import { useQuery } from '@tanstack/react-query';
import { Alert } from '@tcl-boron-prefabs/alert';
import { Tab } from '@tcl-boron-prefabs/tab';
import { TabGroup } from '@tcl-boron-prefabs/tab-group';
import { WarningCircle } from '@tempus/component-library/icons';
import { colors } from '@tempus/component-library/styles';
import {
  DocumentConstants,
  OperationaFlagHoverText,
  OperationalFlags,
  TrialOnHoldDocumentDescription,
} from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUS_CATEGORIES } from '@tempus/t-shared/src/constants/patient-tracker';
import { MaxPageWidth, documentActions } from '@tempus/t-shared/ui';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';

import InternalUsersImpersonateCta from '~/components/InternalUsersImpersonateCta';
import TrialDocumentsHeader from '~/components/PageHeaders/TrialHeader';
import PatientTracker from '~/components/PatientTracker';
import TherapiesDocumentListPage from '~/components/TherapiesDocumentListPage';
import { RootState } from '~/store';
import api from '~/store/api';
import { impersonatedIds } from '~/store/api/axios';
import { creators as patientTrackerCreators } from '~/store/patientTrackerV2';
import { PatientTrackerFilterField, PatientTrackerStateId } from '~/store/patientTrackerV2/types';

import { useStyles } from './styles';

interface RouteMatchParams {
  trialId: string;
}

interface TrialDocumentsParams extends RouteComponentProps<RouteMatchParams>, ConnectedProps<typeof connector> {}

type TrialDocumentsTab = 'Materials' | 'Patients';

const TrialDocuments: React.FC<TrialDocumentsParams> = ({ match, selectedUserSiteId }) => {
  const {
    params: { trialId },
  } = match;
  const { isLoading, error, data: trial } = useQuery({
    queryKey: ['trial', trialId],
    queryFn: () => api.trials.getTrial(trialId),
  });
  const dispatch = useDispatch();
  const classes = useStyles();

  const [queryParams, setQueryParams] = useQueryParams({
    documentsPageTab: StringParam,
  });

  const [selectedTab, setSelectedTab] = useState(queryParams.documentsPageTab || 'Materials');
  const [patientCount, setPatientCount] = useState<number | null>(null);
  const siteId = useSelector(({ site }: RootState) => site.selectedUserSite?.id);

  const {
    user: { canImpersonate },
  } = useSelector((state: RootState) => state);

  const { patients, patientsMetadata, categoryCounts } =
    useSelector(
      ({ patientTrackerV2 }: RootState) => patientTrackerV2[PatientTrackerStateId.TRIAL_DOCUMENTS_PAGE_PATIENTS],
    ) || [];

  useEffect(() => {
    dispatch(
      documentActions.getDocuments({
        classification: DocumentConstants.Classification.Trial,
        id: trialId,
        queryParams: { overrideSiteId: selectedUserSiteId },
      }),
    );

    dispatch(
      patientTrackerCreators.setPatientTrackerFilters(PatientTrackerStateId.TRIAL_DOCUMENTS_PAGE_PATIENTS, {
        trialName: { label: '', value: trialId },
      }),
    );

    dispatch(
      patientTrackerCreators.getPatientTrackerCategoryCounts(
        PatientTrackerStateId.TRIAL_DOCUMENTS_PAGE_PATIENTS,
        siteId || '',
      ),
    );
  }, [selectedUserSiteId]);

  const updateSelectedTab = (value: TrialDocumentsTab) => {
    setSelectedTab(value);
    setQueryParams({ ...queryParams, documentsPageTab: value });
  };

  useEffect(() => {
    setPatientCount(null);

    if (canImpersonate && !impersonatedIds[0]) {
      return;
    }

    let updatedPatientCount = 0;

    Object.keys(categoryCounts).forEach((key) => {
      if (key !== PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE && categoryCounts[key] !== null) {
        updatedPatientCount += categoryCounts[key];
      }
    });

    setPatientCount(updatedPatientCount);
  }, [categoryCounts]);

  const disabledFilters = [
    PatientTrackerFilterField.INDICATIONS,
    PatientTrackerFilterField.TRIAL_STATUS,
    PatientTrackerFilterField.TRIAL_NAME,
    PatientTrackerFilterField.TRIAL_TYPE,
    PatientTrackerFilterField.RN_ASSIGNMENT,
  ];

  return (
    <MaxPageWidth>
      <div className={classes.wrapper}>
        <TrialDocumentsHeader trialId={trialId} trialData={{ isLoading, error, data: trial }} />
        <>
          <TabGroup
            value={selectedTab}
            onChange={(value) => updateSelectedTab(value as TrialDocumentsTab)}
            className={classes.tabGroup}>
            <Tab value={'Materials'}>Materials</Tab>
            <Tab value={'Patients'}>Patients {patientCount !== null && `(${patientCount})`}</Tab>
          </TabGroup>
          <div className={classes.tabGroupBorder} />
          {trial?.TrialPrograms?.[0]?.operationalFlag === OperationalFlags.AMENDMENT && (
            <Alert status="warning" className={classes.overlayWarning}>
              {{
                title: (
                  <>
                    <span className={classes.iconStyles}>
                      <WarningCircle color={colors.orange} />
                    </span>
                    {OperationaFlagHoverText[OperationalFlags.AMENDMENT]}
                  </>
                ),
                content: <div>{TrialOnHoldDocumentDescription[OperationalFlags.AMENDMENT].info}</div>,
              }}
            </Alert>
          )}
        </>
        {selectedTab === 'Materials' && (
          <TherapiesDocumentListPage
            queryParams={{ overrideSiteId: selectedUserSiteId }}
            classificationId={trialId}
            classification={DocumentConstants.Classification.Trial}
          />
        )}
        {selectedTab === 'Patients' && (!canImpersonate || (canImpersonate && impersonatedIds[0])) && (
          <PatientTracker
            stateId={PatientTrackerStateId.TRIAL_DOCUMENTS_PAGE_PATIENTS}
            siteId={siteId || ''}
            visibleFilters={Object.keys(PatientTrackerFilterField)
              .map((field) => PatientTrackerFilterField[field])
              .filter((field) => !disabledFilters.includes(field))}
            timePatients={patients}
            patientsMetadata={patientsMetadata}
            hiddenTabs={[PATIENT_TRACKER_STATUS_CATEGORIES.SCREENING_QUEUE]}
          />
        )}
        {selectedTab === 'Patients' && canImpersonate && !impersonatedIds[0] && <InternalUsersImpersonateCta />}
      </div>
    </MaxPageWidth>
  );
};
const connector = connect((state: RootState) => {
  return {
    selectedUserSiteId: state.site.selectedUserSite?.id,
  };
}, {});

export default withRouter(connector(TrialDocuments));
