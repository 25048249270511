import { colors } from '@tempus/component-library/styles';
import styled from 'styled-components';

export default styled.div`
  font-size: 13px;

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 110%;
    left: 50%;
    margin-left: -50%;
  }

  .tooltip:hover .tooltip-text {
    visibility: visible;
  }

  .footer {
    bottom: 0;
    margin-bottom: 80px;
  }
  .modal-background {
    padding-bottom: 20px;
    background-color: #fafafb;
    .error-message {
      color: ${colors.red};
    }
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 245px);
    .classification-row {
      background-color: white;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex: 1;
      padding: 20px;
      margin-left: 30px;
      margin-right: 45px;
      margin-top: 20px;
      box-shadow: 0 2px 4px 0 #e9e9ea;
      .classification-dropdown {
        padding-right: 10px;
        flex-basis: 25%;
        z-index: 2;
      }
    }
    .collapsed-row {
      display: none;
    }
    .expanded-row {
      background-color: white;
      display: flex;
      flex-direction: row;
      flex: 1;
      .expand-icon-column {
        width: 20px;
        padding-right: 5px;
      }
      .expanded-dropdown {
        flex-basis: calc(25% - 25px);
        margin-right: 10px;
      }
      .tag-picker-row {
        flex: 1;
        display: flex;
        min-width: 200px;
        flex-direction: column;

        .tag-picker {
          flex: 1;
        }
      }
    }
    .document-list {
      .sticky-header {
        position: sticky;
        background: #fafafb;
        top: 0;
        z-index: 1;
        align-items: baseline;
        justify-content: space-between;
        .document-header {
          display: flex;
          flex-direction: row;
          flex: 1;
          margin-left: 30px;
          margin-right: 45px;
          padding: 20px 10px 0px;
          .icon-header {
            width: 20px;
            padding-right: 5px;
          }
          .name-header {
            flex-basis: 25%;
            padding: 0 5px;
          }
          .fixed-header {
            max-width: 60px;
            padding: 0 5px;
          }
          .dropdown-header {
            flex-basis: 20%;
            padding: 0 5px;
          }
          .date-header {
            flex-basis: 20%;
            padding: 0 5px;
          }
          .toggle-group-header {
            display: flex;
            flex-direction: row;
            flex-basis: 15%;
            padding-left: 5px;
            padding-right: 10px;
            justify-content: space-around;
            .toggle-header {
              max-width: 60px;
              text-align: center;
            }
            max-width: 240px;
          }
        }
      }
      .document-status {
        position: relative;
        .uploading-indicator {
          color: ${colors.blue};
          position: absolute;
          left: 50%;
          top: 15px;
          z-index: 11;
          .progress-message {
            margin-bottom: 15px;
            text-align: center;
          }
          .progress-bar {
            width: 240px;
            display: block;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .new-document {
          display: flex;
          flex-direction: row;
          flex: 1;
          align-items: baseline;
          justify-content: space-between;
          .document-wrapper {
            flex: 1;
            .document-row {
              display: flex;
              flex-direction: row;
              align-items: center;
              .expand-icon-column {
                .expand-icon {
                  height: 10px;
                  color: ${colors.blue};
                }
                width: 20px;
                padding-right: 5px;
              }
              .name-column {
                flex-basis: 25%;
                padding: 0 5px;
              }
              .fixed-column {
                max-width: 60px;
                padding: 0 5px;
              }
              .dropdown-column {
                flex-basis: 20%;
                padding: 0 5px;
              }
              .date-column {
                flex-basis: 20%;
                padding: 0 5px;
              }
              .toggle-group {
                display: flex;
                flex-direction: row;
                flex-basis: 15%;
                padding-left: 5px;
                justify-content: space-around;
                .toggle-column {
                  max-width: 60px;
                }
                max-width: 240px;
              }
            }
          }
          .delete-icon-column {
            background-color: #fafafb;
            margin-right: 30px;
            width: 15px;
            flex: 0;
            .delete-icon {
              height: 10px;
            }
            .success-icon {
              height: 12px;
              color: ${colors.blue};
            }
            .failure-icon {
              height: 12px;
              color: ${colors.red};
            }
          }
        }
      }
    }
  }
`;
