import { createUseStyles } from 'react-jss';

const spacing = 5;

export default createUseStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -spacing,
    marginLeft: -spacing,
    alignItems: 'center',

    '& > *': {
      marginTop: spacing,
      marginLeft: spacing,
    },
  },
  addButton: {
    width: '20px !important',
    height: '20px !important',
  },
  select: {
    maxWidth: 300,
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      width: '100%',
    },

    '& > button': {
      marginLeft: 10,
    },
  },
});

export const useComboboxStyles = createUseStyles({
  isVisuallyHidden: {
    display: 'none !important',
  },
});

export const useTagStyles = createUseStyles({
  text: {
    margin: 0,
  },
});
