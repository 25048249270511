import { Tab } from '@tcl-boron-prefabs/tab';
import { TabGroup } from '@tcl-boron-prefabs/tab-group';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { PatientTrackerStateId } from '~/store/patientTrackerV2/types';

import DownloadPatients from '../DownloadPatients/v2';
import { useTabGroupStyles } from './styles';

interface PatientTrackerStatusTabGroupProps {
  stateId: string;
  siteId: string;
  tabs: { title: string; count: number }[];
  value: string;
  onChange: (value: string) => void;
}

const PatientTrackerStatusTabGroup: React.FC<PatientTrackerStatusTabGroupProps> = ({
  stateId,
  siteId,
  tabs,
  value,
  onChange,
}) => {
  const tabGroupStyles = useTabGroupStyles();
  const fetchingRecords = useSelector(({ patientTrackerV2 }: RootState) => patientTrackerV2[stateId].fetchingRecords);

  return (
    <>
      <div className={tabGroupStyles.tabGroupWrapper}>
        {!fetchingRecords && (
          <TabGroup value={value} onChange={onChange}>
            {tabs.map(({ title, count }) => {
              return (
                <Tab key={title} value={title}>
                  {title} {typeof count === 'number' && !isNaN(count) ? `(${count})` : ''}
                </Tab>
              );
            })}
          </TabGroup>
        )}
        {stateId === PatientTrackerStateId.TRIAL_DOCUMENTS_PAGE_PATIENTS && (
          <DownloadPatients stateId={stateId} siteId={siteId} small={true} />
        )}
      </div>

      {stateId !== PatientTrackerStateId.TRIAL_DOCUMENTS_PAGE_PATIENTS && (
        <div className={tabGroupStyles.bottomBorder} />
      )}
    </>
  );
};

export default PatientTrackerStatusTabGroup;
