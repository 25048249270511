import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    fontSize: '13px',
    width: '500px',
    '& h1': {
      display: 'none',
    },
    top: '50px !important',
  },

  modalHeader: {
    marginBottom: '16px',
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  disclaimer: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: '16px',
  },

  searchContainer: {
    height: '420px',
  },

  searchContent: {
    flexDirection: 'column',

    '& > div': {
      marginBottom: '24px',
      width: '100%',
    },
  },

  loadingMessage: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      marginLeft: '5px',
    },
  },

  option: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },

  optionName: {
    fontWeight: 700,
  },

  selectedDetails: {
    display: 'flex',
    alignItems: 'center',
  },

  dob: {
    marginBottom: '12px !important',
  },

  selectedDetailsText: {
    marginLeft: '8px',
  },

  modalFooter: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    height: '84px',
    margin: '20px 0px 0px 0px',
  },

  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      height: '50px',
      minWidth: '70px',
    },

    '& .secondary-button': {
      marginRight: '15px',
    },
  },

  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(8px)',
    overflow: 'scroll',
    zIndex: 5,
  },

  modal: {
    height: '440px',
    '& > div > div': {
      overflowY: 'auto',
    },
  },
});
