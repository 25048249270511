import { Patient } from '@tempus/t-rules-engine/lib/src/types/patient/patient';
import { CohortCancerType } from '@tempus/t-shared';

import { PatientInput, SelectOption } from '~/store/trialSearch/state';

export const checkAddSolidTumor = (diseaseCode: string, solidTumorOptions: SelectOption[]) => {
  const diseaseCodes = [diseaseCode];

  if (solidTumorOptions.map(({ value }) => value).includes(diseaseCode)) {
    diseaseCodes.push(CohortCancerType.SOLID_TUMOR);
  }

  return diseaseCodes;
};

export const formatValuesetOptions = (input): SelectOption[] =>
  input.map(({ code, display }) => ({ label: display, value: code }));

export const convertPatientInputToRulesEnginePatient = (
  patientInput: PatientInput,
  solidTumorOptions: SelectOption[],
) => {
  const getFormattedDiseases = () => {
    let diseases: Patient['diseases'] = [];

    for (const diagnosis of patientInput.diagnoses) {
      if (diagnosis.disease) {
        diseases = diseases.concat(
          checkAddSolidTumor(diagnosis.disease.value, solidTumorOptions).map((diseaseCode) => ({
            status: null,
            histology: null,
            stageGrade: diagnosis.diseaseStage ? diagnosis.diseaseStage.value : null,
            diagnosis: null,
            tmoDiagnosis: 'invalid-tmo-code',
            cohortCancerType: diseaseCode,
            cohortCancerSubtype: null,
            finalTumorPercentage: null,
          })),
        );
      } else if (diagnosis.diseaseStage) {
        diseases.push({
          status: null,
          histology: null,
          stageGrade: diagnosis.diseaseStage.value,
          diagnosis: null,
          tmoDiagnosis: null,
          cohortCancerType: null,
          cohortCancerSubtype: null,
          finalTumorPercentage: null,
        });
      }
    }

    return diseases.length ? diseases : null;
  };

  const converted = {
    diseases: getFormattedDiseases(),
    mutations: patientInput.variantOptions.length
      ? patientInput.variantOptions.map((opt) => ({
          mutation: null,
          reportSection: null,
          cVar: null,
          vaf: null,
          position: null,
          alteration: null,
          codingExon: null,
          genomicExon: null,
          presentation: null,
          pVarSingleChar: null,
          origin: null,
          result: null,
          vafFinalTumorPercentage: null,
          fusedGene: null,
          copyNumber: null,
          ...opt.mutation,
        }))
      : null,
    biomarkers: patientInput.variantOptions.length
      ? patientInput.variantOptions.map((opt) => ({
          name: opt.mutation.gene, // HGNC code
          result: null,
          cytogeneticFindingResult: null,
          cytogeneticFindingAbnormality: null,
        }))
      : null,
    treatments: null,
    medications: null,
    comorbidConditions: null,
    alteredSplicingEvents: null,
    immunotherapyMarkers: [
      {
        tmb: patientInput.tmbResults,
        msi: null,
      },
    ],
    demographics: [
      {
        age: patientInput.age,
        deceased: null,
        gender: null,
      },
    ],
  } as Patient;
  return converted;
};
