import { Spinner } from '@tempus/t-shared/ui';
import React, { ComponentType, memo } from 'react';
import { useSelector } from 'react-redux';

import SiteSelection from '~/components/SiteSelection';
import { RootState } from '~/store';

import Forbidden from './Forbidden';

const LoginComponent: React.FC = () => {
  return <Spinner relativePosition loading transparent />;
};

interface CheckRoleProps {
  Component: ComponentType;
  authorizedRoles: string[];
  redirect?: boolean;
  showSiteSelection?: boolean;
}

const CheckRole: React.FC<CheckRoleProps> = (props) => {
  const { Component, authorizedRoles, redirect, showSiteSelection } = props;
  const { loaded, effectiveRoles } = useSelector((state: RootState) => state.user);
  const hasRole = authorizedRoles.some((role: string) => effectiveRoles.includes(role));
  if (!loaded) {
    return <LoginComponent />;
  }

  if (!hasRole && redirect) {
    return <Forbidden />;
  }

  if (!hasRole && !redirect) {
    return null;
  }

  if (showSiteSelection) {
    return (
      <SiteSelection>
        <Component {...props} />
      </SiteSelection>
    );
  }
  return <Component {...props} />;
};

export default memo(CheckRole);
