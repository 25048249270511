/* pulled component out of t-shared to be more flexible for features specific to LFSites */
import { useQuery } from '@tanstack/react-query';
import { AVAILABLE_TIME_SITE_STATUSES, DocumentConstants, TimeProgramSiteStatus } from '@tempus/t-shared';
import {
  documentActions,
  DocumentEmptyImage,
  DocumentListContext,
  NoResultImage,
  NotAvailable,
  sortedDocumentsByCategoryAndTypeRollup,
  Spinner,
  StateWithDocuments,
  ThreeColumnLayout,
} from '@tempus/t-shared/ui';
import FilterDropdown from '@tempus/t-shared/ui/components/DocumentListPage/FilterDropdown';
import SelectedDocuments from '@tempus/t-shared/ui/components/DocumentListPage/SelectedDocuments';
import Type from '@tempus/t-shared/ui/components/DocumentListPage/Type';
import TypeList from '@tempus/t-shared/ui/components/DocumentListPage/TypeList';
import {
  documentViewFilterDropdownOptions,
  FilterDropdownOption,
  initialDocumentViewFilterDropdownOption,
} from '@tempus/t-shared/ui/components/DocumentListPage/utils/filter';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '~/store';
import api from '~/store/api';

import UnavailableTrialsContactHeader from '../UnavailableTrialsDisplay/ContactHeader';
import CtgovLink from '../UnavailableTrialsDisplay/CtgovLink';
import useStyles from './styles';

const getNotAvailableDescription = (classification: DocumentConstants.Classification): string => {
  switch (classification) {
    case DocumentConstants.Classification.Site:
      return "Documents related to your site's participation in the TIME program, research capabilities and/or rapid activations will show here.";
    case DocumentConstants.Classification.Trial:
      return 'General documents, patient materials, and activation documents related to this trial will show here.';
  }
};

interface DocumentListPageProps {
  header?: ReactNode;
  children?: ReactNode;
  classificationId: string;
  queryParams?: Record<string, unknown>;
  classification: DocumentConstants.Classification;
  showReviewStatusFilter?: boolean;
}

export const DocumentListPage: React.FC<DocumentListPageProps> = ({
  header,
  children,
  queryParams,
  classification,
  classificationId,
  showReviewStatusFilter = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const context = useContext(DocumentListContext);
  const [getDocumentsDispatched, setGetDocumentsDispatched] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState<FilterDropdownOption>(
    initialDocumentViewFilterDropdownOption,
  );

  const handleSetSelectedFilterOption = (selectedItem: FilterDropdownOption | null): void => {
    selectedItem === null
      ? setSelectedFilterOption(initialDocumentViewFilterDropdownOption)
      : setSelectedFilterOption(selectedItem);
  };
  const {
    user: { canImpersonate },
    site: { selectedUserSite },
  } = useSelector((state: RootState) => state);
  const selectedSiteId = selectedUserSite?.id;

  // only run query for Trial docs & don't run the query until selected site loads
  const shouldCheckProgramSiteStatus =
    classification === DocumentConstants.Classification.Trial && Boolean(selectedSiteId);
  const { data: programSite } = useQuery({
    enabled: shouldCheckProgramSiteStatus,
    queryKey: ['programSite', classificationId, selectedSiteId],
    queryFn: () => api.programSites.getProgramSite(selectedSiteId as string, classificationId),
  });
  // using !AVAILABLE_TIME_SITE_STATUSES since the page for Closed or Inactive trials could be accessed by site users
  // by updating the URL or using site selection for grandparent users
  const showUnavailableTrialDisplay =
    classification === DocumentConstants.Classification.Trial &&
    !AVAILABLE_TIME_SITE_STATUSES.includes(programSite?.status as TimeProgramSiteStatus) &&
    !canImpersonate;

  const {
    renderers: { uploadButton },
  } = context;

  const {
    document: { loading, documents, error },
  } = useSelector((state: StateWithDocuments) => state);

  const classificationDocuments = documents[classification][classificationId] || [];
  const empty = classificationDocuments.length === 0;
  const sortedDocsByCategoryAndTypeRollup = sortedDocumentsByCategoryAndTypeRollup(
    classificationDocuments,
    classification,
    selectedFilterOption.filter,
  );

  useEffect(() => {
    setGetDocumentsDispatched(true);
    dispatch(
      documentActions.getDocuments({
        queryParams,
        classification,
        id: classificationId,
        updateCurrent: true,
      }),
    );
  }, [classification, classificationId]);

  const renderLeft = () => {
    return (
      <React.Fragment>
        {loading !== classificationId && !error && !empty && (
          <TypeList sortedDocsByCategoryAndTypeRollup={sortedDocsByCategoryAndTypeRollup} />
        )}
        {uploadButton()}
      </React.Fragment>
    );
  };

  const renderCenter = () => {
    if (!getDocumentsDispatched || loading === classificationId) {
      return <Spinner loading transparent relativePosition />;
    } else if (error || (empty && !showUnavailableTrialDisplay)) {
      return (
        <NotAvailable
          title="No Documents Yet"
          Image={DocumentEmptyImage}
          description={getNotAvailableDescription(classification)}
        />
      );
    }

    const itemsToRender: ReactNode[] = [];
    if (showUnavailableTrialDisplay) {
      itemsToRender.push(<UnavailableTrialsContactHeader />);
      itemsToRender.push(<CtgovLink trialId={classificationId} />);
    }

    sortedDocsByCategoryAndTypeRollup.forEach((typeRollupObj, category) => {
      typeRollupObj.forEach((documents, typeRollup) => {
        itemsToRender.push(
          <Type category={category} key={`${category}-${typeRollup}`} typeRollup={typeRollup} documents={documents} />,
        );
      });
    });

    if (showReviewStatusFilter && !showUnavailableTrialDisplay) {
      if (!itemsToRender.length) {
        itemsToRender.push(
          <NotAvailable
            title="No Results Yet"
            Image={NoResultImage}
            description="There are currently no documents in this status."
            className={classes.noResultComponent}
          />,
        );
      }

      itemsToRender.unshift(
        <FilterDropdown
          label="Status"
          onChange={handleSetSelectedFilterOption}
          options={documentViewFilterDropdownOptions}
          value={selectedFilterOption}
        />,
      );
    }

    return itemsToRender;
  };

  const renderRight = () => {
    return <SelectedDocuments />;
  };

  return (
    <React.Fragment>
      {header ? header : null}

      <ThreeColumnLayout left={renderLeft()} center={renderCenter()} right={renderRight()} classes={classes} />

      {children}
    </React.Fragment>
  );
};
