import { FILTER_COLUMN_ID, MaxPageWidth } from '@tempus/t-shared/ui';
import { kebabCase } from 'lodash';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import { SiteDocumentsCell } from '~/components/DocumentsCell';
import Header from '~/components/Header';
import TherapiesTable from '~/components/Table';
import { RootState } from '~/store';
import { creators as siteCreators } from '~/store/site';
import { SiteWithId, UserSiteMap } from '~/store/site/types';

import Styles from './styles';

const mapState = (state: RootState) => {
  return {
    sites: state.site.userSites,
  };
};

const connector = connect(mapState, {
  getSites: siteCreators.getUserSites,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

interface SitesProps extends RouteComponentProps, PropsFromRedux {}

export const getCellId = (cell: Cell<SiteWithId>): string => {
  const { column } = cell;

  if (typeof column.Header === 'string') {
    return column.Header;
  }

  return column.id || 'unknown';
};

export const getSitesArray = (sites: UserSiteMap | null) =>
  sites
    ? Object.entries(sites).map(([id, values]) => ({
        id,
        name: values.name,
        shortName: values.shortName,
        latestDocumentApplicableAt: values.latestDocumentApplicableAt,
      }))
    : [];

export const SITE_NAME_COLUMN = {
  Header: 'Name',
  id: 'name',
  accessor: ({ name, shortName }) => shortName || name,
  width: '97%',
};

const columns: Column<SiteWithId>[] = [
  SITE_NAME_COLUMN,
  {
    width: '3%',
    id: FILTER_COLUMN_ID,
    Cell: SiteDocumentsCell,
  },
];

const Sites: React.FC<SitesProps> = ({ getSites, sites }) => {
  const history = useHistory();

  const siteDocumentsArray = React.useMemo(() => getSitesArray(sites), [sites]);

  useEffect(() => {
    getSites();
  }, []);

  return (
    <MaxPageWidth>
      <Styles>
        <Header headerTitle={'Sites'} />
        <TherapiesTable
          tableName="site-list"
          columns={columns}
          data={siteDocumentsArray}
          onCellClick={(e, cell) => {
            history.push(`/sites/${cell.row.original.id}/documents`);
          }}
          extendRowProps={(
            props,
            {
              row: {
                original: { id },
              },
            },
          ) => {
            return {
              ...props,
              'data-site-id': id,
              'data-pendo-id': 'site-list-site',
            };
          }}
          extendCellProps={(props, { cell }) => {
            return {
              ...props,
              'data-pendo-id': `site-list-site-${kebabCase(getCellId(cell))}-cell`,
            };
          }}
          initialState={{
            sortBy: [
              {
                id: 'name',
                desc: false,
              },
            ],
          }}
        />
      </Styles>
    </MaxPageWidth>
  );
};

export default withRouter(connector(Sites));
