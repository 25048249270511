import { SagaIterator } from '@redux-saga/core';
import { DocumentConstants, DocumentView } from '@tempus/t-shared';
import { documentActions } from '@tempus/t-shared/ui';
import { compact, map } from 'lodash';
import { call, put, select, takeEvery, takeLeading } from 'redux-saga/effects';

import { RootState } from '~/store';
import api from '~/store/api';

import { creators as documentEditCreators } from './actions';
import {
  GET_TAGS,
  GetTagAction,
  GET_TAG,
  UpdateDocumentAction,
  DocumentEditState,
  UPDATE_DOCUMENT,
  DeleteVersionAction,
  DELETE_VERSION,
} from './types';

const addTags = (existingTags: DocumentEditState['tags'], document: DocumentView): DocumentEditState['tags'] => {
  // If tags haven't been loaded yet, then
  //   they will be when the tag editor is
  //   used and we don't need to update the
  //   list here.
  // Also, no need to do anything if a document
  //   is deleted.
  if (Object.keys(existingTags).length === 0) {
    return existingTags;
  }

  const updatedTags = { ...existingTags };

  document.tags.forEach(({ key, value }) => {
    const valuesForTag = updatedTags[key] || false;

    // If this key was not loaded yet, then
    //   leave it to the normal loading process
    //   when the tag editor is used.
    if (typeof valuesForTag === 'boolean' && !valuesForTag) {
      return;
    }

    // Otherwise, the values for this tag have already
    //   been loaded, so add this value to the list so
    //   it will be available in future showings of the
    //   list.
    updatedTags[key] = valuesForTag || [];
    updatedTags[key] = [...valuesForTag, value];
  });

  return updatedTags;
};

function* updateDocument({ document }: UpdateDocumentAction): SagaIterator {
  const { classificationId, classification, documents } = (yield select(
    ({ document }: RootState) => document,
  )) as RootState['document'];
  const { editingVersionId, tags } = (yield select(
    ({ documentEdit }: RootState) => documentEdit,
  )) as RootState['documentEdit'];

  const { id: documentId, classification: newClassification, trialId, institutionId } = document;
  const newClassificationId = newClassification === DocumentConstants.Classification.Trial ? trialId : institutionId;

  const originalDocuments = documents[classification][classificationId];
  const newDocument = !map(originalDocuments, 'id').includes(documentId);
  const movedDocument = newClassificationId && classificationId && newClassificationId !== classificationId;

  const updatedOriginalDocuments = compact(
    originalDocuments.map((existing) => {
      if (existing.id === documentId) {
        return movedDocument ? undefined : document;
      }

      return existing;
    }),
  );

  const updatedClassificationDocuments = {
    ...documents[classification],
    [classificationId]: updatedOriginalDocuments,
  };

  const updatedDocumentLookup = { ...documents, [classification]: updatedClassificationDocuments };

  if (newClassificationId) {
    const newDocuments = documents[newClassification][newClassificationId];

    if ((movedDocument || newDocument) && newDocuments) {
      updatedDocumentLookup[newClassification][newClassificationId] = [...newDocuments, document];
    }
  }

  yield put(documentActions.setDocuments(updatedDocumentLookup));

  yield put(
    documentEditCreators.updateDocumentEditState({
      tags: addTags(tags, document),
      editingVersionId: movedDocument ? '' : editingVersionId,
    }),
  );
}

function* deleteVersion({ documentId, versionId }: DeleteVersionAction): SagaIterator {
  const { classificationId, classification, documents } = (yield select(
    ({ document }: RootState) => document,
  )) as RootState['document'];
  const { editingVersionId } = (yield select(
    ({ documentEdit }: RootState) => documentEdit,
  )) as RootState['documentEdit'];

  const classificationDocuments = [...documents[classification][classificationId]];
  const existingDocumentIndex = classificationDocuments.findIndex((d) => d.id === documentId);
  const existingDocument = classificationDocuments[existingDocumentIndex];

  const updatedDocument = { ...existingDocument };
  updatedDocument.versions = updatedDocument.versions.filter((v) => v.id !== versionId);

  if (!updatedDocument.versions.length) {
    classificationDocuments.splice(existingDocumentIndex, 1);
  } else {
    classificationDocuments[existingDocumentIndex] = updatedDocument;
  }

  const updatedClassificationDocuments = {
    ...documents[classification],
    [classificationId]: classificationDocuments,
  };

  yield put(
    documentActions.setDocuments({
      ...documents,
      [classification]: updatedClassificationDocuments,
    }),
  );

  yield put(
    documentEditCreators.updateDocumentEditState({
      editingVersionId: editingVersionId === versionId ? '' : editingVersionId,
    }),
  );
}

function* fetchTags(): SagaIterator {
  const { tags } = (yield select(({ documentEdit }: RootState) => documentEdit)) as RootState['documentEdit'];

  if (Object.keys(tags).length) {
    yield put(documentEditCreators.setTags(tags)); // Clears loading status.
    return;
  }

  const keyList = yield call(api.documents.getTags);
  yield put(documentEditCreators.setTags(keyList.reduce((lookup, key) => ({ ...lookup, [key]: false }), {})));
}

function* fetchTagValues({ key }: GetTagAction): SagaIterator {
  const { tags } = (yield select(({ documentEdit }: RootState) => documentEdit)) as RootState['documentEdit'];

  if (tags[key]) {
    yield put(documentEditCreators.setTags(tags)); // Clears loading status.
    return;
  }

  const valueList = yield call(api.documents.getValuesForTag, key);
  yield put(documentEditCreators.setTags({ ...tags, [key]: valueList }, true));
}

export default [
  takeEvery(UPDATE_DOCUMENT, updateDocument),
  takeEvery(DELETE_VERSION, deleteVersion),
  takeLeading(GET_TAGS, fetchTags),
  takeEvery(GET_TAG, fetchTagValues),
];
