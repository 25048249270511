import { Button, Modal, ModalActions } from '@tempus/component-library';
import { H3 } from '@tempus/component-library/typography';
import withStyles from '@tempus/component-library/utils/withStyles';
import React from 'react';

const StyledModal = withStyles({
  content: {
    width: '450px',
  },
})(Modal);

const StyledConfirmH3 = withStyles({
  root: {
    marginTop: '0',
    marginBottom: '30px',
  },
})(H3);

const StyledModalActions = withStyles({
  root: {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    marginRight: '30px',
  },
})(ModalActions);

interface DocumentTypeChangeConfirmationModalProps {
  cancel: () => void;
  confirm: () => void;
  showModal: boolean;
}

const DocumentTypeChangeConfirmationModal: React.FC<DocumentTypeChangeConfirmationModalProps> = ({
  cancel,
  confirm,
  showModal,
}) => {
  return (
    <StyledModal open={showModal} onRequestClose={cancel}>
      <StyledConfirmH3>Are you sure?</StyledConfirmH3>
      You have tags that will be cleared if you decide to continue.
      <StyledModalActions>
        <Button type="cancel" onClick={cancel} data-pendo-id="document-file-type-change-cancel">
          Cancel
        </Button>
        <Button onClick={confirm} data-pendo-id="document-file-type-change-confirm">
          Confirm file type change
        </Button>
      </StyledModalActions>
    </StyledModal>
  );
};

export default DocumentTypeChangeConfirmationModal;
