import { Reducer } from 'redux';

import {
  GET_ALL_SITES,
  GET_ALL_SITES_FAILED,
  GET_USER_SITES,
  GET_USER_SITES_FAILED,
  SET_ALL_SITES,
  SET_IMPERSONATION_MODAL_OPEN,
  SET_USER_SITES,
  SiteActionTypes,
  SiteState,
} from './types';

const defaultState: SiteState = {
  timeSites: [],
  error: '',
  userSites: null,
  selectedUserSite: localStorage.getItem('selectedUserSite')
    ? JSON.parse(localStorage.getItem('selectedUserSite') as string)
    : null,
  loading: {
    timeSites: false,
    userSites: false,
  },
  impersonationModalOpen: false,
};

const reducer: Reducer<SiteState, SiteActionTypes> = (
  state: SiteState = defaultState,
  action: SiteActionTypes,
): SiteState => {
  switch (action.type) {
    case GET_ALL_SITES: {
      return { ...state, loading: { ...state.loading, timeSites: true } };
    }

    case GET_ALL_SITES_FAILED: {
      const { error } = action;
      return {
        ...state,
        error,
        loading: { ...state.loading, timeSites: false },
      };
    }

    case SET_ALL_SITES: {
      const { timeSites } = action;
      return {
        ...state,
        timeSites,
        loading: { ...state.loading, timeSites: false },
      };
    }

    case GET_USER_SITES: {
      return { ...state, loading: { ...state.loading, userSites: true } };
    }

    case GET_USER_SITES_FAILED: {
      const { error } = action;
      return {
        ...state,
        error: error.message,
        loading: { ...state.loading, userSites: false },
      };
    }

    case SET_USER_SITES: {
      const { userSites, selectedUserSite: selectedSite } = action;

      let selectedUserSite = selectedSite ?? state.selectedUserSite;
      // for external site users with access to only 1 site (or impersonating one)
      if (Object.keys(userSites).length === 1) {
        const key = Object.keys(userSites)[0];
        selectedUserSite = {
          id: key,
          name: userSites[key].name,
          shortName: userSites[key].shortName,
          latestDocumentApplicableAt: userSites[key].latestDocumentApplicableAt,
        };
      }
      localStorage.setItem('selectedUserSite', JSON.stringify(selectedUserSite));

      return {
        ...state,
        userSites,
        selectedUserSite,
        loading: { ...state.loading, userSites: false },
      };
    }

    case SET_IMPERSONATION_MODAL_OPEN: {
      const { isImpersonationModalOpen } = action;
      return { ...state, impersonationModalOpen: isImpersonationModalOpen };
    }

    default:
      return state;
  }
};

export default reducer;
