import { PATIENT_TRACKER_STATUS_CATEGORIES } from '@tempus/t-shared/src/constants/patient-tracker';

import {
  GET_PATIENT_TRACKER_V2_RECORDS,
  SET_PATIENT_TRACKER_V2_RECORDS,
  PatientTrackerActionTypes,
  GET_PATIENT_TRACKER_V2_RECORDS_FAILED,
  GET_PATIENT_TRACKER_V2_RECORD,
  SET_PATIENT_TRACKER_V2_RECORD,
  GET_PATIENT_TRACKER_V2_RECORD_FAILED,
  CLEAR_PATIENT_TRACKER_V2_RECORD,
  SET_PATIENT_TRACKER_V2_STATUS_CATEGORY,
  GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS,
  SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS,
  SET_PATIENT_TRACKER_V2_UPDATE_MODAL,
  SET_PATIENT_TRACKER_V2_EDIT_OVERLAY,
  GET_PATIENT_TRACKER_V2_RECORDS_CSV,
  GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED,
  SET_PATIENT_TRACKER_V2_RECORDS_CSV,
  PatientRecordBrowsingState,
  SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE,
  PatientTrackerFilters,
  SET_PATIENT_TRACKER_V2_FILTERS,
  SET_PATIENT_TRACKER_V2_PAGINATION,
  PatientTrackerCategoryCounts,
  GetPatientTrackerRecordsOpts,
  TimePatient,
} from './types';

export const creators = {
  setPatientTrackerStatusCategory(
    id: string,
    statusCategory: PATIENT_TRACKER_STATUS_CATEGORIES,
  ): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_STATUS_CATEGORY, id, statusCategory };
  },
  getPatientTrackerRecords(id: string, siteId: string, opts?: GetPatientTrackerRecordsOpts): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORDS, id, siteId, opts };
  },
  setPatientTrackerRecords(id: string, patients: TimePatient[]): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_RECORDS, id, patients };
  },
  getPatientTrackerRecordsFailed(id: string, error: unknown): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORDS_FAILED, id, error };
  },
  getPatientTrackerRecord(id: string, siteId: string, timePatientId: string): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORD, id, siteId, timePatientId };
  },
  setPatientTrackerRecord(id: string, patient: TimePatient): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_RECORD, id, patient };
  },
  getPatientTrackerRecordFailed(id: string, error: unknown): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORD_FAILED, id, error };
  },
  clearPatientTrackerRecord(id: string): PatientTrackerActionTypes {
    return { type: CLEAR_PATIENT_TRACKER_V2_RECORD, id };
  },
  getPatientTrackerRecordsCsv(
    id: string,
    siteId: string,
    opts?: GetPatientTrackerRecordsOpts,
  ): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORDS_CSV, id, siteId, opts };
  },
  getPatientTrackerRecordsCsvFailed(id: string, error: unknown): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED, id, error };
  },
  setPatientTrackerRecordsCsv(id: string, fileName: string, data: string): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_RECORDS_CSV, id, fileName, data };
  },
  getPatientTrackerCategoryCounts(
    id: string,
    siteId: string,
    opts?: GetPatientTrackerRecordsOpts,
  ): PatientTrackerActionTypes {
    return { type: GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS, id, siteId, opts };
  },
  setPatientTrackerCategoryCounts(id: string, categoryCounts: PatientTrackerCategoryCounts): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS, id, categoryCounts };
  },
  setPatientTrackerUpdateModal(
    id: string,
    { selectedPatientRecord, isOpen }: Partial<PatientRecordBrowsingState>,
  ): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_UPDATE_MODAL, id, selectedPatientRecord, isOpen };
  },
  setPatientTrackerEditOverlay(
    id: string,
    { selectedPatientRecord, isOpen }: Partial<PatientRecordBrowsingState>,
  ): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_EDIT_OVERLAY, id, selectedPatientRecord, isOpen };
  },
  showPatientTrackerErrorMessage(id: string, error: unknown, defaultErrorMessage: string): PatientTrackerActionTypes {
    return { type: SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE, id, error, defaultErrorMessage };
  },
  setPatientTrackerFilters(id: string, filters: Partial<PatientTrackerFilters>): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_FILTERS, id, filters };
  },
  setPatientTrackerPagination(
    id: string,
    numRecords: number,
    skip: number,
    totalRecords: number,
  ): PatientTrackerActionTypes {
    return { type: SET_PATIENT_TRACKER_V2_PAGINATION, id, numRecords, skip, totalRecords };
  },
};
