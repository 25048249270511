import { useQuery } from '@tanstack/react-query';
import { Badge, BadgeProps } from '@tcl-boron-prefabs/badge';
import { Button } from '@tcl-boron-prefabs/button';
import { getStatusBadgeStyling, getTimeProgramSiteStatusBadgeText, TimeProgramSiteStatus } from '@tempus/t-shared';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Loader } from 'tcl-v3/prefabs';

import { RootState } from '~/store';
import api from '~/store/api';
import { getBadgeWidth } from '~/utils/misc';

import EmailModal from './EmailModal';
import useStyles from './styles';

interface RouteMatchParams {
  trialId: string;
}

const getStatusInfoText = (status: TimeProgramSiteStatus) => {
  switch (status) {
    case TimeProgramSiteStatus.ON_HOLD:
      return 'This trial is currently on hold';
    case TimeProgramSiteStatus.DETERMINING_SITE_CAPABILITY:
    case TimeProgramSiteStatus.WAITING_SPONSOR_APPROVAL:
    case TimeProgramSiteStatus.SPONSOR_APPROVED:
      return `Your site's capability to run this trial is being evaluated`;
    case TimeProgramSiteStatus.NOT_QUALIFIED:
    case TimeProgramSiteStatus.SPONSOR_DECLINED_POST_REVIEW:
    case TimeProgramSiteStatus.SPONSOR_DECLINED_PRE_REVIEW:
      return 'This trial is not available to your site';
    case TimeProgramSiteStatus.SITE_DECLINED:
      return 'Your site has declined this trial';
    default:
      return '';
  }
};
const UnavailableTrialsContactHeader: React.FC<RouteComponentProps<RouteMatchParams>> = ({ match }) => {
  const [showModal, setShowModal] = useState(false);
  const {
    params: { trialId },
  } = match;
  const classes = useStyles();

  const selectedUserSite = useSelector(({ site }: RootState) => site.selectedUserSite);
  const selectedSiteId = selectedUserSite?.id;

  const { isLoading, error, data: programSite } = useQuery({
    enabled: Boolean(selectedSiteId), // don't run the query until selected site loads
    queryKey: ['programSite', trialId, selectedSiteId],
    queryFn: () => api.programSites.getProgramSite(selectedSiteId as string, trialId),
  });

  if (isLoading) {
    return <Loader />;
  }
  if (error || !programSite) {
    return null;
  }

  const status = programSite.status as TimeProgramSiteStatus;
  return (
    <>
      <div className={classes.contactContainer}>
        <div className={classes.leftContainer}>
          <Badge
            style={{ maxHeight: '50px', width: `${getBadgeWidth(status)}` }}
            text={getTimeProgramSiteStatusBadgeText(status) as BadgeProps['text']}
            badgeVariant={getStatusBadgeStyling(status).variant as BadgeProps['badgeVariant']}
            badgeType={getStatusBadgeStyling(status).type}
          />
          {getStatusInfoText(status)}
        </div>
        <Button
          buttonType="secondary"
          onClick={() => setShowModal(true)}
          ariaLabel={'Contact Tempus'}
          small={true}
          data-pendo-id="contact-tempus-rep">
          Contact your Tempus representative
        </Button>
      </div>
      <EmailModal trialId={trialId} isOpen={showModal} closeModal={() => setShowModal(false)} />
    </>
  );
};

export default withRouter(UnavailableTrialsContactHeader);
