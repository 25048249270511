import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  impersonateButton: {
    marginTop: '16px',
  },
});
