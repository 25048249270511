import { ActionPalette, GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    textAlign: 'left',
  },
});

const container = {
  padding: '30px',
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: `0px 10px 9px ${GrayscalePalette[300]}`,
};

export const createUseDemographicStyles = createUseStyles({
  container,
});

export const createUseFilterStyles = createUseStyles({
  container: {
    width: '30rem',
    ...container,
    padding: '32px',
    maxHeight: 'max(420px, calc(100vh - 460px))',
    overflowY: 'auto',
  },
  innerContainer: {
    borderRadius: '10px',
    padding: '24px',
    backgroundColor: `${GrayscalePalette[200]}`,
    '&:not(:last-child)': {
      marginBottom: '20px',
    },
  },
  innerContainerTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '2px',
    marginBottom: '16px',
    textTransform: 'uppercase',
  },
  toggleLabel: {
    color: ActionPalette[600],
  },
  tmbSlider: {
    width: '368px',
    margin: '24px 0',
  },
  biomarkerTag: {
    marginBottom: '4px',
    '&:first-child': {
      marginTop: '20px',
    },
  },
  diagnosisContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${GrayscalePalette[400]}`,
    marginBottom: '16px',
  },
  removeButton: {
    marginTop: '16px',
    marginBottom: '16px',
    marginLeft: 'auto',
  },
});

export const useMultiSelectStyles = createUseStyles({
  menu: {
    zIndex: 4,
  },
  searchBox: {
    width: '100%',
  },
  root: {
    marginBottom: '16px',
  },
});

export const useSingleSelectStyles = createUseStyles({
  menu: {
    zIndex: 4,
  },
  root: {
    width: '100%',
    '&:not(:first-child)': {
      marginTop: '24px',
    },
  },
});

// to convert the look of SingleDatePicker from Argon to Boron
export const useDatePickerStyles = createUseStyles({
  calendarToggle: {
    color: `${ActionPalette[600]} !important`,
    backgroundColor: 'transparent !important',
    borderColor: 'transparent !important',
    '&:active, &:focus': {
      color: `${ActionPalette[600]} !important`,
      backgroundColor: 'transparent !important',
      borderColor: 'transparent !important',
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: `${ActionPalette[500]} !important`,
      backgroundColor: 'transparent !important',
      borderColor: 'transparent !important',
    },
    right: '2px !important',
    display: 'none !important',
  },
  root: {
    width: '200px',
  },
  inputWrapper: {
    '&:active, &:focus, &:hover, &:focus-within': {
      borderColor: `${GrayscalePalette[300]} !important`,
    },
    borderRadius: '20px !important',
  },
  forceFocus: {
    borderColor: `${GrayscalePalette[500]} !important`,
  },
});
