import {
  AVAILABLE_TIME_SITE_STATUSES,
  NOT_AVAILABLE_TIME_SITE_STATUSES,
  PHASE_OPTIONS,
  PROGRAM_TYPES_DEC,
} from '@tempus/t-shared';
import { FetchDataOptions, Table } from '@tempus/t-shared/ui';
import { debounce, isEqual, kebabCase } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Row } from 'react-table';

import { TrialDocumentsCell } from '~/components/DocumentsCell';
import {
  SelectColumnFilter,
  trialColumnFilter,
  multiSelectFilter,
  DefaultColumnFilter,
} from '~/components/Table/filters';
import { Routes } from '~/routes';
import { RootState } from '~/store';
import { GetTrialsBySiteRequest, TrialsSearchRequestFilters } from '~/store/api/types';
import { creators as trialCreators } from '~/store/trial/actions';
import { Trial } from '~/store/trial/types';

import { useCustomTableStyles } from '../Table/styles';
import CohortsSubtable from './CohortsSubtable';
import { getCellId, TrialCell } from './cells';

// Export columns so subtables can reference column attributes
export const columns: Column<Trial>[] = [
  {
    Header: <span data-testid="materials-trial">Trial</span>,
    accessor: 'shortName',
    id: 'trialName',
    Cell: TrialCell,
    filter: trialColumnFilter,
    width: 400,
    minWidth: 400,
    maxWidth: 400,
  },
  {
    Header: <span data-testid="materials-agent">Agent</span>,
    accessor: 'agents',
    Cell: () => null,
    disableSortBy: true,
    width: 100,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: <span data-testid="materials-indications">Indications</span>,
    accessor: 'indications',
    Cell: () => null,
    disableSortBy: true,
    width: 100,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: <span data-testid="materials-phase">Phase</span>,
    accessor: 'phases',
    Cell: () => null,
    Filter: SelectColumnFilter(PHASE_OPTIONS),
    filter: multiSelectFilter,
    disableSortBy: true,
    width: 100,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: <span data-testid="materials-biomarkers">Biomarkers</span>,
    accessor: 'biomarkers',
    Cell: () => null,
    disableSortBy: true,
    width: 100,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: ' ', // need whitespace to avoid key error
    Cell: TrialDocumentsCell,
    disableSortBy: true,
    disableFilters: true,
    width: 10,
    minWidth: 10,
    maxWidth: 10,
  },
];

interface TrialsTableProps {
  showUnavailableTrials: boolean;
}

const TrialsTable = ({ showUnavailableTrials }: TrialsTableProps) => {
  const dispatch = useDispatch();
  const selectedUserSite = useSelector(({ site }: RootState) => site.selectedUserSite);

  const canViewNewDocuments = useSelector(({ user }: RootState) => user.canViewNewDocuments);
  const { trials, filteredCount: trialCount } = useSelector(({ trial }: RootState) => trial);
  const [pageSize, setPageSize] = useState(15);
  const [pageCount, setPageCount] = useState(Math.ceil((trialCount || 0) / 15));
  const [formattedFilters, setFormattedFilters] = useState<TrialsSearchRequestFilters>({});

  const renderRowSubComponent = (row: Row<Trial>) => <CohortsSubtable row={row} columns={columns} />;

  useEffect(() => {
    setPageCount(Math.ceil((trialCount || 0) / pageSize));
  }, [trialCount, pageSize]);

  const debouncedGetTrials = useCallback(
    debounce((params: GetTrialsBySiteRequest) => dispatch(trialCreators.getTrials(params)), 300),
    [dispatch],
  );

  const getTrials = async (opts: FetchDataOptions<Trial>) => {
    const { pageIndex, pageSize: requestedPageSize, filters } = opts;
    let page = pageIndex;

    if (pageSize !== requestedPageSize) {
      setPageSize(requestedPageSize);
    }

    let updatedFilters: TrialsSearchRequestFilters = {
      statuses: [...AVAILABLE_TIME_SITE_STATUSES, ...(showUnavailableTrials ? NOT_AVAILABLE_TIME_SITE_STATUSES : [])],
    };

    for (const filter of filters) {
      if (!filter.value) {
        continue;
      }

      switch (filter.id) {
        case 'trialName':
          updatedFilters = { ...updatedFilters, trialName: filter.value as string };
          break;
        case 'agents':
          updatedFilters = { ...updatedFilters, agent: filter.value as string };
          break;
        case 'indications':
          updatedFilters = { ...updatedFilters, indication: [filter.value as string] };
          break;
        case 'phases':
          updatedFilters = { ...updatedFilters, phase: filter.value as string[] };
          break;
        case 'biomarkers':
          updatedFilters = { ...updatedFilters, biomarker: [filter.value as string] };
          break;
        default:
          break;
      }
    }

    if (!isEqual(updatedFilters, formattedFilters)) {
      if (updatedFilters.statuses?.length !== formattedFilters.statuses?.length) {
        page = 0;
      }
      setFormattedFilters(updatedFilters);
    }

    const params: GetTrialsBySiteRequest = {
      page,
      pageSize: requestedPageSize,
      filters: updatedFilters,
      ...(opts.sortBy.length && { sortBy: opts.sortBy }),
    };

    debouncedGetTrials(params);

    return Promise.resolve();
  };

  const fetchData = useCallback(getTrials, [formattedFilters, pageSize, showUnavailableTrials]);

  return (
    <Table
      tableName="trial-list"
      columns={columns}
      data={trials}
      fetchData={fetchData}
      pageCount={pageCount}
      defaultShowFilters
      onCellClick={(e, cell) => {
        const cohorts = cell.row.original.cohorts;
        if (canViewNewDocuments && (!cohorts.length || !cohorts.every((c) => c.programType === PROGRAM_TYPES_DEC.ST))) {
          // setting in localStorage since Redux gets cleared when opening a new tab
          localStorage.setItem('selectedUserSite', JSON.stringify(selectedUserSite));
          window.open(`${Routes.trialDocuments.path.replace(':trialId', cell.row.original.id)}`, '_blank');
        }
      }}
      extendCellProps={(props, { cell }) => {
        return {
          ...props,
          'data-pendo-id': `trial-list-trial-${kebabCase(getCellId(cell))}-cell`,
        };
      }}
      extendRowProps={(
        props,
        {
          row: {
            original: { nctId, cohorts },
          },
        },
      ) => {
        return {
          ...props,
          style:
            !canViewNewDocuments || (cohorts.length && cohorts.every((c) => c.programType === PROGRAM_TYPES_DEC.ST))
              ? { pointerEvents: 'none' }
              : {},
          'data-trial-nct-id': nctId,
          'data-pendo-id': 'trial-list-trial',
        };
      }}
      expandedRowIsSubtable
      showExpandedRowByDefault
      renderExpandedRow={renderRowSubComponent}
      classes={useCustomTableStyles()}
      defaultColumn={useMemo(
        () => ({
          Filter: DefaultColumnFilter,
        }),
        [],
      )}
    />
  );
};

export default TrialsTable;
