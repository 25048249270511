import { ExpandableGroup, Switch, Popover } from '@tempus/component-library';
import { colors } from '@tempus/component-library/styles';
import withStyles from '@tempus/component-library/utils/withStyles';
import { DocumentView, DocumentTagKey, DocumentTagView, DocumentConstants } from '@tempus/t-shared';
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { grayPalette } from 'tcl-v3/colors';
import { ChevronUp, ChevronDown, Close, Success, Fail } from 'tcl-v3/icons';
import { DropdownOption, InputTheme } from 'tcl-v3/models';
import { SingleSelectCombobox, ProgressBar, Badge } from 'tcl-v3/prefabs';

import { displayName, classificationField, extensionFromFile, versionIsValid } from '~/components/AddDocuments/utils';
import { CreatableSingleSelectCombobox } from '~/components/CreatableSingleSelectCombobox';
import DocumentTagPicker from '~/components/DocumentTagPicker';
import TagValueSelect from '~/components/DocumentTagPicker/TagValueSelect';
import { NoLabelDatePicker, NoLabelInput, NoLabelCreatable } from '~/components/StyledInputs';
import DocumentTypeChangeConfirmationModal from '~/components/TherapiesDocumentListPage/DetailsOverlay/DocumentTypeChangeConfirmationModal';
import { FileTypeDropdownOption } from '~/components/TherapiesDocumentListPage/DetailsOverlay/types';
import { categoryOptionsByClassification } from '~/components/TherapiesDocumentListPage/rollup';
import { RootState } from '~/store';
import { creators as documentEditCreators } from '~/store/documentEdit';
import { DocumentFile, DocumentWithoutExtras, DocumentFileVersion } from '~/store/documentEdit/types';
import {
  supportsTime,
  ProgramTypeOptions,
  onlySupportsConnect,
  getEffectiveProgramTypes,
  ProgramTypeDropdownOption,
} from '~/utils/program-type';

const MemoizedNoLabelDatePicker = React.memo(NoLabelDatePicker);

enum DocumentState {
  NOT_STARTED,
  UPLOADING,
  COMPLETED,
  FAILED,
}

const datePickerStyles = {
  top: '0 !important',
};

const useDatePickerStyles = createUseStyles({
  calendarToggle: datePickerStyles,
});

const NewVersionBadge = withStyles({
  root: {
    marginLeft: '25px',
    marginBottom: '5px',
  },
})(Badge);

const StyledExpandableGroup = withStyles({
  root: {
    flexGrow: 1,
    opacity: 0.35,

    '.not-started &': {
      opacity: 1,
    },
  },
  header: {
    display: 'flex',
    maxHeight: 'none',
    background: 'none',
    borderBottom: 'none',
    marginTop: '10px',
    marginBottom: 0,
    backgroundColor: colors.white,
    padding: '30px 10px',
    marginLeft: '30px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: colors.white,
    },
    boxShadow: '0 2px 4px 0 #e9e9ea',
  },
  content: {
    marginTop: 0,
    marginBottom: 0,
    borderBottom: 'none',
    maxHeight: 'none',
    overflow: 'visible',
    backgroundColor: colors.white,
    padding: '0 10px 20px',
    marginLeft: '30px',
    marginRight: '10px',
    boxShadow: '0 4px 4px 0 #e9e9ea',
  },
})(ExpandableGroup);

const labelStyles = {
  fontSize: '13px !important',
  color: grayPalette.gray50,
  fontVariant: 'all-small-caps',
  lineHeight: '15px !important',
  height: '15px !important',
  marginBottom: 10,
};

const useRootLabelStyles = createUseStyles({
  root: labelStyles,
});

const useLabelStyles = createUseStyles({
  label: labelStyles,
});

const LabelCombobox = withStyles({
  label: labelStyles,
})(CreatableSingleSelectCombobox);

interface FileDetailsProps {
  file: DocumentFile;
  classificationOption: DropdownOption | null;
  classification: DocumentConstants.Classification;
  optionalClassification: DocumentConstants.Classification;
  optionalClassificationOptions: DropdownOption[];
  showErrors: boolean;
  documentUploading: string | null;
  localTags: Record<string, string[]>;
}

const htmlDocument = document;

export const FileDetails: React.FC<FileDetailsProps> = ({
  file,
  classification,
  classificationOption,
  optionalClassification,
  optionalClassificationOptions,
  showErrors,
  documentUploading,
  localTags,
}) => {
  const dispatch = useDispatch();
  const rootLabelStyles = useRootLabelStyles();
  const tagPickerLabelStyles = useLabelStyles();
  const datePickerStyles = useDatePickerStyles();

  const failedUploads = useSelector(({ documentEdit: { failedUploads } }: RootState) => failedUploads);
  const loading = useSelector(({ document: { loading } }: RootState) => loading);
  const documents = useSelector(({ document: { documents } }: RootState) => documents);

  const documentId = file.document.id;
  const ext = extensionFromFile(file.file);
  const localFileName = file.file.name.slice(0, -1 * (ext.length + 1));

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dateColumnRef = useRef<HTMLDivElement>(null);
  const [classificationDocuments, setClassificationDocuments] = useState<DocumentView[]>([]);
  const [document, setDocument] = useState<DocumentWithoutExtras>(file.document);
  const [version, setVersion] = useState<DocumentFileVersion>(file.version);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [showVersionTooltip, setShowVersionTooltip] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<number>(DocumentState.NOT_STARTED);
  const [pendingTags, setPendingTags] = useState<DocumentTagView[]>([]);
  const [requiredTags, setRequiredTags] = useState<DocumentTagView[]>([]);
  const [fileType, setFileType] = useState<string>('');
  const [fileName, setFileName] = useState<string>(localFileName);
  const [optionalId, setOptionalId] = useState<string | null>(null);
  const [linkedDocument, setlinkedDocument] = useState<DocumentView | null>(null);
  const [fileTypeChange, setFileTypeChange] = useState<FileTypeDropdownOption | null>(null);

  const selectedlinkedDocument = classificationDocuments.find((d) => d.id === version.documentId);
  const existingTags = selectedlinkedDocument ? selectedlinkedDocument.tags : [];
  const { requiredKeys, optionalClassificationRequired, programTypes } = DocumentConstants.getDocumentRollup(
    classification,
    fileType,
    document.category,
  );

  const effectiveProgramTypes = getEffectiveProgramTypes(programTypes);
  const effectiveProgramTypesKey = JSON.stringify(effectiveProgramTypes);

  const documentOnlySupportsConnect = onlySupportsConnect(document.programType);
  const documentSupportsTime = supportsTime(document.programType);

  const getDisplayName = (): string =>
    displayName(fileType, fileName, requiredTags, classification, document.category, document.programType);

  const selectProps: React.ComponentProps<typeof SingleSelectCombobox>['reactSelectProps'] = useMemo(
    () => ({
      menuPosition: 'fixed',
      menuPlacement: 'auto',
      menuShouldScrollIntoView: false,
      menuPortalTarget: wrapperRef.current ? wrapperRef.current : htmlDocument.body,
    }),
    [wrapperRef.current],
  );

  const setProgramType = useCallback(
    (programType: DocumentConstants.NullableProgramType) => setDocument((d) => ({ ...d, programType })),
    [],
  );

  useEffect(() => {
    if (!documentUploading) {
      return;
    }
    if (!uploadInProgress) {
      setUploadInProgress(true);
    }
    if (uploadStatus === DocumentState.UPLOADING) {
      setUploadStatus(
        failedUploads.find(({ document }) => document.id === documentId)
          ? DocumentState.FAILED
          : DocumentState.COMPLETED,
      );
    } else if (documentUploading === documentId) {
      setUploadStatus(DocumentState.UPLOADING);
    }
  }, [documentUploading]);

  useEffect(() => {
    setDocument((d) => ({ ...d, name: getDisplayName(), tags: pendingTags }));
  }, [fileType, JSON.stringify(requiredTags), fileName, pendingTags.length, document.programType]);

  useEffect(() => {
    const tags: DocumentTagView[] = requiredKeys?.map((key) => ({ key, value: '' })) || [];
    setRequiredTags(tags);
  }, [requiredKeys]);

  useEffect(() => {
    dispatch(documentEditCreators.updateDocumentUpload(documentId, { ...file, version, document, linkedDocument }));
  }, [document, version, linkedDocument]);

  useEffect(() => {
    if (effectiveProgramTypes.length === 1) {
      setProgramType(effectiveProgramTypes[0]);
    }
  }, [effectiveProgramTypesKey]);

  useEffect(() => {
    if (documentOnlySupportsConnect) {
      updateOptionalId(null);
      if (fileType !== DocumentConstants.TRIAL_SUMMARY) {
        setVersion((v) => ({ ...v, internal: true }));
      }
    }
  }, [documentOnlySupportsConnect]);

  useEffect(() => {
    if (!Boolean(loading)) {
      setClassificationDocuments(
        classificationOption && documents[classification][classificationOption.value]
          ? documents[classification][classificationOption.value]
          : [],
      );
    }
  }, [classificationOption, loading]);

  useEffect(() => {
    if (fileType === DocumentConstants.DocumentRollupConstants.OTHER && linkedDocument) {
      setDocument((d) => ({ ...d, category: linkedDocument.category }));
    }
    setVersion({ ...version, documentId: linkedDocument ? linkedDocument.id : '' });
  }, [linkedDocument]);

  const typeOptions: FileTypeDropdownOption[] = useMemo(
    () =>
      Object.values(DocumentConstants.RollupMappings[classification]).map(({ fileType, category, requiredKeys }) => ({
        category,
        value: fileType,
        label: fileType,
        requiredKeys,
      })),
    [classification],
  );

  const nameOptions: DropdownOption[] = useMemo(() => {
    return classificationDocuments
      .filter(({ type }) => type === DocumentConstants.DocumentRollupConstants.OTHER)
      .map(({ name }) => ({ label: name, value: name }));
  }, [classificationDocuments.map((d) => d.name).join(',')]);

  const programTypeOptions = useMemo(
    () => ProgramTypeOptions.filter((o) => effectiveProgramTypes.includes(o.programType)),
    [effectiveProgramTypesKey],
  );

  useEffect(() => {
    const matchingDocument = classificationDocuments.find(
      (doc) => doc.name === getDisplayName() && doc[classificationField(optionalClassification)] === optionalId,
    );
    setlinkedDocument(matchingDocument ? matchingDocument : null);
  }, [JSON.stringify(requiredTags), fileType, fileName, classificationDocuments, optionalId, document.programType]);

  useEffect(() => {
    setExpanded(
      fileType === DocumentConstants.DocumentRollupConstants.OTHER ||
        Boolean(requiredKeys) ||
        effectiveProgramTypes.length > 1,
    );
  }, [fileType, effectiveProgramTypesKey]);

  const toggleActive = useCallback(() => {
    setVersion((v) => ({ ...v, active: !v.active }));
  }, []);

  const toggleInternal = useCallback(() => {
    setVersion((v) => ({ ...v, internal: !v.internal }));
  }, []);

  const toggleVisibleToSponsor = useCallback(() => {
    setVersion((v) => ({ ...v, visibleToSponsor: !v.visibleToSponsor }));
  }, []);

  const updateVersion = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setVersion((v) => ({ ...v, version: value }));
  }, []);

  const updateName = useCallback((option: DropdownOption | null | undefined) => {
    setFileName(option ? option.value : '');
  }, []);

  const updateProgramType = useCallback((option) => {
    setProgramType((option as ProgramTypeDropdownOption).programType);
  }, []);

  useEffect(() => {
    if (fileType !== DocumentConstants.DocumentRollupConstants.OTHER) {
      setFileName(localFileName);
    }
  }, [fileType]);

  const changeFileType = (option: FileTypeDropdownOption) => {
    setFileType(option.value);
    setDocument((d) => ({ ...d, type: option.value, category: option.category }));
  };

  const confirmFileTypeChange = useCallback(() => {
    if (fileTypeChange) {
      changeFileType(fileTypeChange);
    }

    setPendingTags([]);
    setFileTypeChange(null);
  }, [fileTypeChange]);

  const updateFileType = useCallback(
    (option: DropdownOption | null | undefined) => {
      const typedOption = option as FileTypeDropdownOption;

      if (pendingTags.length) {
        setFileTypeChange(typedOption);
      } else {
        changeFileType(typedOption);
      }
    },
    [pendingTags.length],
  );

  const updateRequiredTag = useCallback(
    async (option: DropdownOption | null | undefined, requiredKey: DocumentTagKey) => {
      const pendingTagsWithoutRequiredTags = pendingTags.filter(
        (tag) => !requiredKeys?.includes(tag.key as DocumentTagKey),
      );
      const updatedRequiredTags = [...requiredTags];
      const tagIndex = updatedRequiredTags.findIndex((tag) => tag.key === requiredKey);
      const updatedTag: DocumentTagView = { key: requiredKey, value: option ? option.value : '' };
      updatedRequiredTags[tagIndex] = updatedTag;

      setRequiredTags(updatedRequiredTags);
      setPendingTags([...pendingTagsWithoutRequiredTags, ...updatedRequiredTags.filter((rt) => rt.value)]);
    },
    [requiredKeys, JSON.stringify(requiredTags)],
  );

  const updateOptionalId = useCallback(
    (option: DropdownOption | null | undefined) => {
      const value = option ? option.value : null;
      setOptionalId(value);
      setDocument((d) => ({ ...d, [classificationField(optionalClassification)]: value }));
    },
    [optionalClassification],
  );

  const updateSubcategory = useCallback(
    (option) => setDocument((d) => ({ ...d, category: option ? option.value : '' })),
    [],
  );

  const updateTags = useCallback(async (tags) => {
    setPendingTags(tags);
    return true;
  }, []);

  const renderStatus = () => {
    if (!uploadInProgress) {
      return (
        <React.Fragment>
          <button
            data-pendo-id="document-upload-delete-file"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(documentEditCreators.deleteDocumentUpload(documentId));
            }}>
            <Close color={grayPalette.gray50} className="delete-icon" />
          </button>
        </React.Fragment>
      );
    }
    if ([DocumentState.NOT_STARTED, DocumentState.UPLOADING].includes(uploadStatus)) {
      return <span />;
    }
    return uploadStatus === DocumentState.FAILED ? (
      <React.Fragment>
        <Fail className="failure-icon" />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Success className="success-icon" />
      </React.Fragment>
    );
  };

  const handleDatePickerChange = useCallback((option) => {
    setVersion((v) => ({ ...v, applicableAt: option.dateString }));
    setDatePickerOpen(false);
  }, []);

  useEffect(() => {
    if (datePickerOpen && dateColumnRef.current) {
      // There is no way to get a ref to the calendar itself, so we rely
      //   on the fact that it is the first element after the calendar
      //   button to get a reference to it.
      const calendar = dateColumnRef.current.querySelector('button + div');

      if (calendar) {
        calendar.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [datePickerOpen]);

  const toggleGroup = useCallback(() => setExpanded((e) => !e), []);
  const datePickerToggle = useCallback(() => setDatePickerOpen((o) => !o), []);
  const cancelFileTypeChange = useCallback(() => setFileTypeChange(null), []);
  const stopPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  const datePickerValue = useMemo(() => ({ dateString: version.applicableAt }), [version.applicableAt]);
  const tags = useMemo(() => [...existingTags, ...pendingTags], [existingTags.length + pendingTags.length]);
  const fileNameOption = useMemo(() => (fileName ? { label: fileName, value: fileName } : null), [fileName]);
  const programTypeOption = useMemo(() => programTypeOptions.find((o) => o.programType === document.programType), [
    document.programType,
    effectiveProgramTypesKey,
  ]);

  return (
    <div className="document-status" ref={wrapperRef}>
      {uploadStatus === DocumentState.UPLOADING && (
        <div className="uploading-indicator">
          <div className="progress-message">Uploading file...</div>
          <ProgressBar percent={75} className="progress-bar" color={colors.blue} />
        </div>
      )}
      <div className={`new-document ${uploadStatus === DocumentState.NOT_STARTED ? 'not-started' : ''}`}>
        <StyledExpandableGroup
          open={expanded}
          onClick={toggleGroup}
          expandIcon={null}
          header={
            <div className="document-wrapper">
              {Boolean(linkedDocument) && (
                <Popover
                  hideArrow
                  open={showVersionTooltip}
                  placement="top-start"
                  target={
                    <NewVersionBadge
                      small
                      onMouseEnter={() => setShowVersionTooltip(true)}
                      onMouseLeave={() => setShowVersionTooltip(false)}>
                      {'New version'}
                    </NewVersionBadge>
                  }
                  onRequestClose={() => setShowVersionTooltip(false)}>
                  {'This file already exists. Uploading with the selected values will create a new version.'}
                </Popover>
              )}
              <DocumentTypeChangeConfirmationModal
                showModal={Boolean(fileTypeChange)}
                cancel={cancelFileTypeChange}
                confirm={confirmFileTypeChange}
              />
              <div className="document-row" data-pendo-id="document-upload-document">
                <div className="expand-icon-column">
                  {expanded ? <ChevronUp className="expand-icon" /> : <ChevronDown className="expand-icon" />}
                </div>
                <div className="name-column tooltip">
                  <span className="tooltip-text">{fileName}</span>

                  <NoLabelCreatable
                    label="name"
                    options={nameOptions}
                    onChange={updateName}
                    reactSelectProps={selectProps}
                    value={fileNameOption}
                    disabled={uploadInProgress || fileType !== DocumentConstants.DocumentRollupConstants.OTHER}
                    theme={
                      showErrors && fileType === DocumentConstants.DocumentRollupConstants.OTHER && !fileName.length
                        ? InputTheme.Error
                        : InputTheme.Default
                    }
                    onClick={stopPropagation}
                    data-pendo-id="document-upload-name-input"
                  />
                </div>
                <div className="dropdown-column">
                  <NoLabelCreatable
                    label="type"
                    createDisabled
                    clearable={false}
                    options={typeOptions}
                    reactSelectProps={selectProps}
                    data-pendo-id="document-upload-type-select"
                    value={typeOptions.find((opt) => opt.value === fileType)}
                    disabled={uploadInProgress}
                    onClick={stopPropagation}
                    onChange={updateFileType}
                    theme={showErrors && !document.type.length ? InputTheme.Error : InputTheme.Default}
                  />
                </div>
                <div className="fixed-column">
                  <NoLabelInput
                    label="version"
                    value={version.version}
                    onClick={stopPropagation}
                    onChange={updateVersion}
                    disabled={uploadInProgress}
                    data-pendo-id="document-upload-version-input"
                    theme={
                      showErrors && !versionIsValid(version.version, version.id, linkedDocument)
                        ? InputTheme.Error
                        : InputTheme.Default
                    }
                  />
                </div>
                <div className="date-column" ref={dateColumnRef}>
                  <MemoizedNoLabelDatePicker
                    label="applicableAt"
                    classes={datePickerStyles}
                    isOpen={datePickerOpen}
                    onClick={stopPropagation}
                    data-pendo-id="document-upload-date-select"
                    onChange={handleDatePickerChange}
                    onToggleIsOpen={datePickerToggle}
                    value={datePickerValue}
                    disabled={uploadInProgress}
                    theme={showErrors && !version.applicableAt.length ? InputTheme.Error : InputTheme.Default}
                  />
                </div>
                <div className="toggle-group">
                  <div className="toggle-column">
                    <Switch
                      on={version.active}
                      onChange={toggleActive}
                      disabled={uploadInProgress}
                      data-pendo-id="document-upload-active-toggle"
                    />
                  </div>
                  <div className="toggle-column">
                    <Switch
                      on={!version.internal}
                      onChange={toggleInternal}
                      // "External Site" toggle is disabled for all Connect documents *EXCEPT* "Trial summary"
                      disabled={
                        uploadInProgress || (!documentSupportsTime && fileType !== DocumentConstants.TRIAL_SUMMARY)
                      }
                      data-pendo-id="document-upload-external-toggle"
                    />
                  </div>
                  <div className="toggle-column">
                    <Switch
                      disabled={uploadInProgress}
                      on={version.visibleToSponsor}
                      onChange={toggleVisibleToSponsor}
                      data-pendo-id="document-upload-external-sponsor-toggle"
                    />
                  </div>
                </div>
                <div className="dropdown-column">
                  {!documentOnlySupportsConnect && (
                    <NoLabelCreatable
                      createDisabled
                      label={optionalClassification}
                      clearable={true}
                      options={optionalClassificationOptions}
                      reactSelectProps={selectProps}
                      value={optionalClassificationOptions.find((opt) => opt.value === optionalClassification)}
                      onClick={stopPropagation}
                      data-pendo-id={`document-upload-${optionalClassification}-select`}
                      onChange={updateOptionalId}
                      disabled={uploadInProgress}
                      theme={
                        showErrors && optionalClassificationRequired && !optionalId
                          ? InputTheme.Error
                          : InputTheme.Default
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          }>
          <div className={expanded ? 'expanded-row' : 'collapsed-row'}>
            <div className="expand-icon-column" />
            {requiredKeys &&
              requiredKeys.map((requiredKey) => {
                const tagValue = (requiredTags.find((tag) => tag.key === requiredKey) || {}).value;
                return (
                  <div className="expanded-dropdown" key={requiredKey}>
                    <TagValueSelect
                      localTags={localTags}
                      tagKey={requiredKey}
                      tagValue={tagValue}
                      label={requiredKey}
                      onChange={updateRequiredTag}
                      theme={showErrors && !tagValue ? InputTheme.Error : InputTheme.Default}
                      classes={tagPickerLabelStyles}
                    />
                  </div>
                );
              })}
            {fileType === DocumentConstants.DocumentRollupConstants.OTHER && (
              <div className="expanded-dropdown">
                <LabelCombobox
                  createDisabled
                  label="Category"
                  options={categoryOptionsByClassification[classification]}
                  clearable={false}
                  reactSelectProps={selectProps}
                  data-pendo-id="document-upload-category-select"
                  value={categoryOptionsByClassification[classification].find((opt) => opt.value === document.category)}
                  disabled={Boolean(linkedDocument) || uploadInProgress}
                  onClick={stopPropagation}
                  onChange={updateSubcategory}
                  theme={showErrors && !document.category.length ? InputTheme.Error : InputTheme.Default}
                />
              </div>
            )}
            {programTypeOptions.length > 1 && (
              <div className="expanded-dropdown">
                <LabelCombobox
                  createDisabled
                  clearable={false}
                  label="Program"
                  options={programTypeOptions}
                  disabled={uploadInProgress}
                  onClick={stopPropagation}
                  onChange={updateProgramType}
                  reactSelectProps={selectProps}
                  value={programTypeOption}
                  data-pendo-id="document-upload-program-type-select"
                  theme={
                    showErrors && !effectiveProgramTypes.includes(document.programType)
                      ? InputTheme.Error
                      : InputTheme.Default
                  }
                />
              </div>
            )}
            <div className="tag-picker-row" data-pendo-id="document-upload-tags">
              <div className={rootLabelStyles.root}>Tags</div>
              <DocumentTagPicker
                localTags={localTags}
                className="tag-picker"
                readOnly={existingTags.length > 0}
                tags={tags}
                excludedKeys={requiredKeys ? requiredKeys : undefined}
                onTagsChanged={updateTags}
              />
            </div>
          </div>
        </StyledExpandableGroup>
        <div className="delete-icon-column">{renderStatus()}</div>
      </div>
    </div>
  );
};
