import { Styles } from '@tempus/component-library/styles';
import withStyles from '@tempus/component-library/utils/withStyles';
import { Input } from 'tcl-v3/foundations';
import { SingleDatePicker, SingleSelectCombobox, MultiSelectCombobox } from 'tcl-v3/prefabs';

import { CreatableSingleSelectCombobox } from '~/components/CreatableSingleSelectCombobox';

const labelStyles: Styles = {
  label: {
    display: 'none !important',
    fontSize: '0 !important',
    height: '0 !important',
    margin: '0 !important',
    lineHeight: '0 !important',
  },
};

export const NoLabelDatePicker = withStyles(labelStyles)(SingleDatePicker);

export const NoLabelCombobox = withStyles(labelStyles)(SingleSelectCombobox);

export const NoLabelMultiCombobox = withStyles(labelStyles)(MultiSelectCombobox);

export const NoLabelInput = withStyles(labelStyles)(Input);

export const NoLabelCreatable = withStyles(labelStyles)(CreatableSingleSelectCombobox);
