import { PROGRAM_TYPES_DEC, PROGRAM_TYPES, TrialArm } from '@tempus/t-shared';

import { Trial } from '~/store/trial/types';

export const getEnrollingCohorts = (trial?: Trial) => (trial ? trial.cohorts.filter((c) => c.status) : []);

export const getProgramFromCohorts = (cohorts?: Pick<TrialArm, 'programType'>[]): string => {
  if (!cohorts) {
    throw new Error('No cohorts could be found.');
  }

  const hasTimeCohorts = cohorts.some((c) => c.programType === PROGRAM_TYPES_DEC.TIME);
  const hasStCohorts = cohorts.some((c) => c.programType === PROGRAM_TYPES_DEC.ST);

  if (hasTimeCohorts) {
    return PROGRAM_TYPES.TIME;
  } else if (hasStCohorts) {
    return PROGRAM_TYPES.ST;
  } else {
    throw new Error('No valid program could be found.');
  }
};
