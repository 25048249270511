import { ALL_SITES, DocumentConstants } from '@tempus/t-shared';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import TherapiesDocumentListPage from '~/components/TherapiesDocumentListPage';
import { Routes } from '~/routes';
import { RootState } from '~/store';
import { creators as siteCreators } from '~/store/site';

interface RouteMatchParams {
  siteId: string;
}

const SiteDocuments: React.FC<RouteComponentProps<RouteMatchParams>> = () => {
  const selectedUserSite = useSelector(({ site }: RootState) => site.selectedUserSite);
  const history = useHistory();
  const userSites = useSelector(({ site }: RootState) => site.userSites);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedUserSite?.id) {
      history.push(Routes.siteDocuments.path.replace(':siteId?', selectedUserSite.id));
    } else if (selectedUserSite?.name === ALL_SITES) {
      dispatch(siteCreators.setUserSites(userSites, null));
    }
  }, [selectedUserSite?.id]);

  if (selectedUserSite?.id) {
    return (
      <TherapiesDocumentListPage
        classificationId={selectedUserSite?.id}
        classification={DocumentConstants.Classification.Site}
      />
    );
  } else {
    return null;
  }
};

export default withRouter(SiteDocuments);
