import { AccentPalette, GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    height: '100%',
    padding: '24px 0 24px 24px',
    backgroundColor: GrayscalePalette[200],
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    overflowY: 'auto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  match: {
    backgroundColor: 'rgba(249, 249, 250, 1)',
    borderRadius: '10px',
    border: `1px solid ${GrayscalePalette[400]}`,
    position: 'relative',
    padding: '16px',
    marginBottom: '16px',
    height: '100%',

    '&:not(:first-child)': {
      paddingTop: '12px',
    },
  },
  trialTitle: {
    fontWeight: 600,
    marginBottom: '8px',
  },
  statusRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  editIcon: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    cursor: 'pointer',
  },
  row: {
    marginTop: '16px',
  },
  border: {
    borderBottom: '1px solid rgba(233, 233, 234, 1)',
  },
  viewLink: {
    cursor: 'pointer',
    color: AccentPalette[100],
    marginTop: '5px',
  },
  naText: {
    color: `${GrayscalePalette[500]}`,
  },
});

export const useCollapseStyles = createUseStyles({
  root: {
    marginBottom: '16px',
  },
  titleWrapper: {
    justifyContent: 'flex-start',
    marginBottom: '16px',
  },
  title: {
    fontWeight: 400,
    letterSpacing: '2px',
    fontSize: '15px',
    textTransform: 'uppercase',
  },
  container: {
    paddingRight: 0,
  },
});
