import * as React from 'react';

const NoAccessSvg: React.FC = () => {
  return (
    <svg width="172" height="204" viewBox="0 0 172 204" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.0456032"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5941 176C16.2954 176 12 171.705 12 166.406L12 56.3878C12 51.0891 16.2954 46.7937 21.5941 46.7937H122.269C127.568 46.7937 131.863 51.0891 131.863 56.3878V166.406C131.863 171.705 127.568 176 122.269 176H21.5941Z"
        fill="#042261"
        fillOpacity="0.73"
      />
      <path
        opacity="0.0456032"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7308 157.206C44.4322 157.206 40.1367 152.911 40.1367 147.612L40.1367 37.5941C40.1367 32.2954 44.4322 28 49.7308 28H150.406C155.704 28 160 32.2954 160 37.5941V147.612C160 152.911 155.704 157.206 150.406 157.206H49.7308Z"
        fill="#042261"
        fillOpacity="0.73"
      />
      <g filter="url(#filter0_dd_2397_73854)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.099 59.6414C139.774 59.6414 143.564 63.4314 143.564 68.1068V134.2C143.564 138.876 139.774 142.666 135.099 142.666H37.2314C32.5561 142.666 28.766 138.876 28.766 134.2V68.1068C28.766 63.4314 32.5561 59.6414 37.2314 59.6414L135.099 59.6414Z"
          fill="white"
        />
      </g>
      <rect opacity="0.1" x="39.1719" y="122.62" width="93.6562" height="7.21951" fill="#5A6479" />
      <rect opacity="0.1" x="39.1719" y="110.588" width="93.6562" height="7.21951" fill="#5A6479" />
      <rect opacity="0.1" x="39.1719" y="80.5062" width="93.6562" height="22.8618" rx="10.9844" fill="#5A6479" />
      <g filter="url(#filter1_d_2397_73854)">
        <rect x="38.7168" y="70.5645" width="93.6562" height="19.252" rx="9.62602" fill="white" fillOpacity="0.59" />
        <rect
          x="38.1524"
          y="70.0001"
          width="94.785"
          height="20.3808"
          rx="10.1904"
          stroke="#A0A0A3"
          strokeWidth="1.12872"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.3289 82.1705C47.4185 82.1705 45.8646 80.6155 45.8646 78.7039C45.8646 76.7926 47.4185 75.2377 49.3289 75.2377C51.2392 75.2377 52.7936 76.7926 52.7936 78.7039C52.7936 80.6155 51.2392 82.1705 49.3289 82.1705ZM56.3478 83.8654L52.9779 81.1177C53.4369 80.4249 53.705 79.5955 53.705 78.7039C53.705 76.2896 51.7416 74.3252 49.3289 74.3252C46.9162 74.3252 44.9531 76.2896 44.9531 78.7039C44.9531 81.1186 46.9162 83.0825 49.3289 83.0825C50.5236 83.0825 51.6075 82.6012 52.398 81.8216L55.7719 84.5725C55.8565 84.6416 55.9583 84.6748 56.0593 84.6748C56.1917 84.6748 56.3229 84.6175 56.4132 84.5071C56.5722 84.3116 56.5428 84.024 56.3478 83.8654Z"
        fill="#231F20"
      />
      <defs>
        <filter
          id="filter0_dd_2397_73854"
          x="24.7656"
          y="56.6414"
          width="122.799"
          height="96.0244"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2397_73854" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2397_73854" result="effect2_dropShadow_2397_73854" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2397_73854" result="shape" />
        </filter>
        <filter
          id="filter1_d_2397_73854"
          x="33.5879"
          y="69.4358"
          width="103.914"
          height="29.5094"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.24446 0 0 0 0 0.270514 0 0 0 0 0.341231 0 0 0 0.369373 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2397_73854" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2397_73854" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default NoAccessSvg;
