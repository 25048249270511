import { Card } from '@tcl-boron-prefabs/card';
import React from 'react';
import { Information } from 'tcl-v3/icons';

import trialMatchCardStyles from './TrialMatchCard.styles';

interface NoActiveTrialMatchesCardProps {
  showInactiveMatches: () => void;
}

export const NoActiveTrialMatchesCard: React.FC<NoActiveTrialMatchesCardProps> = ({ showInactiveMatches }) => {
  const classes = trialMatchCardStyles();

  return (
    <Card className={classes.trialMatchCard}>
      <>
        <div className={classes.trialDetailsSection}>
          <div className={classes.inactiveMatchesCardHeader}>
            <div className={classes.inactiveCardTitle}>
              <Information />
              <span className={classes.inactiveCardTitleText}>No trial matches to review </span>
            </div>
            <div className={classes.inactiveCardLink} onClick={showInactiveMatches}>
              View inactive matches
            </div>
          </div>
          <div className={classes.inactiveCardMessage}>
            This patient has no trials they are being considered for.
            <br />
            <br />
            View inactive matches to see any trials this patient may have enrolled in or is no longer a candidate for.
          </div>
        </div>
      </>
    </Card>
  );
};

export default NoActiveTrialMatchesCard;
