import { ROLES } from '@tempus/t-shared';
import { BaseUserState } from '@tempus/t-shared/ui';

import { impersonatedIds } from '../api/axios';
import { BaseUserStateWithFeatures } from './types';

export { default as sagas } from './sagas';
export { creators } from './actions';

export type EnhancedUserState = {
  effectiveRoles: string[];
  canImpersonate: boolean;
  canWriteDocuments: boolean;
  canViewNewDocuments: boolean;
  canViewInternalDocuments: boolean;
  canViewSiteDocuments: boolean;
  canViewAllPatientTracking: boolean;
  canViewPatientTracking: boolean;
  canWritePatientTracking: boolean;
  canWritePatientTrackingExternal: boolean;
  features: string[];
};

const defaultEnhancedUserState: EnhancedUserState = {
  effectiveRoles: [],
  canImpersonate: false,
  canWriteDocuments: false,
  canViewNewDocuments: false,
  canViewInternalDocuments: false,
  canViewSiteDocuments: false,
  canViewAllPatientTracking: false,
  canViewPatientTracking: false,
  canWritePatientTracking: false,
  canWritePatientTrackingExternal: false,
  features: [],
};

export default (base: BaseUserState): BaseUserState & EnhancedUserState => {
  const { user, unprefixedRoles } = base as BaseUserStateWithFeatures;

  if (!user) {
    return { ...base, ...defaultEnhancedUserState };
  }

  const effectiveRoles = impersonatedIds.length
    ? [ROLES.T_TRIALS_READ, ROLES.T_DOCUMENTS_READ, ROLES.T_PATIENT_TRACKER_READ, ROLES.T_PATIENT_TRACKER_WRITE]
    : unprefixedRoles;

  // Impersonation uses actual roles because we want to show the option for
  //   users even when they are impersonating.
  const canImpersonate = unprefixedRoles.includes(ROLES.T_SITE_INTERNAL_USER);

  const canViewInternalDocuments = effectiveRoles.includes(ROLES.T_DOCUMENTS_READ_INTERNAL);

  const canViewPatientTracking = effectiveRoles.some((role) =>
    [ROLES.T_PATIENT_TRACKER_READ, ROLES.T_PATIENT_TRACKER_READ_INTERNAL].includes(role),
  );

  const canWritePatientTracking = effectiveRoles.some((role) =>
    [ROLES.T_PATIENT_TRACKER_WRITE, ROLES.T_PATIENT_TRACKER_WRITE_INTERNAL].includes(role),
  );

  return {
    ...base,
    effectiveRoles,
    canImpersonate,
    canViewInternalDocuments,
    canViewNewDocuments: canImpersonate || effectiveRoles.includes(ROLES.T_DOCUMENTS_READ),
    canWriteDocuments: effectiveRoles.includes(ROLES.T_DOCUMENTS_WRITE),
    canViewSiteDocuments: canImpersonate || effectiveRoles.includes(ROLES.T_DOCUMENTS_READ),
    canViewAllPatientTracking: effectiveRoles.includes(ROLES.T_PATIENT_TRACKER_READ_INTERNAL),
    canViewPatientTracking,
    canWritePatientTrackingExternal: effectiveRoles.includes(ROLES.T_PATIENT_TRACKER_WRITE),
    canWritePatientTracking,
    features: user.features || [],
  };
};
