import { CaretDown } from '@tcl-boron-icons/icons';
import { Button } from '@tcl-boron-prefabs/button';
import { Menu, MenuItem } from '@tcl-boron-prefabs/menu';
import { ALL_SITES } from '@tempus/t-shared';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '~/store';
import { creators as patientTrackerCreators } from '~/store/patientTrackerV2';
import { PatientTrackerFilterField, PatientTrackerStateId } from '~/store/patientTrackerV2/types';

import useStyles from './styles';

const MENU_IDS = {
  ALL_PATIENTS: 'All_Patients',
  FILTERED_PATIENTS: 'Filtered_Patients',
};

const DownloadPatients: React.FC<{
  stateId: string;
  siteId: string;
  small?: boolean;
}> = ({ stateId, siteId, small }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    user: { canImpersonate },
    site: { selectedUserSite },
  } = useSelector((state: RootState) => state);
  const { statusCategory, categoryCounts, fetchingRecords, exports } = useSelector(
    ({ patientTrackerV2 }: RootState) => patientTrackerV2[stateId],
  );
  const { loading: exporting, data: csvData, fileName } = exports;
  const currentCategoryCount = categoryCounts?.[statusCategory] || 0;
  const allCategoriesCount = Object.values(categoryCounts).reduce((acc, count) => acc + count, 0);

  const isAllSitesSelected = selectedUserSite?.name == ALL_SITES;
  const internalUserSelectedAllSites = canImpersonate && isAllSitesSelected;

  const loading = exporting;

  const menuGroup: MenuItem[] = [
    allCategoriesCount > 0 &&
      !internalUserSelectedAllSites && {
        text: 'All patients',
        id: MENU_IDS.ALL_PATIENTS,
      },
    currentCategoryCount > 0 && {
      text: 'Filtered patients',
      id: MENU_IDS.FILTERED_PATIENTS,
    },
  ].filter(Boolean) as MenuItem[];

  const handleDownload = (menuItemId?: string) => {
    dispatch(
      patientTrackerCreators.getPatientTrackerRecordsCsv(stateId, siteId, {
        includeAllCategories: true,
        customFilterList:
          menuItemId === MENU_IDS.ALL_PATIENTS
            ? stateId === PatientTrackerStateId.TRIAL_DOCUMENTS_PAGE_PATIENTS
              ? [PatientTrackerFilterField.TRIAL_NAME]
              : []
            : undefined,
      }),
    );
  };

  useEffect(() => {
    if (csvData && fileName) {
      const data = new Blob([csvData], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = csvURL;
      link.download = fileName!;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [csvData, fileName]);

  return (
    <Menu
      className={classes.downloadMenu}
      onClose={() => setIsMenuOpen(false)}
      menuItems={[menuGroup]}
      isOpen={isMenuOpen}
      onChange={handleDownload}>
      <Button
        onClick={() => setIsMenuOpen(true)}
        loading={loading}
        disabled={!menuGroup.length || fetchingRecords}
        ariaLabel="download"
        small={small}>
        Download <CaretDown />
      </Button>
    </Menu>
  );
};

export default DownloadPatients;
