import { Summary } from '@tempus/component-library/icons';
import { colors } from '@tempus/component-library/styles';
import { NOT_AVAILABLE_TIME_SITE_STATUSES, TimeProgramSiteStatus } from '@tempus/t-shared';
import { shouldShowDocumentBadge } from '@tempus/t-shared/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import styled from 'styled-components';
import '@tcl-boron-fonts/ibm-plex-sans/dist/ibm-plex-sans.css';

import { RootState } from '~/store';
import { SiteWithId } from '~/store/site/types';
import { Trial } from '~/store/trial/types';

const Styles = styled.div`
  .documents-cell {
    display: flex;
    justify-content: center;

    .updated-or-new-badge {
      position: relative;

      ::after {
        left: 4px;
        content: '•';
        bottom: -7px;
        font-size: 40px;
        position: absolute;
        color: ${colors.green};
      }
    }

    .target {
      display: flex;
      align-items: center;
    }

    [data-type='zip'] {
      padding: 15px;
      font-size: 12px;
      color: ${colors.blue};
      font-family: 'IBM Plex Sans';
      border-top: 1px solid ${colors.gray};

      svg {
        margin-right: 15px;
      }
    }
  }
`;

type CommonCellProps = {
  latestApplicableAt: string | null | undefined;
  documentUrl: string;
  status?: TimeProgramSiteStatus;
};

type TrialDocumentsCellProps = CellProps<Trial> & CommonCellProps;

type SiteDocumentsCellProps = CellProps<SiteWithId> & CommonCellProps;

export const DocumentsCell: React.FC<TrialDocumentsCellProps | SiteDocumentsCellProps> = (props) => {
  const {
    user: { canImpersonate },
  } = useSelector((state: RootState) => state);
  const { latestApplicableAt, documentUrl, status } = props;
  if (status && NOT_AVAILABLE_TIME_SITE_STATUSES.includes(status) && !canImpersonate) {
    return null;
  }
  const history = useHistory();

  const renderDocumentsLink = () => {
    const showUpdatedDocumentBadge = shouldShowDocumentBadge(latestApplicableAt);
    const className = showUpdatedDocumentBadge ? 'updated-or-new-badge' : '';

    const navigateToDocuments = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      history.push(documentUrl);
    };

    if (!latestApplicableAt) {
      return null;
    }

    return (
      <Styles>
        <div className="documents-cell">
          <button onClick={navigateToDocuments} className={className} data-testid="document-cell">
            <Summary />
          </button>
        </div>
      </Styles>
    );
  };

  return renderDocumentsLink();
};

export const TrialDocumentsCell: React.FC<TrialDocumentsCellProps> = (props) => {
  const trial = props.row.original;
  const trialId = trial.id;
  const latestApplicableAt = trial.$meta?.latestDocumentApplicableAt;
  const documentUrl = `/trials/${trialId}/documents`;
  const status = trial.status;
  return DocumentsCell({ ...props, latestApplicableAt, documentUrl, status });
};

export const SiteDocumentsCell: React.FC<SiteDocumentsCellProps> = (props) => {
  const site = props.row.original;
  const latestApplicableAt = site.latestDocumentApplicableAt;
  const documentUrl = `/sites/${site.id}/documents`;
  return DocumentsCell({ ...props, latestApplicableAt, documentUrl });
};
