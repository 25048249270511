import { DocumentView } from '@tempus/t-shared';

import {
  DocumentEditActionTypes,
  SET_EDITING_VERSION_ID,
  UPDATE_DOCUMENT,
  GET_TAGS,
  GET_TAG,
  DocumentEditState,
  SET_TAGS,
  DocumentFile,
  SET_FAILED_UPLOADS,
  DELETE_VERSION,
  SET_DOCUMENT_UPLOADS,
  UPDATE_DOCUMENT_UPLOAD,
  DELETE_DOCUMENT_UPLOAD,
  UPDATE_DOCUMENT_EDIT_STATE,
} from './types';

export const creators = {
  setEditingVersionId(id: string): DocumentEditActionTypes {
    return { type: SET_EDITING_VERSION_ID, id };
  },
  updateDocument(document: DocumentView): DocumentEditActionTypes {
    return { type: UPDATE_DOCUMENT, document };
  },
  deleteVersion(documentId: string, versionId: string): DocumentEditActionTypes {
    return { type: DELETE_VERSION, documentId, versionId };
  },
  getTags(): DocumentEditActionTypes {
    return { type: GET_TAGS };
  },
  getTag(key: string): DocumentEditActionTypes {
    return { type: GET_TAG, key };
  },
  setTags(tags: DocumentEditState['tags'], appendTags?: boolean): DocumentEditActionTypes {
    return { type: SET_TAGS, tags, appendTags };
  },
  setFailedUploads(document: DocumentFile | null): DocumentEditActionTypes {
    return { type: SET_FAILED_UPLOADS, document };
  },
  setDocumentUploads(documents: DocumentFile[]): DocumentEditActionTypes {
    return { type: SET_DOCUMENT_UPLOADS, documents };
  },
  updateDocumentUpload(documentId: string, document: DocumentFile): DocumentEditActionTypes {
    return { type: UPDATE_DOCUMENT_UPLOAD, documentId, document };
  },
  deleteDocumentUpload(documentId: string): DocumentEditActionTypes {
    return { type: DELETE_DOCUMENT_UPLOAD, documentId };
  },
  updateDocumentEditState(state: Partial<DocumentEditState>): DocumentEditActionTypes {
    return { type: UPDATE_DOCUMENT_EDIT_STATE, state };
  },
};
