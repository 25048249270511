import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { TrialType, LfsTrialStatus, TimePatientStatuses, TimePatientStatusesDetail } from '@tempus/t-shared';
import {
  PATIENT_TRACKER_STATUS_ROLLUP,
  PATIENT_TRACKER_STATUSES,
} from '@tempus/t-shared/src/constants/patient-tracker';
import React from 'react';
import { DropdownOption } from 'tcl-v3/models';

export const PATIENT_TRACKER_CATEGORY_LOOKUP = Object.entries(PATIENT_TRACKER_STATUS_ROLLUP).reduce(
  (acc, [category, statusArray]) => {
    statusArray.forEach((status) => {
      acc[status] = category;
    });

    return acc;
  },
  {},
);

export enum VisitType {
  PROVIDER_VISIT = 'Provider Visit',
  BLOOD_DRAW = 'Blood Draw',
  SCAN = 'Scan',
  OTHER = 'Other',
}

export enum PATIENT_TRACKER_ACKNOWLEDGED_STATUS {
  NEEDS_REVIEW = 'Needs Review',
  NOT_APPROVED = 'Not Approved',
  APPROVED = 'Approved',
}

export const renderExternalPatientStatus = (status: string) => {
  switch (status) {
    // Display as "New - Pending Site/Tempus Review" as requested by Design Team
    // Avoided backend change in QB because much logic in QB & LinkSites tied to these statuses
    // and QB is being deprecated in two quarters
    case PATIENT_TRACKER_STATUSES.PENDING_SITE_REVIEW:
      return `New - ${status}`;
    default:
      return status;
  }
};

export enum SORT_OPTION {
  URGENCY = 'Urgency',
  UPCOMING_VISIT = 'Upcoming visit',
  OLDEST = 'Date added (oldest first)',
  NEWEST = 'Date added (newest first)',
  MATCH_STATUS = 'Match status',
}

export const getSortDropdownOptions = (): DropdownOption[] =>
  Object.keys(SORT_OPTION).map((key) => ({
    label: SORT_OPTION[key],
    value: key,
  }));

const trialStatusDisplayNames = {
  [LfsTrialStatus.ACTIVE]: 'Active at my site',
  [LfsTrialStatus.AVAILABLE_TO_ACTIVATE]: 'Available to activate',
  [LfsTrialStatus.ON_HOLD]: 'On hold',
};

export const getMatchStatusDropdownOptions = (isInternal: boolean = false): DropdownOption[] => {
  const sortedMatchStatuses = [
    PATIENT_TRACKER_STATUSES.URGENT,
    PATIENT_TRACKER_STATUSES.PENDING_TEMPUS_REVIEW,
    PATIENT_TRACKER_STATUSES.PENDING_SITE_REVIEW,
    PATIENT_TRACKER_STATUSES.WATCHLIST,
    PATIENT_TRACKER_STATUSES.IMMINENT_MATCH,
    PATIENT_TRACKER_STATUSES.PURSUING_ACTIVATION,
    PATIENT_TRACKER_STATUSES.CONSENTED,
    PATIENT_TRACKER_STATUSES.ENROLLED,
    PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE,
    PATIENT_TRACKER_STATUSES.TRIAL_COHORT_ON_HOLD,
    PATIENT_TRACKER_STATUSES.SITE_ON_HOLD,
  ];
  if (isInternal) {
    sortedMatchStatuses.push(PATIENT_TRACKER_STATUSES.INTERNAL_REVIEW);
  }

  return sortedMatchStatuses.map((status) => ({
    label: status,
    value: status,
  }));
};

export const getTrialStatusDropdownOptions = (): DropdownOption[] => {
  const options = [LfsTrialStatus.ACTIVE, LfsTrialStatus.AVAILABLE_TO_ACTIVATE, LfsTrialStatus.ON_HOLD];
  return options.map((opt) => ({
    label: trialStatusDisplayNames[opt],
    value: opt,
  }));
};

export const getVisitTypeDropdownOptions = (): DropdownOption[] =>
  Object.keys(VisitType).map((key) => ({
    label: VisitType[key],
    value: VisitType[key],
  }));

export const getTrialTypeDropdownOptions = (): DropdownOption[] =>
  Object.keys(TrialType).map((key) => ({
    label: TrialType[key],
    value: TrialType[key],
  }));

export const getPatientStatusDropdownOptions = (): DropdownOption[] =>
  Object.keys(TimePatientStatuses).map((key) => ({
    label: TimePatientStatuses[key],
    value: TimePatientStatuses[key],
    subContent: React.createElement('div', { style: { color: GrayscalePalette[600] } }, TimePatientStatusesDetail[key]),
  }));

export enum PATIENT_OVERLAY_TAB_ID {
  ACTIVITIES = 'activities',
  PATIENT_DETAILS = 'patientDetails',
  INACTIVE_MATCHES = 'inactiveMatches',
}
