import { AccentPalette, GrayscalePalette, SuccessPalette, WarningPalette } from '@tcl-boron-colors/colors';
import { NotEditable } from '@tcl-boron-icons/icons';
import { Checkmark } from '@tempus/component-library/icons';
import { PATIENT_TRACKER_STATUSES } from '@tempus/t-shared/src/constants/patient-tracker';
import React from 'react';

import { useStyles } from './styles';

export const Circle: React.FC<{ color: string }> = ({ color }) => {
  return <span className={useStyles().dot} style={{ backgroundColor: color }}></span>;
};

interface MatchStatusProps {
  status: PATIENT_TRACKER_STATUSES;
}

export const MatchStatus: React.FC<MatchStatusProps> = ({ status }) => {
  const classes = useStyles();

  const getColor = () => {
    switch (status) {
      case PATIENT_TRACKER_STATUSES.URGENT:
        return WarningPalette[700];
      case PATIENT_TRACKER_STATUSES.PENDING_SITE_REVIEW:
      case PATIENT_TRACKER_STATUSES.PENDING_TEMPUS_REVIEW:
        return AccentPalette[100];
      case PATIENT_TRACKER_STATUSES.IMMINENT_MATCH:
      case PATIENT_TRACKER_STATUSES.PURSUING_ACTIVATION:
      case PATIENT_TRACKER_STATUSES.CONSENTED:
        return SuccessPalette[700];
      case PATIENT_TRACKER_STATUSES.WATCHLIST:
      case PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE:
      case PATIENT_TRACKER_STATUSES.TRIAL_COHORT_ON_HOLD:
      case PATIENT_TRACKER_STATUSES.SITE_ON_HOLD:
      case PATIENT_TRACKER_STATUSES.INTERNAL_REVIEW:
        return GrayscalePalette[500];
      default:
        return 'transparent';
    }
  };

  const displayIcon = () => {
    switch (status) {
      case PATIENT_TRACKER_STATUSES.ENROLLED:
        return (
          <span>
            <Checkmark width={8} height={8} color={SuccessPalette[700]} />
          </span>
        );
      case PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE:
        return <NotEditable width={8} height={8} />;
      default:
        return <Circle color={getColor()} />;
    }
  };

  return (
    <>
      <span>
        {displayIcon()}
        <span className={classes.matchStatus}>{status}</span>
      </span>
    </>
  );
};

export default MatchStatus;
