import { Button } from '@tcl-boron-prefabs/button';
import { AVAILABLE_TIME_SITE_STATUSES, NOT_AVAILABLE_TIME_SITE_STATUSES, ValuesetCodes } from '@tempus/t-shared';
import React from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { convertPatientInputToRulesEnginePatient } from '~/routes/trialsPage/trialSearch/utils';
import { RootState } from '~/store';
import { setShowUnavailableTrialsButton } from '~/store/trialSearch/slice';
import { getTrialMatches } from '~/store/trialSearch/thunks';

interface UnavailableTrialsButtonProps extends RouteComponentProps, ConnectedProps<typeof connector> {}

const UnavailableTrialsButton: React.FC<UnavailableTrialsButtonProps> = ({
  getTrialMatches,
  trialSearch,
  selectedUserSite,
}) => {
  const { loading, patientInput, showUnavailableTrialsButton } = trialSearch;

  const dispatch = useDispatch();
  const solidTumorOptions = useSelector(
    ({ trialSearch: { dropdowns } }: RootState) => dropdowns[ValuesetCodes.COHORT_CANCER_SOLID_TUMORS] || [],
  );
  const fireSearch = () => {
    const patient = convertPatientInputToRulesEnginePatient(patientInput, solidTumorOptions);
    if (selectedUserSite) {
      getTrialMatches({
        searchParams: { patient },
        siteId: selectedUserSite.id,
        statuses: [...AVAILABLE_TIME_SITE_STATUSES, ...NOT_AVAILABLE_TIME_SITE_STATUSES],
      });
      dispatch(setShowUnavailableTrialsButton(false));
    }
  };
  if (loading) {
    return null;
  }
  return (
    <div>
      {showUnavailableTrialsButton ? (
        <Button
          data-pendo-id="show-not-available-trials-find-trials"
          buttonType="secondary"
          onClick={fireSearch}
          ariaLabel={'Show unavailable trials'}>
          Show trials not available to my site
        </Button>
      ) : null}
    </div>
  );
};

const connector = connect(
  (state: RootState) => {
    return {
      trialSearch: state.trialSearch,
      selectedUserSite: state.site.selectedUserSite,
    };
  },
  {
    getTrialMatches: getTrialMatches.action,
  },
);

export default withRouter(connector(UnavailableTrialsButton));
