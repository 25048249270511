import { SagaIterator } from '@redux-saga/core';
import { storeActions } from '@tempus/t-shared/ui';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';

import { RootState } from '~/store';
import api from '~/store/api';

import { creators } from './actions';
import {
  GetAllSitesFailedAction,
  GetUserSitesFailedAction,
  GET_ALL_SITES,
  GET_ALL_SITES_FAILED,
  GET_USER_SITES,
  GET_USER_SITES_FAILED,
} from './types';

function* fetchAllSites(): SagaIterator {
  const { timeSites } = (yield select(({ site }: RootState) => site)) as RootState['site'];

  if (timeSites.length) {
    return;
  }

  try {
    const { data } = yield call(api.timeSites.getAll);
    yield put(creators.setAllSites(data.data));
  } catch (error) {
    if (error instanceof Error) {
      yield put(creators.getAllSitesFailed(error.message));
    }
  }
}

function* fetchUserSites(): SagaIterator {
  let { userSites } = (yield select(({ site }: RootState) => site)) as RootState['site'];

  if (!userSites) {
    userSites = yield call(api.timeSites.getUserSites);
  }

  try {
    yield put(creators.setUserSites(userSites, null));
  } catch (error) {
    if (error instanceof Error) {
      yield put(creators.getUserSitesFailed(error));
    }
  }
}

function* putErrorMessage({ error }: GetUserSitesFailedAction | GetAllSitesFailedAction): SagaIterator {
  yield put(storeActions.notification.showErrorMessage(typeof error === 'string' ? error : error.message));
}

export default [
  takeLeading(GET_ALL_SITES, fetchAllSites),
  takeLatest(GET_ALL_SITES_FAILED, putErrorMessage),
  takeLeading(GET_USER_SITES, fetchUserSites),
  takeLatest(GET_USER_SITES_FAILED, putErrorMessage),
];
