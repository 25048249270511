export type UserSite = {
  name: string;
  shortName: string | null;
  latestDocumentApplicableAt: string | null;
};

export interface SiteWithId extends UserSite {
  id: string;
}

export type UserSiteMap = Record<string, UserSite>;

export interface SiteState {
  timeSites: Institution[];
  error: string;
  loading: {
    timeSites: boolean;
    userSites: boolean;
  };
  userSites: UserSiteMap | null;
  selectedUserSite: SiteWithId | null;
  impersonationModalOpen: boolean;
}

export const GET_ALL_SITES = 'GET_ALL_SITES';
export const SET_ALL_SITES = 'SET_ALL_SITES';
export const GET_ALL_SITES_FAILED = 'GET_ALL_SITES_FAILED';
export const GET_USER_SITES = 'GET_USER_SITES';
export const SET_USER_SITES = 'SET_USER_SITES';
export const GET_USER_SITES_FAILED = 'GET_USER_SITES_FAILED';
export const SET_IMPERSONATION_MODAL_OPEN = 'SET_IMPERSONATION_MODAL_OPEN';

export interface Institution {
  id: string;
  name: string;
  shortName: string | null;
}

interface GetAllSitesAction {
  type: typeof GET_ALL_SITES;
}
export interface GetAllSitesFailedAction {
  type: typeof GET_ALL_SITES_FAILED;
  error: string;
}
interface SetAllSitesAction {
  type: typeof SET_ALL_SITES;
  timeSites: Array<Institution>;
}

interface GetUserSitesAction {
  type: typeof GET_USER_SITES;
}

export interface GetUserSitesFailedAction {
  type: typeof GET_USER_SITES_FAILED;
  error: Error;
}

interface SetUserSitesAction {
  type: typeof SET_USER_SITES;
  userSites: UserSiteMap;
  selectedUserSite: SiteWithId;
}

interface SetImpersonationModalOpen {
  type: typeof SET_IMPERSONATION_MODAL_OPEN;
  isImpersonationModalOpen: boolean;
}

export type SiteActionTypes =
  | GetAllSitesAction
  | GetAllSitesFailedAction
  | SetAllSitesAction
  | GetUserSitesAction
  | GetUserSitesFailedAction
  | SetUserSitesAction
  | SetImpersonationModalOpen;
