import { TimeProgramSiteStatus } from '@tempus/t-shared';
import { createAsyncSaga } from 'create-async-saga';
import { call, takeEvery } from 'redux-saga/effects';

import { BIOMARKER } from '~/components/TrialSearchInput/biomarkers.filter';
import { formatBiomarkerOptions } from '~/components/TrialSearchInput/biomarkers.utils';
import { TRIAL_SEARCH_VALUESETS } from '~/routes/trialsPage/trialSearch';
import { formatValuesetOptions } from '~/routes/trialsPage/trialSearch/utils';
import Api from '~/store/api';
import { SelectOption, TrialSearchQuery, TrialSearchResult } from '~/store/trialSearch/state';

export const getTrialMatches = createAsyncSaga('trialSearch/getTrialMatches', function*(payload: {
  searchParams: TrialSearchQuery;
  siteId: string | null;
  statuses: TimeProgramSiteStatus[];
}) {
  return yield call(async ({ searchParams, siteId, statuses }): Promise<TrialSearchResult[]> => {
    const response = await Api.trialMatches.getTrialMatches(searchParams, siteId, statuses);
    return response.data;
  }, payload);
});

export const loadDropdowns = createAsyncSaga('trialSearch/loadDropdowns', function*() {
  return yield call(
    async (): Promise<Record<string, SelectOption[]>> => {
      const { getBiomarkers, getValueSet } = Api.dropdowns;
      const tasks = [
        ...TRIAL_SEARCH_VALUESETS.map((vs) => getValueSet(vs).then(formatValuesetOptions)),
        getBiomarkers('').then(formatBiomarkerOptions),
      ];
      const responses = await Promise.all(tasks);
      return responses.reduce((acc, data, idx) => {
        const key = [...TRIAL_SEARCH_VALUESETS, BIOMARKER][idx];
        acc[key] = data;
        return acc;
      }, {});
    },
  );
});

export default [
  takeEvery(getTrialMatches.actionType, getTrialMatches.asyncSaga),
  takeEvery(loadDropdowns.actionType, loadDropdowns.asyncSaga),
];
