import { ActionPalette, GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  // button styles overrides
  root: {
    top: '0',
    height: '75px',
    boxShadow: `0 10px 9px 0 transparent`,
    border: 'none !important',
    transition: 'box-shadow,background-color 150ms ease-out',
    borderRadius: '100px',
    '&:hover': {
      backgroundColor: GrayscalePalette[300],
    },
  },

  isOpen: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    boxShadow: `0 5px 9px 5px ${GrayscalePalette[300]}`,
    borderColor: ActionPalette[100],
  },

  bg: {
    backgroundColor: ActionPalette[100],
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    paddingRight: '1%',
    borderRadius: '100px',
    alignItems: 'center',
    border: `1px solid ${GrayscalePalette[300]}`,
  },

  textSearchInput: {
    textAlign: 'left',
    paddingRight: '20px',
  },

  searchButton: {
    marginLeft: '24px',
  },

  textSearchInputSubtitle: {
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    marginTop: '4px',
  },

  textSearchInputFilterBox: {
    position: 'absolute',
    top: '125px',
    zIndex: '99',
  },

  vl: { borderLeft: `1px solid ${GrayscalePalette[300]}`, height: '50px' },
});
