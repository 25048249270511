import { Badge, BadgeProps } from '@tcl-boron-prefabs/badge';
import { Tooltip } from '@tcl-boron-prefabs/tooltip';
import {
  OperationaFlagHoverText,
  OperationalFlagDisplayName,
  OperationalFlags,
  PROGRAM_TYPES_DEC,
  TimeProgramSiteStatus,
  getStatusBadgeStyling,
  getTimeProgramSiteStatusBadgeText,
} from '@tempus/t-shared';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { grayPalette } from 'tcl-v3/colors';

import { RootState } from '~/store';
import { SearchArm } from '~/store/api/types';
import { getSummaryDisplays } from '~/store/trial/utils';
import { getBadgeWidth } from '~/utils/misc';

export const Cohort = (props: SearchArm): JSX.Element => {
  const classes = createUseStyles({
    wrapper: {
      '& td': {
        padding: '0 15px 10px',
        verticalAlign: 'top',
      },
    },
    first: {
      display: 'flex',
      flexDirection: 'row',
    },
  })();

  const tooltipComponentClasses = createUseStyles({
    tooltip: {
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const selectedUserSite = useSelector(({ site }: RootState) => site.selectedUserSite?.name);
  const { name, armSummary, programType, displayStatus, operationalFlag } = props;
  const summary = armSummary || {};
  const { agentString, indicationsString, phaseString, biomarkersString } = getSummaryDisplays(summary);
  const operationalFlagText = [OperationalFlags.AMENDMENT, OperationalFlags.SAFETY].includes(
    operationalFlag as OperationalFlags,
  )
    ? OperationaFlagHoverText[operationalFlag as OperationalFlags]
    : null;

  const OperationalFlagBadge = () => (
    <Badge
      style={{
        maxHeight: '50px',
        backgroundColor: grayPalette.gray60,
        borderColor: grayPalette.gray60,
        width: getBadgeWidth(OperationalFlagDisplayName),
      }}
      text={OperationalFlagDisplayName}
      badgeType={'secondary'}
    />
  );

  return (
    <tr className={classes.wrapper}>
      <td className={classes.first}>
        {(selectedUserSite !== 'All Sites' || programType !== PROGRAM_TYPES_DEC.ST) && (
          <span style={{ minWidth: '120px' }}>
            {operationalFlag &&
            !(
              operationalFlag === OperationalFlags.NON_ACTIVE_SITES &&
              displayStatus === TimeProgramSiteStatus.ACTIVE_TRIAL_SITE
            ) ? (
              operationalFlagText ? (
                <Tooltip isOpen={isOpen} position="below-center" classes={tooltipComponentClasses()}>
                  {{
                    tooltip: <div>{operationalFlagText}</div>,
                    base: (
                      <div onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
                        <OperationalFlagBadge />
                      </div>
                    ),
                  }}
                </Tooltip>
              ) : (
                <OperationalFlagBadge />
              )
            ) : (
              <Badge
                style={{ maxHeight: '50px', width: `${getBadgeWidth(displayStatus)}` }}
                text={getTimeProgramSiteStatusBadgeText(displayStatus)}
                badgeVariant={getStatusBadgeStyling(displayStatus).variant as BadgeProps['badgeVariant']}
                badgeType={getStatusBadgeStyling(displayStatus).type}
              />
            )}
          </span>
        )}
        <span style={{ paddingLeft: '10px' }}>{name}</span>
      </td>

      <td>{agentString}</td>
      <td>{indicationsString}</td>
      <td>{phaseString}</td>
      <td>{biomarkersString}</td>
      <td>{/* Placeholder for parent table documents icon */}</td>
    </tr>
  );
};
