import { InternalProgramType, DocumentConstants } from '@tempus/t-shared';
import { DropdownOption } from 'tcl-v3/models';

export interface ProgramTypeDropdownOption extends DropdownOption {
  programType: DocumentConstants.NullableProgramType;
}

export const ProgramTypeOptions: ProgramTypeDropdownOption[] = [
  ...DocumentConstants.ProgramType.map((p) => ({
    programType: p,
    value: p.toString(),
    label: InternalProgramType[p],
  })),
  { label: 'Both', value: '', programType: null },
];

export const getEffectiveProgramTypes = (
  programTypes: DocumentConstants.NullableProgramType[] | undefined,
): DocumentConstants.NullableProgramType[] => {
  return programTypes || [InternalProgramType.TIME];
};

export const onlySupportsConnect = (programType: DocumentConstants.NullableProgramType): boolean => {
  return programType === InternalProgramType.Connect;
};

export const supportsTime = (programType: DocumentConstants.NullableProgramType): boolean => {
  return [null, InternalProgramType.TIME].includes(programType);
};
