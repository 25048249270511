import { GetTrialsBySiteRequest } from '../api/types';
import {
  GET_ALL_TRIALS,
  SET_ALL_TRIALS,
  GET_TRIALS,
  GET_TRIALS_FAILED,
  SET_TRIALS,
  SET_TRIALS_META,
  GET_TRIAL_OPTIONS,
  GET_TRIAL_OPTIONS_FAILED,
  SET_TRIAL_OPTIONS,
  Trial,
  BaseTrial,
  TrialActionTypes,
} from './types';

export const creators = {
  getAllTrials(): TrialActionTypes {
    return { type: GET_ALL_TRIALS };
  },
  setAllTrials(trials: Array<Trial>): TrialActionTypes {
    return { type: SET_ALL_TRIALS, trials };
  },
  getTrials(params?: GetTrialsBySiteRequest): TrialActionTypes {
    return { type: GET_TRIALS, params };
  },
  getTrialsFailed(error: string): TrialActionTypes {
    return { type: GET_TRIALS_FAILED, error };
  },
  setTrials(trials: Array<Trial>, unfilteredCount?: number, filteredCount?: number): TrialActionTypes {
    return { type: SET_TRIALS, trials, unfilteredCount, filteredCount };
  },
  setTrialsMeta(latestApplicableAtLookup: Record<string, string>): TrialActionTypes {
    return { type: SET_TRIALS_META, latestApplicableAtLookup };
  },
  getTrialOptions(): TrialActionTypes {
    return { type: GET_TRIAL_OPTIONS };
  },
  getTrialOptionsFailed(error: string): TrialActionTypes {
    return { type: GET_TRIAL_OPTIONS_FAILED, error };
  },
  setTrialOptions(trialOptions: Array<BaseTrial>): TrialActionTypes {
    return { type: SET_TRIAL_OPTIONS, trialOptions };
  },
};
