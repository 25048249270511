import { Reducer } from 'redux';

import {
  GET_TRIALS_FAILED,
  SET_TRIALS,
  SET_TRIALS_META,
  GET_TRIAL_OPTIONS_FAILED,
  SET_TRIAL_OPTIONS,
  Trial,
  TrialActionTypes,
  TrialState,
  GET_TRIALS,
  GET_ALL_TRIALS,
  SET_ALL_TRIALS,
} from './types';

const defaultState: TrialState = {
  allTrials: [],
  trials: [],
  unfilteredCount: 0,
  filteredCount: 0,
  trialOptions: [],
  error: '',
  loading: true,
};

const reducer: Reducer<TrialState, TrialActionTypes> = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ALL_TRIALS:
      return { ...state, loading: true };
    case SET_ALL_TRIALS: {
      const { trials } = action;
      return {
        ...state,
        allTrials: trials,
        loading: false,
      };
    }
    case GET_TRIALS:
      return { ...state, loading: true };
    case GET_TRIALS_FAILED: {
      const { error } = action;
      return {
        ...state,
        error,
        loading: false,
      };
    }
    case SET_TRIALS: {
      const { trials, unfilteredCount, filteredCount } = action;
      return {
        ...state,
        trials,
        unfilteredCount,
        filteredCount,
        loading: false,
      };
    }
    case SET_TRIALS_META: {
      const { trials } = state;
      const { latestApplicableAtLookup } = action;

      const updatedTrials = trials.reduce((updatedList, trial) => {
        const latestDocumentApplicableAt = latestApplicableAtLookup[trial.id];
        if (latestDocumentApplicableAt) {
          updatedList.push({ ...trial, $meta: { latestDocumentApplicableAt } });
        } else {
          updatedList.push(trial);
        }

        return updatedList;
      }, [] as Trial[]);

      return {
        ...state,
        trials: updatedTrials,
      };
    }
    case GET_TRIAL_OPTIONS_FAILED: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }
    case SET_TRIAL_OPTIONS: {
      const { trialOptions } = action;
      return {
        ...state,
        trialOptions,
      };
    }
    default:
      return state;
  }
};

export default reducer;
