import { Checkbox } from '@tempus/component-library';
import { DocumentConstants, DocumentVersionReviewStatus } from '@tempus/t-shared';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'tcl-v3/foundations';
import { DateInputValue, InputTheme } from 'tcl-v3/models';
import { SingleDatePicker, Toggle } from 'tcl-v3/prefabs';
import typography from 'tcl-v3/styles/typography.module.scss';

import { versionNumberIsUnique, versionNumberIsValid } from '~/components/AddDocuments/utils';
import IssueText from '~/components/IssueText';
import { RootState } from '~/store';
import { onlySupportsConnect, supportsTime } from '~/utils/program-type';

import { useDetailStyles, useReverseToggleStyles, useDatePickerStyles, useCheckboxWrapperStyles } from '../styles';
import { DetailSectionProps } from '../types';

const dbDateToPickerDate = (dbDate: string) => {
  const [year, month, day] = dbDate.split('-');
  return `${month}/${day}/${year}`;
};

const VersionDetails: React.FC<DetailSectionProps> = ({ document, version, persistChanges, setUnsavedChanges }) => {
  const detailClasses = useDetailStyles();
  const datePickerStyles = useDatePickerStyles();
  const reverseToggleStyles = useReverseToggleStyles();
  const checkboxWrapperClasses = useCheckboxWrapperStyles();

  const {
    user: { canWriteDocuments },
  } = useSelector((state: RootState) => state);

  const [dateIsOpen, setDateIsOpen] = useState(false);
  const [active, setActive] = useState(version.active);
  const [internal, setInternal] = useState(version.internal);
  const [versionNumber, setVersionNumber] = useState(version.version);
  const [reviewStatus, setReviewStatus] = useState(version.reviewStatus);
  const [versionNumberChange, setVersionNumberChange] = useState(version.version);
  const [dateValue, setDateValue] = useState({ dateString: dbDateToPickerDate(version.applicableAt) });
  const [versionIsUnique, setVersionIsUnique] = useState(true);
  const [visibleToSponsor, setVisibleToSponsor] = useState(version.visibleToSponsor);

  const resetToStoreValues = () => {
    setVersionIsUnique(true);
    setDateIsOpen(false);
    setActive(version.active);
    setInternal(version.internal);
    setVersionNumber(version.version);
    setReviewStatus(version.reviewStatus);
    setVersionNumberChange(version.version);
    setDateValue({ dateString: dbDateToPickerDate(version.applicableAt) });
    setVisibleToSponsor(version.visibleToSponsor);
  };

  useEffect(() => {
    resetToStoreValues();
  }, [version.id]);

  const isUnique = versionNumberIsUnique(versionNumber, version.id, document);
  const isValid = versionNumberIsValid(versionNumber);
  const doesNotHaveActiveVersions = !document.versions.some((v) => v.active);
  const dateValid = Boolean(dateValue.dateString);
  const allFieldsValid = dateValid && isUnique && isValid;
  const versionHasChanged =
    active !== version.active ||
    internal !== version.internal ||
    versionNumber !== version.version ||
    reviewStatus !== version.reviewStatus ||
    visibleToSponsor !== version.visibleToSponsor ||
    dateValue.dateString !== dbDateToPickerDate(version.applicableAt);

  const persistVersionChanges = async () => {
    if (!allFieldsValid) {
      setUnsavedChanges(true);
      return;
    }

    if (!versionHasChanged) {
      return;
    }

    const result = await persistChanges({
      active,
      internal,
      reviewStatus,
      version: versionNumber,
      applicableAt: dateValue.dateString,
      visibleToSponsor,
    });

    setUnsavedChanges(false);
    if (!result) {
      resetToStoreValues();
    }
  };

  const dateChanged = (v: DateInputValue) => {
    setDateValue(v);
    setDateIsOpen(false);
  };

  useEffect(() => {
    persistVersionChanges();
  }, [active, internal, dateValue, versionNumber, reviewStatus, visibleToSponsor]);

  useEffect(() => {
    setVersionNumberChange(versionNumber);
    setVersionIsUnique(isUnique);
  }, [versionNumber]);

  const persistVersionChangesOnBlur = () => setVersionNumber(versionNumberChange);
  const versionNumberChanged = (e: React.ChangeEvent<HTMLInputElement>) => setVersionNumberChange(e.target.value);

  const toggleReviewStatus = () => {
    setReviewStatus((s) => (s ? null : DocumentVersionReviewStatus.Pending));
  };

  return (
    <div>
      <div className={detailClasses.row}>
        <Input
          label="Version"
          value={versionNumberChange}
          placeholder={version.version}
          disabled={!canWriteDocuments}
          onChange={versionNumberChanged}
          onBlur={persistVersionChangesOnBlur}
          data-pendo-id="document-details-version-textbox"
          theme={isValid && versionIsUnique ? InputTheme.Default : InputTheme.Error}
        />

        <span className="spacer" />

        <SingleDatePicker
          value={dateValue}
          isOpen={dateIsOpen}
          label="Display date"
          onChange={dateChanged}
          classes={datePickerStyles}
          disabled={!canWriteDocuments}
          onToggleIsOpen={() => setDateIsOpen(!dateIsOpen)}
          data-pendo-id="document-details-display-date-textbox"
          placeholder={dbDateToPickerDate(version.applicableAt)}
          theme={dateValid ? InputTheme.Default : InputTheme.Error}
        />
      </div>

      {!versionIsUnique && (
        <IssueText classes={detailClasses} colorClass={typography.error} text={'The version is already being used.'} />
      )}

      {document.classification === DocumentConstants.Classification.Trial && (
        <>
          <div className={`${detailClasses.row} ${checkboxWrapperClasses.root}`}>
            <Checkbox
              value={reviewStatus || ''}
              disabled={!canWriteDocuments}
              checked={Boolean(reviewStatus)}
              label="Requires review from sponsor"
              onChange={() => toggleReviewStatus()}
              data-pendo-id="document-details-review-toggle"
            />
          </div>

          <div className={`${detailClasses.row} ${typography.supportingBody} ${typography.gray} note`}>
            Checking this box will not affect external visibility to sponsors
            {!onlySupportsConnect(document.programType) && ' or sites'}. Use the switches below to manage external
            visibility.
          </div>
        </>
      )}

      <div className={detailClasses.row}>
        <Toggle
          value={'active'}
          isSelected={active}
          onChange={() => setActive(!active)}
          disabled={!canWriteDocuments}
          classes={reverseToggleStyles}
          label="Make this version active"
          data-pendo-id="document-details-active-toggle"
        />
      </div>

      {doesNotHaveActiveVersions && (
        <IssueText
          classes={detailClasses}
          colorClass={typography.warning}
          text={
            'There are no active versions of this document. If you wish to display for external sites, toggle at least one version to active..'
          }
        />
      )}

      <div className={`${detailClasses.row} less-margin`}>
        <Toggle
          value={'visibleToSponsor'}
          isSelected={visibleToSponsor}
          onChange={() => setVisibleToSponsor(!visibleToSponsor)}
          disabled={!canWriteDocuments}
          classes={reverseToggleStyles}
          label="Display for external sponsor"
          data-pendo-id="document-details-visible-to-sponsor-toggle"
        />
      </div>

      {(supportsTime(document.programType) || document.type === DocumentConstants.TRIAL_SUMMARY) && (
        <div className={`${detailClasses.row} less-margin`}>
          <Toggle
            value={'internal'}
            isSelected={!internal}
            onChange={() => setInternal(!internal)}
            disabled={!canWriteDocuments}
            classes={reverseToggleStyles}
            label="Display for external sites"
            data-pendo-id="document-details-internal-toggle"
          />
        </div>
      )}

      {/*
        This is a hacky way to allow the calendar to be shown without getting
        hidden by the parent container's overflow. This is needed because of
        limitations in TCL.
      */}
      {dateIsOpen && <div style={{ height: 200 }} />}
    </div>
  );
};

export default VersionDetails;
