import { Button, InvertingButton, Modal, ModalActions } from '@tempus/component-library';
import { colors } from '@tempus/component-library/styles';
import { H3, Paragraph } from '@tempus/component-library/typography';
import withStyles from '@tempus/component-library/utils/withStyles';
import { DocumentConstants } from '@tempus/t-shared';
import { storeActions } from '@tempus/t-shared/ui';
import { uniqueId, omit } from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { grayPalette } from 'tcl-v3/colors';
import { setModalAppElement } from 'tcl-v3/foundations';
import { Close, Fail } from 'tcl-v3/icons';

import {
  extensionFromFile,
  classificationField,
  versionNumberIsValid,
  versionNumberIsUnique,
} from '~/components/AddDocuments/utils';
import { RootState } from '~/store';
import api from '~/store/api';
import { creators as documentEditCreators } from '~/store/documentEdit/actions';
import { DocumentFile, DocumentWithoutExtras } from '~/store/documentEdit/types';
import { creators as siteCreators } from '~/store/site/actions';
import { creators as trialCreators } from '~/store/trial/actions';
import { getEffectiveProgramTypes } from '~/utils/program-type';

import { FileList } from './FileList';
import Styles from './styles';

setModalAppElement('#root');

interface AddDocumentProps {
  providedId?: string;
  classification: DocumentConstants.Classification;
  showFileUploader: boolean;
  hideFileUploader: () => void;
}

const maxModalWidth = 3072;
const maxModalHeight = 1920;

const FullScreenModal = withStyles({
  content: {
    top: 0,
    padding: 0,
    height: '100%',
    width: '100%',
    transition: 'transform .3s ease 0s',
    fontSize: '13px',
    [`@media (max-width: ${maxModalWidth}px)`]: {
      maxWidth: '100vw',
    },

    [`@media (max-height: ${maxModalHeight}px)`]: {
      maxHeight: '100vh',
    },
  },
})(Modal);

const CancelModal = withStyles({
  content: {
    width: '450px',
  },
})(Modal);

const StyledCancelH3 = withStyles({
  root: {
    marginTop: '0',
    marginBottom: '30px',
  },
})(H3);

const ErrorMessage = withStyles({
  root: {
    color: colors.red,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '30px',
    '& svg': {
      marginRight: '5px',
    },
  },
})(Paragraph);

const StyledH3 = withStyles({
  root: {
    padding: '30px 30px 20px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0 2px 4px 0 #e9e9ea',
    marginBlockStart: 0,
    marginBlockEnd: '2px',
  },
})(H3);

const StyledModalActions = withStyles({
  root: {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    marginRight: '30px',
  },
})(ModalActions);

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const AddDocuments: React.FC<AddDocumentProps> = ({
  showFileUploader,
  hideFileUploader,
  providedId,
  classification,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const uploads = useSelector(({ documentEdit }: RootState) => documentEdit.uploads);
  const failedUploads = useSelector(({ documentEdit }: RootState) => documentEdit.failedUploads);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [cancelEventTimestamp, setCancelEventTimestamp] = useState(0);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [hasMissingFields, setHasMissingFields] = useState<boolean>(false);
  const [hasInvalidFields, setHasInvalidFields] = useState<boolean>(false);
  const [hasDuplicateFields, setHasDuplicateFields] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<number>(-1);
  const [classificationId, setClassificationId] = useState<string | null>(null);
  const [documentUploading, setDocumentUploading] = useState<string | null>(null);

  useEffect(() => {
    setClassificationId(providedId ? providedId : null);
  }, [providedId]);

  const field = classificationField(classification);
  const hasErrors = hasMissingFields || hasInvalidFields || hasDuplicateFields;
  const optionalClassification =
    classification === DocumentConstants.Classification.Trial
      ? DocumentConstants.Classification.Site
      : DocumentConstants.Classification.Trial;
  const optionalField = classificationField(optionalClassification);

  const resetRef = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.value = '';
    }
  };

  const onChange = async (fileList: FileList | null) => {
    if (!fileList || !fileList.length) {
      return;
    }
    const filesToUpload = Array.from(fileList) as File[];
    const trialId = classification === DocumentConstants.Classification.Trial && providedId ? providedId : null;
    const institutionId = classification === DocumentConstants.Classification.Site && providedId ? providedId : null;
    dispatch(
      documentEditCreators.setDocumentUploads([
        ...uploads,
        ...filesToUpload.map((file) => {
          return {
            file,
            document: {
              id: uniqueId(), // this will be overriden on the server
              type: '',
              trialId,
              category: '',
              classification,
              sponsorId: null,
              institutionId,
              versions: [],
              tags: [],
              name: '',
              programType: null,
            },
            version: {
              id: uniqueId(), // this will be overriden on the server
              active: true,
              version: '1.0',
              internal: false,
              extension: extensionFromFile(file),
              applicableAt: '',
              documentId: '',
              visibleToSponsor: true, // set to true by user's preference
              reviewStatus: null,
            },
            linkedDocument: null,
            tagChangeCount: 0,
            changeCount: 0,
          };
        }),
      ]),
    );
    if (!uploadModalOpen) {
      setUploadModalOpen(true);
    }
    resetRef();
  };

  useEffect(() => {
    dispatch(siteCreators.getAllSites());
    dispatch(trialCreators.getAllTrials());
  }, []);

  const renderFilePicker = () => {
    return (
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(event): void => {
          const target = event.target as HTMLInputElement;
          onChange(target.files);
        }}
        multiple
      />
    );
  };

  const selectDocuments = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (showFileUploader) {
      selectDocuments();
      hideFileUploader();
    }
  }, [showFileUploader]);

  const navigateToDocuments = () => history.push(`/${classification}s/${classificationId}/documents`);

  const resetState = () => {
    setShowErrors(false);
    setHasInvalidFields(false);
    setHasMissingFields(false);
    setHasDuplicateFields(false);
    dispatch(documentEditCreators.setDocumentUploads([]));
    setDocumentUploading(null);
    resetRef();
    setUploadModalOpen(false);
    setUploadInProgress(-1);
  };

  const completeUpload = (failures: DocumentFile[]) => {
    setUploadInProgress(uploadInProgress + 1);
    if (failures.length) {
      dispatch(
        storeActions.notification.showErrorMessage(
          <React.Fragment>
            <Paragraph>
              <strong>Error</strong>
            </Paragraph>
            <Paragraph>The following documents failed to upload</Paragraph>
            <ul>
              {failures.map(({ document, version }) => (
                <li key={version.id}>{document.name}</li>
              ))}
            </ul>
          </React.Fragment>,
        ),
      );
      dispatch(documentEditCreators.setFailedUploads(null));
    }
    resetState();
    navigateToDocuments();
  };

  const getNewDocumentData = (document: DocumentWithoutExtras) => {
    document[field] = classificationId;
    return {
      ...omit(document, ['id', 'versions']),
      tags: document.tags.map(({ key, value }) => [key, value]),
    };
  };

  const uploadDocument = async () => {
    const docFile = uploads[uploadInProgress];
    const { file, document, version } = docFile;
    setDocumentUploading(document.id);
    let failures = [...failedUploads];
    try {
      let base64File = (await toBase64(file)) as string;
      base64File = base64File.split(',')[1];
      const { data: updatedDocument } = await api.documents.create({
        data: base64File,
        document: version.documentId.length ? version.documentId : getNewDocumentData(document),
        version: omit(version, ['id', 'documentId']),
      });
      dispatch(documentEditCreators.updateDocument(updatedDocument));
    } catch (err) {
      failures = [...failures, docFile];
      dispatch(documentEditCreators.setFailedUploads(docFile));
    } finally {
      setDocumentUploading(null);
      if (uploadInProgress === uploads.length - 1) {
        // last document
        completeUpload(failures);
      } else {
        setUploadInProgress(uploadInProgress + 1);
      }
    }
  };

  useEffect(() => {
    if (uploadInProgress >= 0 && uploadInProgress < uploads.length) {
      uploadDocument();
    }
  }, [uploadInProgress]);

  const documentHasRequiredFields = (docFile: DocumentFile) => {
    const { document, version } = docFile;
    const { requiredKeys, optionalClassificationRequired, programTypes } = DocumentConstants.getDocumentRollup(
      classification,
      document.type,
      document.category,
    );
    const effectiveProgramTypes = getEffectiveProgramTypes(programTypes);

    const requiredKeyPresent =
      !requiredKeys || requiredKeys.every((requiredKey) => document.tags.find((tag) => requiredKey === tag.key));
    const optionalClassificationPresent = !optionalClassificationRequired || Boolean(document[optionalField]);

    return Boolean(
      classificationId &&
        document.type.length &&
        document.category.length &&
        document.name.length &&
        version.extension.length &&
        version.applicableAt.length &&
        version.version.length &&
        requiredKeyPresent &&
        optionalClassificationPresent &&
        effectiveProgramTypes.includes(document.programType),
    );
  };

  const documentHasValidFields = (docFile: DocumentFile) => {
    const { version } = docFile;
    return versionNumberIsValid(version.version);
  };

  const documentHasUniqueFields = (docFile: DocumentFile) => {
    const { version, linkedDocument } = docFile;
    return versionNumberIsUnique(version.version, version.id, linkedDocument);
  };

  const validateDocuments = () => {
    const missingFields = !uploads.every(documentHasRequiredFields);
    const invalidFields = !uploads.every(documentHasValidFields);
    const duplicateFields = !uploads.every(documentHasUniqueFields);
    setHasMissingFields(missingFields);
    setHasInvalidFields(invalidFields);
    setHasDuplicateFields(duplicateFields);
  };

  const uploadDocuments = async () => {
    setShowErrors(true);
    if (!hasErrors) {
      setUploadInProgress(0);
    }
  };

  const onCancel = () => {
    setCancelModalOpen(false);
    resetState();
  };

  const closeUploadModal = (e: React.MouseEvent | React.KeyboardEvent) => {
    // If the timestamp matches the close cancel modal event, don't
    //   handle it again.
    if (e.timeStamp === cancelEventTimestamp) {
      return;
    }

    if (uploads.length) {
      setCancelModalOpen(true);
    } else {
      resetState();
    }
  };

  const closeCancelModal = (e: React.MouseEvent | React.KeyboardEvent) => {
    // Hold on to this timestamp so we can prevent the main
    //   modal handler from handling this event.
    setCancelEventTimestamp(e.timeStamp);

    setCancelModalOpen(false);
  };

  useEffect(() => {
    validateDocuments();
  }, [uploads.map((u) => u.changeCount).join(',')]);

  return (
    <React.Fragment>
      {renderFilePicker()}
      <CancelModal open={cancelModalOpen} onRequestClose={closeCancelModal}>
        <StyledCancelH3>Are you sure?</StyledCancelH3>
        You have unsaved changes that will be lost if you decide to continue.
        <StyledModalActions>
          <Button type="cancel" onClick={closeCancelModal} data-pendo-id="document-upload-cancel-cancel">
            Cancel
          </Button>
          <Button onClick={onCancel} data-pendo-id="document-upload-confirm-cancel">
            Yes, leave this page
          </Button>
        </StyledModalActions>
      </CancelModal>
      <FullScreenModal open={uploadModalOpen} onRequestClose={closeUploadModal}>
        <Styles>
          <div className="header">
            <StyledH3>
              Upload files
              <button onClick={closeUploadModal} data-pendo-id="document-upload-cancel-icon">
                <Close color={grayPalette.gray50} height={10} />
              </button>
            </StyledH3>
          </div>
          <FileList
            providedId={providedId}
            classification={classification}
            showErrors={showErrors}
            documentUploading={documentUploading}
            updateClassificationId={setClassificationId}
          />
          <div className="footer">
            <StyledModalActions>
              <Button
                type="cancel"
                onClick={closeUploadModal}
                disabled={uploadInProgress >= 0}
                data-pendo-id="document-upload-cancel">
                Cancel
              </Button>
              <InvertingButton
                onClick={selectDocuments}
                disabled={uploadInProgress >= 0}
                data-pendo-id="document-upload-more">
                Select more documents
              </InvertingButton>
              <Button
                onClick={uploadDocuments}
                data-pendo-id="document-upload-upload"
                disabled={uploadInProgress >= 0 || !uploads.length}>
                Upload
              </Button>
            </StyledModalActions>
            {showErrors && hasMissingFields && (
              <ErrorMessage>
                Please enter missing information.
                <Fail height={10} />
              </ErrorMessage>
            )}
            {showErrors && hasInvalidFields && (
              <ErrorMessage>
                One or more of your version numbers is invalid. Please update entered information.
                <Fail height={10} />
              </ErrorMessage>
            )}
            {showErrors && hasDuplicateFields && (
              <ErrorMessage>
                A version of one or more of your documents already exist for selected sites. Please update entered
                information.
                <Fail height={10} />
              </ErrorMessage>
            )}
          </div>
        </Styles>
      </FullScreenModal>
    </React.Fragment>
  );
};
