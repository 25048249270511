import { DocumentConstants } from '@tempus/t-shared';
import { DropdownOption } from 'tcl-v3/models';

const getCategoryOptionsByClassification = (): Record<DocumentConstants.Classification, DropdownOption[]> => {
  const options: Record<DocumentConstants.Classification, DropdownOption[]> = {
    [DocumentConstants.Classification.Site]: [],
    [DocumentConstants.Classification.Trial]: [],
  };

  const fill = (classification: DocumentConstants.Classification, rollup: DocumentConstants.DocumentRollup): void => {
    rollup.forEach((categoryRollup, category) => {
      const categoryOptions: DropdownOption[] = [];

      categoryRollup.forEach((fileTypes, rollup) => {
        const isOther = rollup === DocumentConstants.DocumentRollupConstants.OTHER;

        const display = isOther ? category : rollup;
        const indent = isOther ? '' : '      ';

        const value = `${category}${DocumentConstants.DocumentRollupConstants.OTHER_CATEGORY_DELIMITER}${rollup}`;
        const label = `${indent}${display}`;
        const option = { value, label };

        // Make sure the top-level category option is always
        //   first.
        categoryOptions[isOther ? 'unshift' : 'push'](option);
      });

      options[classification].push(...categoryOptions);
    });
  };

  fill(DocumentConstants.Classification.Trial, DocumentConstants.trialDocumentRollup);
  fill(DocumentConstants.Classification.Site, DocumentConstants.siteDocumentRollup);

  return options;
};

export const categoryOptionsByClassification = getCategoryOptionsByClassification();
