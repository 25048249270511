import { createUseStyles } from 'react-jss';
import { grayPalette } from 'tcl-v3/colors';

const defaultPadding = 15;
const deleteMargin = 35;
const borderBottom = `1px solid ${grayPalette.gray45}`;

export default createUseStyles({
  title: {
    display: 'flex',
    marginBottom: 15,
    alignItems: 'center',

    '& > div': {
      marginLeft: 5,
    },
  },
  tabs: {
    borderBottom,
    margin: ({ padding }: { padding: number | undefined }): string => `0 -${padding || defaultPadding}px`,
  },
  tab: {
    textTransform: 'uppercase',
    fontSize: '12px !important',
    padding: '15px 0 !important',
  },
  tabContents: {
    overflow: 'scroll',
    padding: `30px ${defaultPadding}px 40px`,
    margin: `0 -${defaultPadding}px ${deleteMargin}px`,
    borderBottom: ({ canWriteDocuments }: { canWriteDocuments: boolean }): string =>
      canWriteDocuments ? borderBottom : 'none',
  },
  deleteButton: {
    display: 'flex',
    alignContent: 'center',
    marginBottom: deleteMargin,

    '& svg': {
      marginRight: 7,
    },
  },
});
