import { isEqual } from 'lodash';
import moment from 'moment';

export enum PickerStatus {
  DEFAULT = 'default',
  WARNING = 'warning',
  ERROR = 'error',
}

type DateValidateResult = {
  passed: boolean;
  status?: PickerStatus;
  message?: string;
};
type DateValidateRule = (date: string | undefined | null) => DateValidateResult;

export function getChangedValues(oldState, newState) {
  const changedValues = {};

  for (const key in oldState) {
    if (!isEqual(oldState[key], newState[key])) {
      changedValues[key] = newState[key];
    }
  }

  return changedValues;
}

export const dateRuleRequired = (date) => {
  return {
    passed: !!date,
    status: PickerStatus.ERROR,
    message: 'Date is required',
  };
};
export const dateRuleNotInFuture = (date) => {
  const passed = moment.utc(date).isSameOrBefore(moment(), 'day');
  return {
    passed,
    status: PickerStatus.ERROR,
    message: 'Date cannot be in the future',
  };
};

export const validateDateByRules = (date: string | undefined | null, rules: DateValidateRule[]) => {
  for (const rule of rules) {
    const result = rule(date);
    if (!result.passed) {
      return result;
    }
  }

  return {
    passed: true,
    status: PickerStatus.DEFAULT,
  };
};
