import { buildStore, RootStateFromStore } from '@tempus/t-shared/ui';

import { config } from '~/config';

import enhanceUser from './enhanceUser';
import reducers from './reducers';
import sagas from './sagas';

const store = buildStore({
  sagas,
  reducers,
  enhanceUser,
  useLogger: config.environment !== 'prd',
});

export type RootState = RootStateFromStore<typeof store>;
export default store;
