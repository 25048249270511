import { AccentPalette, GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  viewLink: {
    cursor: 'pointer',
    color: AccentPalette[100],
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '4px',
    },
  },
  activityStatusContainer: {
    borderLeft: `1px solid ${GrayscalePalette[300]}`,
    padding: '12px 12px',
    marginBottom: '16px',
  },
  ul: {
    paddingLeft: '16px',
  },
  li: {
    listStyleType: 'disc',
    marginBottom: '8px',
  },
  listItemContainer: {
    display: 'flex',
  },
  updatesLeft: {
    flex: 1,
  },
  viewMore: {
    paddingBottom: '16px',
  },
});
