import { FILTER_COLUMN_ID, MaxPageWidth } from '@tempus/t-shared/ui';
import { kebabCase } from 'lodash';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { Column } from 'react-table';

import TherapiesTable from '~/components/Table';
import { getCellId, getSitesArray, SITE_NAME_COLUMN } from '~/routes/sites';
import Styles from '~/routes/sites/styles';
import { RootState } from '~/store';
import { creators as siteCreators } from '~/store/site';
import { SiteWithId } from '~/store/site/types';

const columns: Column<SiteWithId>[] = [
  SITE_NAME_COLUMN,
  {
    id: FILTER_COLUMN_ID,
  },
];

const mapState = (state: RootState) => {
  return {
    sites: state.site.userSites,
  };
};

const connector = connect(mapState, {
  getSites: siteCreators.getUserSites,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

interface SitesProps extends RouteComponentProps, PropsFromRedux {}

const PatientTrackerSitesV2: React.FC<SitesProps> = ({ getSites, sites }) => {
  const history = useHistory();

  const sitesArray = React.useMemo(() => getSitesArray(sites), [sites]);

  useEffect(() => {
    getSites();
  }, []);

  return (
    <MaxPageWidth>
      <Styles>
        <TherapiesTable
          tableName="patient-tracking-list"
          columns={columns}
          data={sitesArray}
          onCellClick={(e, cell) => {
            const siteId = cell.row.original.id;
            history.push(`/patient-tracker/sites/${siteId}`);
          }}
          extendRowProps={(props) => {
            return {
              ...props,
              'data-pendo-id': 'patient-tracker-list-site',
            };
          }}
          extendCellProps={(props, { cell }) => {
            return {
              ...props,
              'data-pendo-id': `patient-tracker-list-site-${kebabCase(getCellId(cell))}-cell`,
            };
          }}
          initialState={{
            sortBy: [
              {
                id: 'name',
                desc: false,
              },
            ],
          }}
        />
      </Styles>
    </MaxPageWidth>
  );
};

export default withRouter(connector(PatientTrackerSitesV2));
