import { Collapse } from '@tcl-boron-prefabs/collapse';
import React from 'react';

import { PatientTrackingDetails, TimePatient } from '~/store/patientTrackerV2/types';

import InactiveMatchBox from './InactiveMatchBox';
import useStyles, { useCollapseStyles } from './InactiveMatchesTab.styles';

const sortMatchesByStatusDate = (a: PatientTrackingDetails, b: PatientTrackingDetails) => {
  if (!a.statusDate && !b.statusDate) {
    return 0;
  }

  if (!a.statusDate) {
    return -1;
  }

  if (!b.statusDate) {
    return 1;
  }

  const dateA = new Date(a.statusDate);
  const dateB = new Date(b.statusDate);
  return dateA.getTime() - dateB.getTime();
};

interface InactiveMatchesGroupProps {
  matches: TimePatient['patientTrackingDetails'];
  editMatch: (matchId: string) => void;
}

export default ({ matches, editMatch }: InactiveMatchesGroupProps) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const classes = useStyles();
  const collapseStyles = useCollapseStyles();

  if (!matches.length) {
    return null;
  }

  return (
    <Collapse
      title={`${matches[0].status} (${matches.length})`}
      titlePlacement="right"
      isOpen={isOpen}
      onChange={() => setIsOpen(!isOpen)}
      classes={collapseStyles}>
      <>
        {matches.sort(sortMatchesByStatusDate).map((match) => (
          <InactiveMatchBox key={match.trial.id} match={match} editMatch={editMatch} />
        ))}
        <div className={classes.border} />
      </>
    </Collapse>
  );
};
