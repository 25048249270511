import * as SentryBrowser from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import withStyles from '@tempus/component-library/utils/withStyles';
import { T_TRIALS_EMAIL } from '@tempus/t-shared';
import * as PropTypes from 'prop-types';
import React from 'react';

import { config } from '~/config';

const sentryDsn = config.sentryDsn;
const { enableSentry } = config.toggles;

const ErrorMsg = (classes) => (
  <div className={classes.root}>
    <h1>
      An error has occured.
      <br />
      Please refresh your browser and try again.
      <br />
    </h1>
    Contact {T_TRIALS_EMAIL} if you continue to experience any problems.
  </div>
);

const ErrorBoundary = ({ children, classes }) => (
  <SentryReact.ErrorBoundary fallback={() => ErrorMsg(classes)}>{children}</SentryReact.ErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

if (enableSentry === '1' && sentryDsn) {
  SentryReact.init({
    dsn: sentryDsn,
    integrations: [SentryBrowser.browserTracingIntegration()],
    environment: config.environment,
    release: 'therapies',
    tracesSampleRate: 1.0,
  });
}

export default withStyles({
  root: {
    fontSize: 20,
    textAlign: 'center',
    margin: 100,
    lineHeight: 1.5,
  },
})(ErrorBoundary);
