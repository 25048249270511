import { extractRelevantGenes, InternalProgramType } from '@tempus/t-shared';
import { uniq } from 'lodash';

import Api from '~/store/api';

export const getRelevantBiomarkers = async ({ patientBiomarkers, trialId, matchingArms }) => {
  if (!patientBiomarkers.length) {
    return '';
  }

  const [annotation] = await Api.annotations.get({ trialId, programType: InternalProgramType.TIME });
  const trialBiomarkers = extractRelevantGenes(matchingArms, [annotation], 'gene');
  const relevantBiomarkers = patientBiomarkers
    .filter((o) => trialBiomarkers.includes(o.mutation.gene)) // Find HGNC code intersection
    .map((o) => o.label.split(' ')[0]); // Convert gene codes back to gene displays

  return uniq(relevantBiomarkers).join(', ');
};
