import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';

export const useFeatureEnabled = (flag: string) => {
  const userFeatures = useSelector<RootState, string[]>((state) => state.user.features);

  return userFeatures.includes(flag);
};

export interface FeatureGateProps {
  children: React.ReactNode;
  flag: string;
  fallback?: React.ReactNode;
}

const FeatureGate = ({ children, flag, fallback = null }: FeatureGateProps) => {
  const userHasFeature = useFeatureEnabled(flag);

  if (!userHasFeature) {
    return fallback;
  }

  return children;
};

export default FeatureGate;
