import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import React from 'react';

import useStyles from '../styles';
import { AddPatientPage } from '../types';

interface DuplicateMatchProps {
  viewDuplicateMatch: () => void;
  navigateToPage: (page: AddPatientPage) => void;
}

const DuplicateMatch: React.FC<DuplicateMatchProps> = ({ navigateToPage, viewDuplicateMatch }) => {
  const classes = useStyles();

  const onBack = () => navigateToPage(AddPatientPage.IDENTIFICATION_PAGE);

  return (
    <React.Fragment>
      <div className={`${classes.contentContainer} ${classes.screenedMatchContainer}`}>
        <span className={typography.sectionHeader}>Duplicate match</span>
        <div className={classes.screenedMatchContent}>
          This patient has already been matched to this trial in the patient tracker. Would you like to see the patient
          tracker?
        </div>
        <div className={classes.screenedMatchButtonWrapper}>
          <div className={classes.modalActions}>
            <Button
              small
              id="duplicate-match-back-button"
              className="secondary-button"
              buttonType="secondary"
              ariaLabel="Back"
              onClick={onBack}
              data-pendo-id="patient-tracker-duplicate-match-back">
              Back
            </Button>
            <Button
              small
              buttonType="primary"
              ariaLabel="View patient"
              onClick={viewDuplicateMatch}
              data-pendo-id="patient-tracker-duplicate-match-view-patient">
              View patient tracker
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DuplicateMatch;
