import { useMutation } from '@tanstack/react-query';
import { Modal } from '@tcl-boron-prefabs/modal';
import { storeActions } from '@tempus/t-shared/ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '~/store';
import api from '~/store/api';

import useStyles from './styles';

interface EmailModalProps {
  isOpen: boolean;
  closeModal: () => void;
  trialId: string;
}

interface SendEmailArgs {
  siteId: string;
  trialId: string;
}
const sendEmailMutation = ({ siteId, trialId }: SendEmailArgs) => {
  return api.email.sendExternalInterestEmail(siteId, trialId);
};
const EmailModal: React.FC<EmailModalProps> = ({ isOpen, closeModal, trialId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    site: { selectedUserSite },
  } = useSelector((state: RootState) => state);
  const selectedSiteId = selectedUserSite?.id;

  const { mutate: sendEmail } = useMutation({
    mutationFn: sendEmailMutation,
    onError: () => {
      dispatch(
        storeActions.notification.showErrorMessage(
          'Notification not sent - Please try again and if the problem persists get in touch with your Tempus representative.',
        ),
      );
    },
    onSuccess: () => {
      dispatch(storeActions.notification.showSuccessMessage('Notification sent to Tempus'));
      closeModal();
    },
  });
  if (selectedSiteId) {
    return (
      <Modal
        isOpen={isOpen}
        size="small"
        shouldCloseOnOverlayClick={true}
        overlayClassName={classes.modalOverlay}
        title="Get more information about this trial"
        callToActionButton={{
          id: 'contact',
          buttonText: 'Confirm',
          onClick: () => {
            sendEmail({ siteId: selectedSiteId, trialId });
          },
        }}
        cancelButton={{ id: 'cancel', buttonText: 'Cancel', onClick: closeModal }}>
        Tempus will reach out to your Site&apos;s contact with more information about this trial.
      </Modal>
    );
  } else {
    return <div></div>;
  }
};
export default EmailModal;
