// a slice is both actions and a reducer combined

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, isEqual } from 'lodash';

import { initialState, PatientInput, SearchPrompt } from './state';
import { getTrialMatches, loadDropdowns } from './thunks';

export const trialSearchSlice = createSlice({
  name: 'trialSearch',
  initialState,
  reducers: {
    updatePatient: (state, action: PayloadAction<Partial<PatientInput>>) => {
      state.patientInput = { ...state.patientInput, ...action.payload };
    },
    clearPatient: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((category) => {
        state.patientInput[category] = cloneDeep(initialState.patientInput[category]);
      });
      if (state.trials.length === 0 && isEqual(state.patientInput, initialState.patientInput)) {
        state.searchPrompt = SearchPrompt.NO_INPUT;
      }
      state.showUnavailableTrialsButton = false;
    },
    clearTrials: (state) => {
      state.trials = cloneDeep(initialState.trials);
      state.searchPrompt = SearchPrompt.NO_INPUT;
      state.showUnavailableTrialsButton = false;
    },
    setShowUnavailableTrialsButton: (state, action: PayloadAction<boolean>) => {
      state.showUnavailableTrialsButton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getTrialMatches.pending, (state) => {
        state.loading = true;
      })

      .addCase(getTrialMatches.fulfilled, (state, action) => {
        state.trials = action.payload;
        state.loading = false;
        if (action.payload.length === 0 && isEqual(state.patientInput, initialState.patientInput)) {
          state.searchPrompt = SearchPrompt.NO_INPUT;
          state.showUnavailableTrialsButton = false;
        } else if (action.payload.length === 0 && !isEqual(state.patientInput, initialState.patientInput)) {
          state.searchPrompt = SearchPrompt.NO_RESULTS;
        }
      })

      .addCase(getTrialMatches.rejected, (state) => {
        state.loading = false;
      })

      .addCase(loadDropdowns.pending, (state) => {
        state.loading = true;
      })

      .addCase(loadDropdowns.fulfilled, (state, action) => {
        state.dropdowns = action.payload;
        state.loading = false;
      })

      .addCase(loadDropdowns.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { clearPatient, updatePatient, clearTrials, setShowUnavailableTrialsButton } = trialSearchSlice.actions;

// Action creators are generated for each case reducer function
export default trialSearchSlice.reducer;
