import { Summary } from '@tempus/t-shared';

import { TrialResponse, TrialsSearchRequestFilters } from '../api/types';

export const getSummaryDisplays = (summary: Summary) => {
  const { indications, biomarkers, agents, phases } = summary;
  const agentString = agents?.map((a) => `${a.class} ${a.name}`).join(', ') || '';
  const indicationsString = indications?.map((i) => `${i.type} ${i.subtype ? i.subtype : ''}`.trim()).join(', ') || '';
  const phaseString = phases?.join(', ') || '';
  const biomarkersString = biomarkers?.map((b) => `${b.gene} ${b.result ? b.result : ''}`.trim()).join(', ') || '';
  return { agentString, indicationsString, phaseString, biomarkersString };
};

const joinStrings = (strings: string[]) => strings.filter(Boolean).join(', ');

export function formatTrials(trials: TrialResponse[]) {
  return trials.map((trial) => {
    const { cohorts } = trial;

    const armSummaries = cohorts.map((c) => c.armSummary || null).filter(Boolean);

    const trialSummary = armSummaries.reduce(
      (acc, curr) => {
        const { agentString, indicationsString, phaseString, biomarkersString } = getSummaryDisplays(curr);
        acc.agents = joinStrings([acc.agents, agentString]);
        acc.indications = joinStrings([acc.indications, indicationsString]);
        acc.phases = joinStrings([acc.phases, phaseString]);
        acc.biomarkers = joinStrings([acc.biomarkers, biomarkersString]);
        return acc;
      },
      { agents: '', indications: '', phases: '', biomarkers: '' },
    );

    const { agents, indications, phases, biomarkers } = trialSummary;

    return {
      ...trial,
      agents,
      indications,
      phases,
      biomarkers,
      cohorts,
    };
  });
}

export const hasNonStatusFilters = (filters?: TrialsSearchRequestFilters) => {
  if (!filters) {
    return false;
  }

  return (
    filters.agent ||
    filters.biomarker ||
    filters.indication ||
    filters.lineOfTherapy ||
    filters.phase ||
    filters.trialName
  );
};
