import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { ALL_SITES } from '@tempus/t-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import DownloadPatients from '~/components/DownloadPatients/v2';
import { RootState } from '~/store';

import AddPatient from '../AddPatientV2';
import { useStyles } from './styles';

interface PatientTrackerHeaderProps {
  title: string;
  stateId: string;
  siteId: string;
}

const PatientTrackerHeader: React.FC<PatientTrackerHeaderProps> = ({ title, stateId, siteId }) => {
  const classes = useStyles();
  const { canWritePatientTracking } = useSelector((state: RootState) => state.user);
  const selectedUserSite = useSelector((state: RootState) => state.site.selectedUserSite);
  const isAllSitesSelected = selectedUserSite?.name == ALL_SITES;
  return (
    <div className={classes.header}>
      <span className={`${typography.header} ${classes.title}`} data-testid="patient-tracker-heading">
        {title}
      </span>
      <div className={classes.actionButtons}>
        {canWritePatientTracking && !isAllSitesSelected && <AddPatient />}
        <DownloadPatients stateId={stateId} siteId={siteId} />
      </div>
    </div>
  );
};

export default PatientTrackerHeader;
