/*
This is pretty much a direct copy of https://go.opstempus.com/5vTXK9, but the
select component is replaced with a Creatable component instead. Some other
small modifications and duplications have been added because they could not
be accessed directly from TCL.
*/

import { ActionPalette } from '@tcl-boron-colors/colors';
import cn from 'classnames';
import uniqueId from 'lodash-es/uniqueId';
import React, { DetailedHTMLProps, HTMLAttributes, ReactElement, useState } from 'react';
import Select, { OptionTypeBase } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import Creatable, { Props } from 'react-select/creatable';
import { useCommonInputStyles } from 'tcl-v3/foundations';
import { BaseInputProps, DropdownOption, InputTheme } from 'tcl-v3/models';
import { mergeClasses } from 'tcl-v3/utils';

import { CreatableSingleSelectComponentOverrides, getComponentOverrides } from './overrides';
import { ReactSingleSelectStyleOverrides } from './styles';

type CreatableProps<T extends OptionTypeBase> = Props<T, false>;
type DivElement = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface CreatableSingleSelectComboboxProps<T extends DropdownOption = DropdownOption>
  extends BaseInputProps,
    Omit<DivElement, 'onChange'> {
  hideLabel?: boolean;
  clearable?: boolean;
  async?: boolean;
  loadOptions?: CreatableProps<T>['loadOptions'];
  createDisabled?: boolean;
  value?: T | null;
  options?: T[];
  reactSelectProps?: CreatableProps<T>['reactSelectProps'];
  onChange: (option: DropdownOption | null) => void;
  formatOptionLabel?: CreatableProps<T>['formatOptionLabel'];
  useBoronStyles?: boolean;
  componentOverrides?: CreatableSingleSelectComponentOverrides;
  isDisabled?: boolean;
}

const componentToUse = (async: boolean, creatable: boolean) =>
  async ? (creatable ? AsyncCreatable : AsyncSelect) : creatable ? Creatable : Select;

const nonMemoCreatableSingleSelectCombobox = <T extends DropdownOption>({
  className = '',
  classes: classOverrides = {},
  clearable = true,
  isDisabled = false,
  hideLabel = false,
  id,
  label,
  onChange,
  options,
  placeholder = 'Select',
  reactSelectProps = {},
  subContent,
  theme = InputTheme.Default,
  value,
  async = false,
  loadOptions,
  createDisabled,
  formatOptionLabel,
  useBoronStyles = false,
  componentOverrides = {},
  ...props
}: CreatableSingleSelectComboboxProps<T>): ReactElement | null => {
  [id] = useState(id || uniqueId('creatable_single_select_combobox_'));
  const classes = mergeClasses(useCommonInputStyles({ theme, subContent }), classOverrides, { root: className });
  const Component: React.ElementType = componentToUse(async, !createDisabled);

  return (
    <div data-analytics="single-select-combobox" {...props}>
      <label htmlFor={id} className={cn(classes.label, { [classes.isVisuallyHidden]: hideLabel })}>
        {label}
      </label>
      <Component
        {...reactSelectProps}
        useBoronStyles={useBoronStyles}
        components={getComponentOverrides(componentOverrides)}
        id={id}
        inputTheme={theme}
        isClearable={clearable}
        isDisabled={isDisabled}
        isInvalid={theme === InputTheme.Error}
        onChange={onChange}
        loadOptions={loadOptions}
        options={options}
        placeholder={placeholder}
        styles={ReactSingleSelectStyleOverrides}
        value={value}
        formatOptionLabel={formatOptionLabel}
        formatCreateLabel={useBoronStyles ? formatCreateLabelBoron : reactSelectProps.formatCreateLabel}
      />
      {subContent && <div className={classes.subContent}>{subContent}</div>}
    </div>
  );
};

const formatCreateLabelBoron = (inputValue) => (
  <div style={{ color: ActionPalette[300] }}>Create &quot;{inputValue}&quot;</div>
);

export const CreatableSingleSelectCombobox = React.memo(
  nonMemoCreatableSingleSelectCombobox,
) as typeof nonMemoCreatableSingleSelectCombobox;
