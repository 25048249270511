import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Column, Row } from 'react-table';

import { Trial } from '~/store/trial/types';

import { Cohort } from './cells/Cohort';

type CohortSubtableProps = {
  row: Row<Trial>;
  columns: Column<Trial>[];
};
export default ({ row, columns }: CohortSubtableProps) => {
  const [expanded, setExpanded] = useState(false);

  const classes = createUseStyles({
    wrapper: {
      padding: '10px 0',
    },
    subtable: {
      width: '100%',
    },
    expander: {
      padding: '15px',
    },
  })();

  const toggleShowMore = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const cohorts = [...row.original.cohorts];
  const displayCohorts = cohorts.length <= 1 || expanded ? cohorts : cohorts.slice(0, 1);
  const unit = 'cohort'.concat(cohorts.length > 2 ? 's' : '');

  return (
    <div className={classes.wrapper}>
      <table className={classes.subtable}>
        <thead>
          {/* Add table headers to enforce the same column widths as the parent */}
          <tr>
            {columns.map((c) => (
              <th key={c.id} style={{ width: c.width }}></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayCohorts.map((c) => (
            <Cohort key={c.id} {...c} />
          ))}
        </tbody>
      </table>
      {cohorts.length > 1 && (
        <button className={classes.expander} onClick={toggleShowMore}>
          <span className={typography.link}>{expanded ? `Hide ${unit}` : `+ ${cohorts.length - 1} ${unit}`}</span>
        </button>
      )}
    </div>
  );
};
