import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { ALL_SITES, AVAILABLE_TIME_SITE_STATUSES, ValuesetCodes } from '@tempus/t-shared';
import cn from 'classnames';
import _, { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { DiagnosisFilter } from '~/components/TrialSearchInput/diagnosis.filter';
import { config } from '~/config';
import { convertPatientInputToRulesEnginePatient } from '~/routes/trialsPage/trialSearch/utils';
import { RootState } from '~/store';
import { clearPatient, clearTrials, setShowUnavailableTrialsButton } from '~/store/trialSearch/slice';
import { initialState } from '~/store/trialSearch/state';
import { getTrialMatches } from '~/store/trialSearch/thunks';

import TrialSearchByPatient from '../TrialSearchByPatient';
import { TrialSearchInput } from '../TrialSearchInput';
import { BiomarkerFilter } from '../TrialSearchInput/biomarkers.filter';
import { DemographicsFilter } from '../TrialSearchInput/demographics.filter';
import useStyles from './styles';
import { TrialSearchInputTitles } from './types';

interface TrialSearchBarProps extends RouteComponentProps, ConnectedProps<typeof connector> {}

const TrialSearchBar: React.FC<TrialSearchBarProps> = ({ getTrialMatches, trialSearch, selectedUserSite }) => {
  const classes = useStyles();
  const { loading, patientInput } = trialSearch;
  const dispatch = useDispatch();
  const elementFocusRef = useRef<HTMLDivElement | null>(null);

  const setElementFocusRef = (element) => {
    elementFocusRef.current = element;
  };

  // START Logic to set focus on the active filter container on rerenders to handle blur events to close the popovers.

  const setFocusOnActiveFilterContainer = useCallback(
    // We need to debounce this because setFocus is called more than 1 time sometimes on a single biomarker option select.
    _.debounce(() => {
      /* The window.biomarkerUpdated flag is set to true every time the biomarker options are updated. We update focus 
       only if the biomarkers weren't just updated. This is because, if the biomarkers are updated and we update focus, 
       the open biomarker dropdown closes and any user input is also lost. So, if the flag is true, we set the flag to 
       false and return. */
      // eslint-disable-next-line no-undef
      if (window.areBiomarkersUpdatedForTrialFilters) {
        // eslint-disable-next-line no-undef
        window.areBiomarkersUpdatedForTrialFilters = false;
        return;
      }

      // Note: We skip updating focus for the demographics filter as its focus is not being lost in any scenario,
      // and explicitly updating focus for it causes the popover to close on input into the date field.
      if (elementFocusRef.current && elementFocusRef.current.id !== TrialSearchInputTitles.DEMOGRAPHICS) {
        elementFocusRef.current.focus();
      }
    }, 100),
    [],
  );

  // This effect ensures the focus is on the correct diagnosis, biomarker, or demographic
  // input container when the TrialSearchInput component is rerendered.
  useEffect(() => {
    setFocusOnActiveFilterContainer();
  });

  // END Logic to set focus on the active filter container on rerenders to handle blur events to close the popovers.

  const whitelistedInstitutionsForPatientNameSearch = config.patientNameSearchInstitutionsWhitelist?.length
    ? config.patientNameSearchInstitutionsWhitelist.split(',')
    : [];

  const [isWhitelistedInstitutionForPatientNameSearch, setIsWhitelistedInstitutionForPatientNameSearch] = useState(
    false,
  );

  const [searchInputChanged, setSearchInputChanged] = useState(true);
  const [searchParamsSameAsInitialState, setSearchParamsSameAsInitialState] = useState(
    isEqual(patientInput, initialState.patientInput),
  );

  const whitelistedUserEmailsForPatientNameSearch = config.patientNameSearchUsersWhitelist?.length
    ? config.patientNameSearchUsersWhitelist.split(',')
    : [];

  const currentUserEmail = useSelector((state: RootState) => state.user.user?.email);

  const isPatientNameSearchWhitelistedUser =
    currentUserEmail && whitelistedUserEmailsForPatientNameSearch.includes(currentUserEmail);

  const solidTumorOptions = useSelector(
    ({ trialSearch: { dropdowns } }: RootState) => dropdowns[ValuesetCodes.COHORT_CANCER_SOLID_TUMORS] || [],
  );

  useEffect(() => {
    setSearchInputChanged(true);
    setSearchParamsSameAsInitialState(isEqual(patientInput, initialState.patientInput));
  }, [patientInput]);

  const fireSearch = () => {
    const patient = convertPatientInputToRulesEnginePatient(patientInput, solidTumorOptions);
    if (selectedUserSite) {
      getTrialMatches({
        searchParams: { patient },
        siteId: selectedUserSite.id,
        statuses: AVAILABLE_TIME_SITE_STATUSES,
      });
      dispatch(setShowUnavailableTrialsButton(true));
    }

    setSearchInputChanged(false);
  };

  const clearAll = () => {
    dispatch(clearPatient(Object.keys(patientInput)));
    dispatch(clearTrials());
  };

  useEffect(() => {
    fireSearch();

    setIsWhitelistedInstitutionForPatientNameSearch(
      Boolean(selectedUserSite && whitelistedInstitutionsForPatientNameSearch.includes(selectedUserSite.id)),
    );
  }, [selectedUserSite?.id]);

  return (
    <div className={classes.bg}>
      <TrialSearchInput
        title={TrialSearchInputTitles.DEMOGRAPHICS}
        placeholderSubtitle={'Add demographics'}
        Component={DemographicsFilter}
        stateKeys={['age', 'dateOfBirth']}
        setElementFocusRef={setElementFocusRef}
      />
      <div className={classes.vl} />
      <TrialSearchInput
        title={TrialSearchInputTitles.DIAGNOSIS}
        placeholderSubtitle={'Add diagnosis'}
        Component={DiagnosisFilter}
        stateKeys={['diagnoses']}
        setElementFocusRef={setElementFocusRef}
      />
      <div className={classes.vl} />
      <TrialSearchInput
        title={TrialSearchInputTitles.BIOMARKERS}
        placeholderSubtitle={'Add biomarker'}
        Component={BiomarkerFilter}
        stateKeys={['variantOptions', 'tmbResults']}
        setElementFocusRef={setElementFocusRef}
      />

      {searchParamsSameAsInitialState &&
      (isWhitelistedInstitutionForPatientNameSearch ||
        (isPatientNameSearchWhitelistedUser && selectedUserSite?.name !== ALL_SITES)) ? (
        <TrialSearchByPatient />
      ) : (
        <p className={cn(typography.link, typography.small)} onClick={clearAll} data-testid="clear-all">
          Clear all
        </p>
      )}

      <Button
        className={classes.searchButton}
        loading={loading}
        disabled={loading || (searchParamsSameAsInitialState && trialSearch.trials.length === 0) || !searchInputChanged}
        buttonType="primary"
        onClick={fireSearch}
        ariaLabel={'search'}
        data-pendo-id="search-button"
        data-testid="search-button">
        Search
      </Button>
    </div>
  );
};

const connector = connect(
  (state: RootState) => {
    return {
      trialSearch: state.trialSearch,
      selectedUserSite: state.site.selectedUserSite,
      canImpersonate: state.user.canImpersonate,
    };
  },
  {
    getTrialMatches: getTrialMatches.action,
  },
);

export default withRouter(connector(TrialSearchBar));
