import { Menu, MenuItem, Popover } from '@tempus/component-library';
import { DocumentTagKey, DocumentTagView } from '@tempus/t-shared';
import { storeActions } from '@tempus/t-shared/ui';
import { kebabCase, orderBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { grayPalette } from 'tcl-v3/colors';
import { AddSimple, Close } from 'tcl-v3/icons';
import { DropdownOption, InputTheme } from 'tcl-v3/models';
import { IconButton, Loader, Tag } from 'tcl-v3/prefabs';

import { fixedPopperProps } from '~/components/constants';

import TagValueSelect from './TagValueSelect';
import useStyles, { useTagStyles } from './styles';
import { LocalTags } from './types';

// For now, we are using hardcoded keys instead of determining
//   them based on existing tags.
const TAG_KEYS = Object.values(DocumentTagKey);

enum EditorState {
  Button,
  Key,
  Value,
}

interface DocumentTagPickerProps {
  readOnly?: boolean;
  className?: string;
  tags: DocumentTagView[];
  localTags?: LocalTags;
  excludedKeys?: DocumentTagKey[];
  onTagsChanged: (tags: DocumentTagView[]) => Promise<boolean>;
}

const DocumentTagPicker: React.FC<DocumentTagPickerProps> = ({
  tags,
  readOnly,
  className,
  localTags,
  onTagsChanged,
  excludedKeys = [],
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tagClasses = useTagStyles();
  const [editorKey, setEditorKey] = useState('');
  const valueFieldRef = useRef<HTMLDivElement>(null);
  const [persisting, setPersisting] = useState(false);
  const [showOptionMenu, setShowOptionMenu] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.Button);

  useEffect(() => {
    if (editorState === EditorState.Button) {
      setEditorKey('');
    }
  }, [editorState]);

  useEffect(() => {
    if (editorState === EditorState.Value && valueFieldRef.current) {
      setShowOptionMenu(true);
      valueFieldRef.current.focus();
    }
  }, [valueFieldRef.current, editorState]);

  const persistTags = async (tags: DocumentTagView[]) => {
    setPersisting(true);

    const success = await onTagsChanged(tags);

    if (!success) {
      dispatch(storeActions.notification.showErrorMessage('Failed to update tags.'));
    }

    setEditorState(EditorState.Button);
    setPersisting(false);
  };

  const onDelete = (deletedTag: DocumentTagView) => async () => {
    if (persisting) {
      return; // Prevent deleting while something else is being processed.
    }

    await persistTags(tags.filter((tag) => !(tag.key === deletedTag.key && tag.value === deletedTag.value)));
  };

  const renderExistingTag = (tag: DocumentTagView) => (
    <Tag
      classes={tagClasses}
      key={`${tag.key}/${tag.value}`}
      data-pendo-id="document-tag-editor-tag"
      onDelete={!readOnly ? onDelete(tag) : undefined}>
      <strong>{tag.key}:</strong> {tag.value}
    </Tag>
  );

  const valueSelected = async (option: DropdownOption | undefined | null) => {
    if (!option) {
      return;
    }

    await persistTags([...tags, { key: editorKey, value: option.value }]);
  };

  const renderCombobox = () => {
    return (
      <TagValueSelect
        ref={valueFieldRef}
        localTags={localTags}
        disabled={persisting}
        onChange={valueSelected}
        showOptionMenu={showOptionMenu}
        tagKey={editorKey as DocumentTagKey}
        setShowOptionMenu={setShowOptionMenu}
      />
    );
  };

  const renderKeyTarget = () => {
    const nextEditorState = editorState === EditorState.Button ? EditorState.Key : EditorState.Button;

    return (
      <IconButton
        isInverted
        shape="circle"
        name="Add Tag"
        Icon={AddSimple}
        disabled={persisting}
        theme={InputTheme.Tempus}
        className={classes.addButton}
        data-pendo-id="document-tag-picker-add-button"
        onClick={() => setEditorState(nextEditorState)}
      />
    );
  };

  const keySelected = (key: string) => () => {
    setEditorKey(key);
    setEditorState(EditorState.Value);
  };

  const renderEditor = () => {
    if (persisting || readOnly) {
      return null;
    } else if (editorState === EditorState.Button || editorState === EditorState.Key) {
      const availableTags = TAG_KEYS.filter((key) => !excludedKeys.includes(key));

      return (
        <Popover
          hideArrow
          placement="bottom-start"
          target={renderKeyTarget()}
          popperProps={fixedPopperProps}
          open={editorState === EditorState.Key}
          onRequestClose={() => setEditorState(EditorState.Button)}>
          <Menu>
            {availableTags.map((key) => (
              <MenuItem
                key={key}
                onClick={keySelected(key)}
                data-pendo-id={`document-tag-picker-key-item-${kebabCase(key)}`}>
                {key}
              </MenuItem>
            ))}
          </Menu>
        </Popover>
      );
    }

    return (
      <div className={classes.select}>
        {renderCombobox()}
        <button
          disabled={persisting}
          onClick={() => setEditorState(EditorState.Button)}
          data-pendo-id="document-tag-picker-cancel-button">
          <Close color={grayPalette.gray40} />
        </button>
      </div>
    );
  };

  const displayedTags = tags.filter((tag) => !excludedKeys.includes(tag.key as DocumentTagKey));

  return (
    <div className={`${classes.root} ${className}`} data-pendo-id="document-tag-picker">
      {orderBy(displayedTags, ['key', 'value'], ['asc', 'asc']).map(renderExistingTag)}

      {renderEditor()}

      {persisting && <Loader scale={0.5} />}
    </div>
  );
};

export default DocumentTagPicker;
