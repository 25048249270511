import { DropdownOption } from 'tcl-v3/models';

export enum AddPatientPage {
  IDENTIFICATION_PAGE = 'IDENTIFICATION_PAGE',
  MATCH_INFORMATION_PAGE = 'MATCH_INFORMATION_PAGE',
  DUPLICATE_MATCH_PAGE = 'DUPLICATE_MATCH_PAGE',
  INELIGIBLE_MATCH_PAGE = 'INELIGIBLE_MATCH_PAGE',
}

export interface CohortDropdownOption extends DropdownOption {
  name?: string;
  programType?: number;
}

export enum PatientInputFieldsEnum {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  DOB = 'DOB',
  NCT_ID = 'NCT_ID',
  COHORT = 'COHORT',
  TRIAL_ID = 'TRIAL_ID',
  BIOMARKER = 'BIOMARKER',
  PATIENT_STATUS = 'PATIENT_STATUS',
  COMMENTS = 'COMMENTS',
  NEXT_VISIT_DATE = 'NEXT_VISIT_DATE',
  VISIT_TYPE = 'VISIT_TYPE',
  MRN = 'MRN',
}

export type PatientInputFields = {
  [PatientInputFieldsEnum.FIRST_NAME]: string | null;
  [PatientInputFieldsEnum.LAST_NAME]: string | null;
  [PatientInputFieldsEnum.DOB]: string | null;
  [PatientInputFieldsEnum.COHORT]: CohortDropdownOption[];
  [PatientInputFieldsEnum.MRN]: string;
} & Record<
  Exclude<
    PatientInputFieldsEnum,
    | PatientInputFieldsEnum.FIRST_NAME
    | PatientInputFieldsEnum.LAST_NAME
    | PatientInputFieldsEnum.COHORT
    | PatientInputFieldsEnum.DOB
  >,
  string
>;

export type PatientInputFieldsUpdates = Partial<PatientInputFields>;
