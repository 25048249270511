import { BrandPalette } from '@tcl-boron-colors/colors';
import colors from '@tempus/component-library/styles/colors';
import styled from 'styled-components';

export default styled.div`
  font-size: 13px;
  width: 100%;
  color: ${BrandPalette[700]};
  table {
    tr:nth-child(odd) {
      border-bottom: none !important;
    }
    tr .editCell {
      display: none;
    }
    tr:hover .editCell {
      display: block;
    }
    > td {
      max-width: 600px;
      cursor: pointer;
    }
  }
  .linkButton {
    color: #7b7b7e;
    font-size: 11px;
  }
  .linkButton:hover {
    color: ${colors.blue};
    transition: all 0.3s ease;
  }
  button svg:hover {
    fill: ${colors.ceruleanBlue};
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  .saveButtons {
    display: flex;
  }
  .saveButtons button:first-of-type {
    margin-right: 10px;
  }
  .filterButton {
    width: 70px;
    text-align: left;
  }
  textarea {
    border: 1px solid ${colors.sterling};
    border-radius: 3px;
    font-size: 12px;
    padding: 10px;
    transition: background-color 0.3s, box-shadow 0.3s;
    resize: none;
  }
  textarea:focus {
    outline: none !important;
    border-color: ${colors.blue};
    box-shadow: 0 0 10px #719ece;
  }
  .no-trials-div {
    display: flex;
    flex-direction: column;
  }
  .show-unavailable-trials-button {
    margin: 30px auto 0 auto;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;
