import _ from 'lodash';

export const ArrayParam = {
  // we need handle the value specially since current router and history version
  // encode the value automatically which causes the value to be encoded multiple times
  encode: (value) =>
    _.castArray(value)
      .map((v) => v.replace(/ /g, '-'))
      .join('_'),
  decode: (value) => {
    return (value as string)?.split('_').map((v) => (v as string).replace(/-/g, ' '));
  },
};
