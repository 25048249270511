import { SingleSelectComboBox } from '@tcl-boron-prefabs/single-select-combo-box';
import { ALL_SITES } from '@tempus/t-shared';
import { ThreeColumnLayout } from '@tempus/t-shared/ui';
import classnames from 'classnames';
import { sortBy, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';
import { DropdownOption } from 'tcl-v3/models';

import { allSitesOption } from '~/components/constants';
import { RootState } from '~/store';
import { creators as siteCreators } from '~/store/site';

import useStyles, { useLayoutStyles } from './styles';

type HeaderProps = RouteComponentProps;

const Header: React.FC<HeaderProps> = () => {
  const userSites = useSelector(({ site }: RootState) => site.userSites);
  const {
    user: { canImpersonate },
    site: { selectedUserSite },
  } = useSelector((state: RootState) => state);

  const location = useLocation();
  const path = location.pathname;
  const [selectedSite, setSelectedSite] = useState<DropdownOption | null>(null);

  const dispatch = useDispatch();
  const showAllSites = canImpersonate && (path.includes('trials') || path.includes('patient-tracker'));

  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const sortedUserSites = sortBy(Object.entries(userSites || {}), ([, site]) => startCase(site.shortName || site.name));
  const allDropdownOptions: DropdownOption[] = sortedUserSites.map(([id, site]) => ({
    value: id,
    label: site.shortName || site.name,
  }));
  if (showAllSites) {
    allDropdownOptions.unshift(allSitesOption);
  }

  const setSelectedUserSite = (selectedItem: DropdownOption | null) => {
    if (selectedItem) {
      dispatch(siteCreators.setUserSites(userSites, { id: selectedItem.value, name: selectedItem.label }));
      setSelectedSite(selectedItem);
    }
  };

  useEffect(() => {
    if (selectedUserSite && selectedUserSite.name !== ALL_SITES) {
      // if a site is selected, set the site
      setSelectedUserSite({ value: selectedUserSite?.id as string, label: selectedUserSite?.name as string });
      return;
    }
    if (showAllSites) {
      // if "All Sites" can be shown, show "All Sites"
      setSelectedUserSite(allSitesOption);
      return;
    }
  }, []);

  useEffect(() => {
    if (!showAllSites && selectedUserSite?.name === ALL_SITES) {
      setSelectedSite(null);
    }
    if (showAllSites && selectedUserSite === null) {
      setSelectedUserSite(allSitesOption);
    }
  }, [location]);

  const renderLeft = () => {
    return (
      <div className={classnames({ [classes.comboBoxWrapper]: true })}>
        <SingleSelectComboBox
          options={allDropdownOptions}
          onChange={setSelectedUserSite}
          value={selectedSite}
          placeholder={'Select a site'}
          dropdownType={'primary'}
          label={'Site'}
          clearable={false}
        />
      </div>
    );
  };

  return <ThreeColumnLayout left={renderLeft()} classes={layoutClasses} />;
};

export default withRouter(Header);
