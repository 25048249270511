import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
  },
  tabGroup: {
    marginTop: '32px',
  },
  tabGroupBorder: {
    height: '4px',
    borderBottom: `1px solid ${GrayscalePalette[400]}`,
    marginBottom: '32px',
  },
  overlayWarning: {
    width: 'inherit',
    maxWidth: '100% !important',
    padding: '24px 18px',
    alignItems: 'center',
  },
  iconStyles: {
    padding: '0 20px 0 10px',
  },
});
