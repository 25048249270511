import { DocumentTagKey } from '@tempus/t-shared';
import { uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownOption, InputTheme } from 'tcl-v3/models';
import { mergeClasses } from 'tcl-v3/utils';

import {
  CreatableSingleSelectCombobox,
  CreatableSingleSelectComboboxProps,
} from '~/components/CreatableSingleSelectCombobox';
import { formatCreateLabel } from '~/components/CreatableSingleSelectCombobox/shared';
import { RootState } from '~/store';
import { creators as documentEditCreators } from '~/store/documentEdit';

import { useComboboxStyles } from './styles';
import { LocalTags } from './types';

const makeOptions = (list: string[]) =>
  uniq(list)
    .sort()
    .map((value) => ({ label: value, value }));

interface TagValueSelectProps {
  label?: string;
  tagValue?: string | null;
  disabled?: boolean;
  localTags?: LocalTags;
  tagKey: DocumentTagKey;
  showOptionMenu?: boolean;
  setShowOptionMenu?: (value: boolean) => void;
  onChange: (option: DropdownOption | undefined | null, tagKey: DocumentTagKey) => Promise<void>;
  theme?: InputTheme;
  classes?: Record<string, string>;
}

const TagValueSelect = React.forwardRef<HTMLDivElement, TagValueSelectProps>(
  (
    {
      tagKey,
      tagValue,
      label = '',
      disabled,
      localTags,
      onChange,
      showOptionMenu: externalShowOptionMenu,
      setShowOptionMenu: externalSetShowOptionMenu,
      theme,
      classes: classOverrides = {},
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const classes = mergeClasses(useComboboxStyles(), classOverrides);
    const [internalShowOptionMenu, internalSetShowOptionMenu] = useState(false);

    const showOptionMenu = externalShowOptionMenu || internalShowOptionMenu;
    const setShowOptionMenu = externalSetShowOptionMenu || internalSetShowOptionMenu;

    const hideLabel = !label;

    useEffect(() => {
      dispatch(documentEditCreators.getTag(tagKey));
    }, [tagKey]);

    const {
      documentEdit: { tags: storeTags, loadingTags },
    } = useSelector((state: RootState) => state);

    const comboboxProps: Omit<CreatableSingleSelectComboboxProps, 'options' | 'onChange'> = {
      label,
      classes,
      disabled,
      hideLabel,
      theme,
      onBlur: () => setShowOptionMenu(false),
      onFocus: () => setShowOptionMenu(true),
    };

    const selectProps: CreatableSingleSelectComboboxProps['reactSelectProps'] = {
      formatCreateLabel,
      menuPosition: 'fixed',
      menuPlacement: 'auto',
      isLoading: loadingTags,
      menuIsOpen: showOptionMenu,
      menuShouldScrollIntoView: false,
      menuPortalTarget: document.body,
    };

    const existingValueOptions = storeTags[tagKey] || [];
    const additionalValueOptions = localTags ? localTags[tagKey] || [] : [];
    const valueOptions = makeOptions([...existingValueOptions, ...additionalValueOptions]);

    const internalOnChange = (option: DropdownOption | undefined | null) => {
      onChange(option, tagKey);
      setShowOptionMenu(false);
    };

    return (
      <CreatableSingleSelectCombobox
        {...comboboxProps}
        options={valueOptions}
        onChange={internalOnChange}
        reactSelectProps={{ ...selectProps, ref: ref }}
        data-pendo-id="document-tag-value-select-combobox"
        value={tagValue ? valueOptions.find((o) => o.value === tagValue) : null}
      />
    );
  },
);

export default TagValueSelect;
