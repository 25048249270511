import { Tab } from '@tcl-boron-prefabs/tab';
import { TabGroup } from '@tcl-boron-prefabs/tab-group';
import { MaxPageWidth, Spinner } from '@tempus/t-shared/ui';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { withRouter, useHistory } from 'react-router-dom';

import Header from '~/components/Header';
import { Routes } from '~/routes';
import Styles from '~/routes/sites/styles';
import { RootState } from '~/store/';
import { creators as trialCreators } from '~/store/trial/';

import { useTabGroupStyles, useClasses } from './styles';

const TrialsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(({ trial }: RootState) => trial);
  const history = useHistory();
  const tabGroupClasses = useTabGroupStyles();
  const materialsTableCount = useSelector(({ trial }: RootState) => trial.filteredCount);

  const subtabRoutes = [
    Routes.trialSearch,
    {
      ...Routes.trialMaterials,
      title: `${Routes.trialMaterials.title}${
        history.location.pathname === Routes.trialMaterials.path ? ` (${materialsTableCount})` : ''
      }`,
    },
  ];

  useEffect(() => {
    dispatch(trialCreators.getAllTrials());
  }, []);

  const handleChange = (selected: string) => {
    history.push(selected);
  };

  const subTabs = subtabRoutes.map((route) => {
    const datatestid = route.title;
    return (
      <Tab key={route.path} value={route.path} className={tabGroupClasses.tab} data-testid={datatestid}>
        {route.title}
      </Tab>
    );
  });

  const subRoutes = subtabRoutes.map((route) => (
    <Route path={route.path} key={route.path} component={route.component} />
  ));

  return (
    <>
      <MaxPageWidth classes={useClasses()}>
        <Styles>
          <Header headerTitle={'Trials'} />

          <TabGroup onChange={handleChange} isTabSelected={(tabValue) => tabValue === history.location.pathname}>
            {subTabs}
          </TabGroup>
          {loading && <Spinner loading transparent />}
        </Styles>
      </MaxPageWidth>
      <Switch>
        {subRoutes}
        <Redirect to={Routes.trialSearch.path} />
      </Switch>
    </>
  );
};

export default withRouter(TrialsPage);
