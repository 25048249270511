import React, { ReactNode } from 'react';
import { FormatOptionLabelMeta } from 'react-select';
import { InputTheme } from 'tcl-v3/models';

import {
  CreatableSingleSelectCombobox,
  CreatableSingleSelectComboboxProps,
} from '~/components/CreatableSingleSelectCombobox';
import { formatCreateLabel } from '~/components/CreatableSingleSelectCombobox/shared';

import { CreatableSingleSelectComponentOverrides } from '../CreatableSingleSelectCombobox/overrides';
import useStyles from './styles';
import { PatientDropdownOption } from './types';

interface PatientNameSelectProps {
  label: string;
  placeholder?: string;
  reactSelectProps?: CreatableSingleSelectComboboxProps<PatientDropdownOption>['reactSelectProps'];
  selectedValue: PatientDropdownOption | null;
  options?: PatientDropdownOption[];
  formatOptionLabel?: (
    option: PatientDropdownOption,
    optionMeta: FormatOptionLabelMeta<PatientDropdownOption, false>,
  ) => ReactNode;
  onChange: (option: PatientDropdownOption | null) => void;
  isLoadingPatients?: boolean;
  async?: boolean;
  loadOptions?: (input: string, callback: (options: PatientDropdownOption[]) => void) => void;
  createDisabled?: boolean;
  theme?: InputTheme;
  componentOverrides?: CreatableSingleSelectComponentOverrides;
  isDisabled?: boolean;
}

const PatientNameSelect: React.FC<PatientNameSelectProps> = ({
  label,
  placeholder,
  selectedValue,
  options,
  formatOptionLabel,
  onChange,
  reactSelectProps,
  isLoadingPatients,
  async,
  loadOptions,
  createDisabled,
  theme,
  componentOverrides = {},
  isDisabled,
}) => {
  const classes = useStyles();

  const internalOnChange = (option: PatientDropdownOption | null) => {
    onChange(option);
  };

  const comboBoxProps: Omit<CreatableSingleSelectComboboxProps<PatientDropdownOption>, 'onChange'> = {
    label,
    placeholder,
    options,
    async,
    createDisabled,
    classes,
    theme,
    componentOverrides,
    isDisabled,
  };

  const selectProps: CreatableSingleSelectComboboxProps<PatientDropdownOption>['reactSelectProps'] = {
    ...reactSelectProps,
    formatCreateLabel,
    menuPosition: 'fixed',
    menuPlacement: 'auto',
    isLoading: isLoadingPatients,
    menuShouldScrollIntoView: false,
    menuPortalTarget: document.body,
  };

  return (
    <React.Fragment>
      <div className={classes.comboBox}>
        <CreatableSingleSelectCombobox<PatientDropdownOption>
          {...comboBoxProps}
          loadOptions={loadOptions}
          onChange={internalOnChange}
          reactSelectProps={{ ...selectProps }}
          data-pendo-id="patient-tracker-patient-name-select-combobox"
          value={selectedValue}
          formatOptionLabel={formatOptionLabel}
          useBoronStyles={true}
        />
      </div>
    </React.Fragment>
  );
};

export default PatientNameSelect;
