import { PATIENT_TRACKER_STATUS_CATEGORIES } from '@tempus/t-shared/src/constants/patient-tracker';
import { MaxPageWidth, NotAvailable, ScreenEmptyImage } from '@tempus/t-shared/ui';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';

const NoPatients: React.FC = () => {
  const { statusCategory } = useSelector((state: RootState) => state.patientTrackerV2.patientTrackerV2);
  const title = statusCategory === PATIENT_TRACKER_STATUS_CATEGORIES.NEW ? 'All up to date' : 'No patients yet';

  let description;
  switch (statusCategory) {
    case PATIENT_TRACKER_STATUS_CATEGORIES.MONITORING:
      description = (
        <>
          <div>
            Patients that might be eligible for a TIME trial in the future will display here. This includes a patient
            status of watchlist.
          </div>
          <br />
          <div>
            To update a patient’s status, click Update and select a new status from the Patient Status dropdown.
          </div>
        </>
      );
      break;
    case PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED:
      description = (
        <>
          <div>Patients that have enrolled in a trial will display here.</div>
          <br />
          <div>
            To update a patient’s status, click Update and select a new status from the Patient Status dropdown.
          </div>
        </>
      );
      break;
    case PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE:
      description = (
        <>
          <div>
            Patients that should no longer be considered for a TIME trial will display here. This includes a patient
            status of no longer eligible.
          </div>
          <br />
          <div>
            To update a patient’s status, click Update and select a new status from the Patient Status dropdown.
          </div>
        </>
      );
      break;
    default:
      description = 'New patients that require review will appear here. Looks like you are all up to date.';
  }

  return (
    <MaxPageWidth>
      <NotAvailable title={title} Image={ScreenEmptyImage} description={description} />
    </MaxPageWidth>
  );
};

export default NoPatients;
