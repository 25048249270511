import { Badge, BadgeProps } from '@tcl-boron-prefabs/badge';
import { Card } from '@tcl-boron-prefabs/card';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import {
  AVAILABLE_TIME_SITE_STATUSES,
  getStatusBadgeStyling,
  getTimeProgramSiteStatusBadgeText,
  PROGRAM_TYPES_DEC,
  TimeProgramSiteStatus,
} from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUSES } from '@tempus/t-shared/src/constants/patient-tracker';
import React, { useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';

import { Routes } from '~/routes';
import { RootState } from '~/store';
import { Cohort, TrialSearchResult } from '~/store/trialSearch/state';
import { formatCohortOptions } from '~/utils/arm';

import AddPatient from '../AddPatientV2';
import { PatientInputFieldsEnum } from '../AddPatientV2/types';
import { getRelevantBiomarkers } from './getRelevantBiomarkers';
import useStyles, { customCard } from './styles';

const addToPatientTracker = {
  text: 'Request nurse review',
  id: 'addToPatientTracker',
  'data-pendo-id': 'trial-card-request-nurse-review',
};
const seeTrialDocuments = {
  text: 'See trial documents',
  id: 'seeTrialDocuments',
  'data-pendo-id': 'trial-card-see-trial-documents',
};

interface TrialSearchResultCardProps extends TrialSearchResult, ConnectedProps<typeof connector> {}

const TrialSearchResultCard: React.FC<TrialSearchResultCardProps> = ({
  id,
  nctId,
  shortName,
  title,
  cohorts,
  programSites,
  patientBiomarkers,
  canWritePatientTrackingExternal,
}) => {
  const selectedUserSite = useSelector(({ site }: RootState) => site.selectedUserSite);

  const matchingArms = cohorts.reduce((acc, curr) => ((acc[curr.name] = null), acc), {});
  const programSiteStatus = programSites.map((site) => site.status)[0];
  const isTrialAvailableToSite = AVAILABLE_TIME_SITE_STATUSES.includes(programSiteStatus as TimeProgramSiteStatus);
  const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false);
  const [fetchedBiomarkers, setFetchedBiomarkers] = useState<undefined | string>(undefined);

  const classes = useStyles();

  const actionButtonClicked = async (button: string) => {
    switch (button) {
      case addToPatientTracker.id:
        const relevantBiomarkers = await getRelevantBiomarkers({ patientBiomarkers, trialId: id, matchingArms });
        setFetchedBiomarkers(relevantBiomarkers);
        setIsAddPatientModalOpen(true);
        return;
      case seeTrialDocuments.id:
        // setting in localStorage since Redux gets cleared when opening a new tab
        localStorage.setItem('selectedUserSite', JSON.stringify(selectedUserSite));
        window.open(`${Routes.trialDocuments.path.replace(':trialId', id)}`, '_blank');
        return;
    }
  };

  const generateCohort = (cohort: Cohort) => {
    return (
      <div key={cohort.id} className={classes.cohortContainer}>
        <div>
          <strong data-testid="trial-card-cohort-name">{cohort.name}</strong>
        </div>

        <Badge
          className={classes.badge}
          text={getTimeProgramSiteStatusBadgeText(cohort.displayStatus)}
          badgeVariant={getStatusBadgeStyling(cohort.displayStatus).variant as BadgeProps['badgeVariant']}
          badgeType={getStatusBadgeStyling(cohort.displayStatus).type}
        />
        {cohort?.summary?.phases?.length ? (
          <div className={classes.subtextAttribute}>
            <span className={classes.subtextAttributeKey} data-testid="trial-card-phase">
              Phase
            </span>
            <span className={classes.subtextAttributeValue}>{cohort.summary.phases.join(', ')}</span>
          </div>
        ) : null}

        {cohort?.summary?.agents?.length ? (
          <div className={classes.subtextAttribute}>
            <span className={classes.subtextAttributeKey} data-testid="trial-card-agent">
              Agent
            </span>
            <span className={classes.subtextAttributeValue}>
              {cohort.summary.agents.map((agent) => agent.name).join(', ')}
            </span>
          </div>
        ) : null}

        {cohort?.summary?.linesOfTherapies?.length ? (
          <div className={classes.subtextAttribute}>
            <span className={classes.subtextAttributeKey} data-testid="line-of-therapy">
              Line of therapy
            </span>
            <span className={classes.subtextAttributeValue}>{cohort.summary.linesOfTherapies.join(', ')}</span>
          </div>
        ) : null}
      </div>
    );
  };

  const getPatientStatus = (cohorts: Cohort[]) =>
    cohorts.every((c) => c.programType === PROGRAM_TYPES_DEC.ST) ? '' : PATIENT_TRACKER_STATUSES.PENDING_TEMPUS_REVIEW;

  return (
    <>
      <Card data-pendo-id="trial-card" classes={customCard()}>
        <>
          <div className={classes.cardContent}>
            <p className={typography.body} data-testid="trial-card-nctid">
              {nctId}
            </p>
            <h1 className={typography.sectionHeader} data-testid="trial-short-name">
              {shortName}
            </h1>
            <p className={`${typography.body} ${classes.blackText}`} data-testid="trial-title">
              {title}
            </p>
            <div className={typography.body}>{cohorts.map((cohort) => generateCohort(cohort))}</div>
          </div>
          <div className={classes.footer}>
            <div className={classes.footerButtons}>
              <div
                onClick={() => actionButtonClicked(seeTrialDocuments.id)}
                className={classes.footerButton}
                data-pendo-id={seeTrialDocuments['data-pendo-id']}>
                {seeTrialDocuments.text}
              </div>
              {canWritePatientTrackingExternal && isTrialAvailableToSite && (
                <div
                  onClick={() => actionButtonClicked(addToPatientTracker.id)}
                  className={classes.footerButton}
                  data-pendo-id={addToPatientTracker['data-pendo-id']}>
                  {addToPatientTracker.text}
                </div>
              )}
            </div>
          </div>
        </>
      </Card>
      {isAddPatientModalOpen && fetchedBiomarkers !== undefined && (
        <AddPatient
          hideButton={true}
          setIsAddPatientModalOpen={setIsAddPatientModalOpen}
          prepopulatedPatientValues={{
            [PatientInputFieldsEnum.NCT_ID]: nctId,
            [PatientInputFieldsEnum.COHORT]: formatCohortOptions(cohorts),
            [PatientInputFieldsEnum.BIOMARKER]: fetchedBiomarkers,
            [PatientInputFieldsEnum.PATIENT_STATUS]: getPatientStatus(cohorts),
          }}
        />
      )}
    </>
  );
};

const connector = connect((state: RootState) => {
  return {
    canWritePatientTrackingExternal: state.user.canWritePatientTrackingExternal,
    patientBiomarkers: state.trialSearch.patientInput.variantOptions,
  };
}, {});

export default connector(TrialSearchResultCard);
