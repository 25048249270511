// Copied from https://go.opstempus.com/oXLw1X.

import { ActionPalette } from '@tcl-boron-colors/colors';
import { CSSProperties } from 'react';
import { StylesConfig } from 'react-select';
import { grayPalette, tempusPalette } from 'tcl-v3/colors';
import { getThemeStyles } from 'tcl-v3/utils';

const container = (base, state): CSSProperties => {
  if (state.isDisabled) {
    return {
      ...base,
      opacity: '0.5',
    };
  }

  return base;
};

const control = (base, state): CSSProperties => {
  const {
    selectProps: { inputTheme, useBoronStyles },
  } = state;
  const { defaultStyles: defaultv3Styles, focusedStyles: focusedv3Styles } = getThemeStyles(inputTheme);

  const defaultStyles = useBoronStyles ? { borderColor: ActionPalette[200], borderRadius: '24px' } : defaultv3Styles;
  const focusedStyles = useBoronStyles ? { color: ActionPalette[600] } : focusedv3Styles;

  return {
    ...base,
    ...defaultStyles,
    '&:focus-within': {
      ...focusedStyles,
    },
    '&:hover': {
      boxShadow: `0 2px 4px 0 ${grayPalette.gray20}`,
      borderColor: useBoronStyles ? ActionPalette[500] : defaultv3Styles.borderColor,
    },
    boxShadow: 'none',
    cursor: 'pointer',
    minHeight: '35px',
    transition: 'background-color 0.3s',
  };
};

const valueContainer = (base, { selectProps: { useBoronStyles } }): CSSProperties => ({
  ...base,
  fontSize: useBoronStyles ? '12px' : '14px',
  color: useBoronStyles ? `${ActionPalette[500]} !important` : 'inherit',
  padding: '2px 8px 2px 13px',
});

const placeholder = (base, state): CSSProperties => {
  const {
    selectProps: { useBoronStyles },
  } = state;
  return {
    ...base,
    color: useBoronStyles ? `${ActionPalette[500]} !important` : grayPalette.gray50,
  };
};

const clearIndicator = (base): CSSProperties => ({
  ...base,
  padding: '8px 10px',
});

const dropdownIndicator = (base): CSSProperties => ({
  ...base,
  padding: '8px 15px 8px 10px',
});

const indicatorSeparator = (base, { hasValue }): CSSProperties => ({
  ...base,
  backgroundColor: hasValue ? grayPalette.gray30 : 'transparent',
  margin: '9px 0',
});

const menuPortal = (base): CSSProperties => ({
  ...base,
  zIndex: 100,
});

const getBackgroundColor = (isFocused, isSelected, useBoronStyles) => {
  if (isSelected && useBoronStyles) {
    return ActionPalette[400];
  }
  if (isFocused && useBoronStyles) {
    return ActionPalette[200];
  }
  if (isFocused) {
    return tempusPalette.tempus20;
  }
  return 'white';
};

export const ReactSingleSelectStyleOverrides: StylesConfig = {
  container,
  control,
  valueContainer,
  placeholder,
  clearIndicator,
  dropdownIndicator,
  indicatorSeparator,
  menuPortal,
  option: (base, props) => {
    const {
      isFocused,
      isSelected,
      selectProps: { useBoronStyles },
    } = props;
    return {
      ...base,
      alignItems: 'center',
      backgroundColor: getBackgroundColor(isFocused, isSelected, useBoronStyles),
      color: ActionPalette[600],
      fontWeight: isSelected && useBoronStyles ? 700 : 'inherit',
      cursor: 'pointer',
      display: 'flex',
      fontSize: '14px',
      lineHeight: '15px',
      justifyContent: 'space-between',
      '&:active': {
        backgroundColor: useBoronStyles ? ActionPalette[400] : 'inherit',
      },
    };
  },
};
