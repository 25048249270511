import { BaseUserState } from '@tempus/t-shared/ui';
import { OktaUser } from '@tempus/t-shared/ui/store/user/types';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';

export interface GetCurrentUserAction {
  type: typeof GET_CURRENT_USER;
}

export type EnhancedUserActionTypes = GetCurrentUserAction;

export type BaseUserStateWithFeatures = BaseUserState & {
  user: {
    features: string[];
  } & OktaUser;
};
