import { createUseStyles } from 'react-jss';

export const useCustomTableStyles = createUseStyles({
  table: {
    width: '100%',
  },
  td: {
    borderRight: 'none !important',
  },
  filter: {
    paddingTop: '10px',
  },
  filterPlaceholder: {
    height: '35px',
  },
});

export const useFilterHeaderStyles = createUseStyles({
  root: {
    '& div': {
      fontWeight: 400,
      letterSpacing: 0,
      textTransform: 'none',
    },
  },
});
