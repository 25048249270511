import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import React from 'react';

interface HeaderProps {
  headerTitle: string;
}
export const Header: React.FC<HeaderProps> = ({ headerTitle }) => {
  return (
    <div className="header">
      <span className={typography.header}>{headerTitle}</span>
    </div>
  );
};

export default Header;
