import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import React from 'react';

import useStyles from '../styles';
import { AddPatientPage } from '../types';

interface IneligibleMatchProps {
  navigateToPage: (page: AddPatientPage) => void;
}

const IneligibleMatch: React.FC<IneligibleMatchProps> = ({ navigateToPage }) => {
  const classes = useStyles();

  const onClose = () => navigateToPage(AddPatientPage.IDENTIFICATION_PAGE);

  return (
    <React.Fragment>
      <div className={`${classes.contentContainer} ${classes.screenedMatchContainer}`}>
        <span className={typography.sectionHeader}>Patient has already been screened for this trial</span>
        <div className={classes.screenedMatchContent}>
          A Tempus screener has determined this patient is ineligible. If you&lsquo;d like more information about this
          decision, reach out to your contact at Tempus.
        </div>
        <div className={classes.screenedMatchButtonWrapper}>
          <div className={classes.modalActions}>
            <Button
              small
              id="ineligible-match-close-button"
              className="secondary-button"
              buttonType="primary"
              ariaLabel="Close"
              onClick={onClose}
              data-pendo-id="patient-tracker-ineligible-match-close">
              Close
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IneligibleMatch;
