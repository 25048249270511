import { Navigation, NavigationTabArray, TabGenerationHelpers, changeImpersonation } from '@tempus/t-shared/ui';
import { sortBy } from 'lodash';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownOption } from 'tcl-v3/models';
import { Modal, SingleSelectCombobox } from 'tcl-v3/prefabs';

import { getPatientTrackerSiteUrl, getSiteDocumentsSiteUrl, Routes } from '~/routes';
import { RootState } from '~/store';
import { impersonatedIds } from '~/store/api/axios';
import { creators as siteCreators } from '~/store/site';

import { getPathForImpersonation } from './utils';

const TherapiesNavigation: React.FC = () => {
  const dispatch = useDispatch();

  const [selectedImpersonationSite, setSelectedImpersonationSite] = useState<DropdownOption | null>(null);

  const {
    user: {
      canImpersonate,
      canViewInternalDocuments,
      canViewSiteDocuments,
      canViewPatientTracking,
      loaded: userLoaded,
    },
  } = useSelector((state: RootState) => state);
  const timeSites = useSelector(({ site }: RootState) => site.timeSites);
  const userSites = useSelector(({ site }: RootState) => site.userSites);
  const { selectedUserSite, impersonationModalOpen } = useSelector(({ site }: RootState) => site);

  const currentlyImpersonatingSite = useMemo(() => {
    const id = impersonatedIds[0];

    if (!id) {
      return null;
    }

    const site = timeSites.find((site) => site.id === id);

    if (!site) {
      return {
        value: id,
        label: `[Site Not Found | ID = ${id}]`,
      };
    }

    return {
      value: site.id,
      label: site.shortName || site.name,
    };
  }, [timeSites.length]);

  useEffect(() => {
    if (userLoaded) {
      dispatch(siteCreators.getUserSites());
    }
  }, [userLoaded]);

  useEffect(() => {
    if (userLoaded && canImpersonate) {
      dispatch(siteCreators.getAllSites());
    }
  }, [userLoaded, canImpersonate]);

  useEffect(() => {
    setSelectedImpersonationSite(currentlyImpersonatingSite);
  }, [currentlyImpersonatingSite]);

  const tabsForLayout = ({ tabClasses, clickableTab }: TabGenerationHelpers) => {
    const tabs: NavigationTabArray = [
      {
        display: <span data-testid="topnav-trials">Trials</span>,
        className: tabClasses.uppercase,
        ...clickableTab(Routes.trials.path),
      },
    ];

    if (userLoaded && userSites !== null) {
      if (canViewInternalDocuments) {
        tabs.push({
          display: <span data-testid="topnav-sites">Sites</span>,
          className: tabClasses.uppercase,
          ...clickableTab(getSiteDocumentsSiteUrl(selectedUserSite?.id ?? '')),
        });
      }

      if (canViewPatientTracking) {
        tabs.push({
          display: <span data-testid="topnav-patient-tracker">Patient Tracker</span>,
          className: tabClasses.uppercase,
          ...clickableTab(getPatientTrackerSiteUrl(selectedUserSite?.id ?? '')),
        });
      }

      if (canViewSiteDocuments && !canViewInternalDocuments) {
        tabs.push({
          growBefore: true,
          display: <span data-testid="topnav-mysite">My site</span>,
          ...clickableTab(getSiteDocumentsSiteUrl(selectedUserSite?.id ?? null)),
        });
      }
    }

    return tabs;
  };

  const closeImpersonationModal = () => dispatch(siteCreators.setImpersonationModalOpen(false));

  const reloadWithImpersonation = (value: string) => {
    changeImpersonation(value, (v) => getPathForImpersonation(document.location.pathname, v));
  };

  const reloadWithImpersonationFromSelected = () => {
    reloadWithImpersonation(selectedImpersonationSite?.value || '');
  };

  const siteSelectProps: ComponentProps<typeof SingleSelectCombobox>['reactSelectProps'] = {
    menuPlacement: 'auto',
  };

  return (
    <React.Fragment>
      <Navigation
        tabs={tabsForLayout}
        exitImpersonation={() => reloadWithImpersonation('')}
        impersonateMenuItem={
          canImpersonate
            ? ['View as a TIME site', () => dispatch(siteCreators.setImpersonationModalOpen(true))]
            : undefined
        }
        impersonating={currentlyImpersonatingSite ? currentlyImpersonatingSite.label : undefined}
        enableHelpPopover={false}
      />

      {canImpersonate && (
        <Modal
          style={{
            overlay: {
              zIndex: 5,
            },
          }}
          size="medium"
          isOpen={impersonationModalOpen}
          onClickCancel={closeImpersonationModal}
          title="View Therapies Portal as a specific site"
          onClickConfirm={reloadWithImpersonationFromSelected}>
          <SingleSelectCombobox
            clearable
            label="Site"
            reactSelectProps={siteSelectProps}
            value={selectedImpersonationSite}
            onChange={setSelectedImpersonationSite}
            options={sortBy(timeSites, (a) => a.shortName || a.name).map((site) => ({
              label: site.shortName || site.name,
              value: site.id,
            }))}
          />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default TherapiesNavigation;
