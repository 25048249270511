import { Button } from '@tcl-boron-prefabs/button';
import { SingleSelectTypeahead } from '@tcl-boron-prefabs/single-select-typeahead';
import { ValuesetCodes } from '@tempus/t-shared';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownOption } from 'tcl-v3/models';

import { TrialSearchInputFilterProps } from '~/components/TrialSearchInput/index';
import { RootState } from '~/store';
import { updatePatient } from '~/store/trialSearch/slice';

import { createUseFilterStyles, useSingleSelectStyles } from './styles';

enum DiagnosisDropdown {
  Disease = 'disease',
  DiseaseStage = 'diseaseStage',
}

export const DiagnosisFilter: React.FC<TrialSearchInputFilterProps> = (props) => {
  const dispatch = useDispatch();
  const classes = createUseFilterStyles();
  const selectClasses = useSingleSelectStyles();
  const { open, setSubtitle } = props;

  const diagnosisOptions = useSelector(
    ({ trialSearch: { dropdowns } }: RootState) => dropdowns[ValuesetCodes.CANCER_COHORT] || [],
  );
  const stageOptions = useSelector(({ trialSearch: { dropdowns } }: RootState) => dropdowns[ValuesetCodes.STAGE] || []);

  const diagnoses = useSelector((state: RootState) => state.trialSearch.patientInput.diagnoses);

  const [disableAddDiagnosisButton, setDisableAddDiagnosisButton] = useState(false);

  // what the user types in into the select
  const [diagnosisInputValues, setDiagnosisInputValues] = useState<string[]>([]);
  const [stageInputValues, setStageInputValues] = useState<string[]>([]);
  // the filtered list of options based on what was typed in
  const [diagnosisOptionsFiltered, setDiagnosisOptionsFiltered] = useState<DropdownOption[]>(diagnosisOptions);
  const [stageOptionsFiltered, setStageOptionsFiltered] = useState<DropdownOption[]>(stageOptions);

  const handleOnSelect = (type: DiagnosisDropdown, selectedItem: DropdownOption | null, index: number) => {
    if (!selectedItem) {
      return;
    }

    const updatedDiagnoses = cloneDeep(diagnoses);
    updatedDiagnoses[index][type] = selectedItem;
    dispatch(updatePatient({ diagnoses: updatedDiagnoses }));
  };

  const handleDiagnosisInputChange = async (value: string, index: number) => {
    const updatedValues = [...diagnosisInputValues];
    updatedValues[index] = value;
    setDiagnosisInputValues(updatedValues);

    setDiagnosisOptionsFiltered(
      value
        ? diagnosisOptions.filter(
            (entry) =>
              entry.label
                .toLowerCase()
                .replace(/[^\w]/g, '') // remove punctuation
                .indexOf(
                  value
                    .toLowerCase()
                    .replace(/[^\w]/g, '') // remove punctuation
                    .trim(),
                ) != -1,
          )
        : diagnosisOptions,
    );
  };

  const handleStageInputChange = async (value: string, index: number) => {
    const updatedValues = [...stageInputValues];
    updatedValues[index] = value;
    setStageInputValues(updatedValues);

    setStageOptionsFiltered(
      value
        ? stageOptions.filter((entry) => entry.label.toLowerCase().indexOf(value.toLowerCase().trim()) != -1)
        : stageOptions,
    );
  };

  const getSubtitle = () => {
    return diagnoses.length ? `(${diagnoses.length}) selected` : undefined;
  };

  // Remove empty diagnoses (add diagnosis entry without selecting disease or stage values) from the list of diagnoses when the filter is closed
  useEffect(() => {
    if (!open) {
      const nonEmptyDiagnoses = diagnoses.filter(
        (diagnosis) => diagnosis.disease?.label || diagnosis.diseaseStage?.label,
      );

      if (nonEmptyDiagnoses.length !== diagnoses.length) {
        dispatch(updatePatient({ diagnoses: nonEmptyDiagnoses }));
      }
    }
  }, [open]);

  useEffect(() => {
    setDisableAddDiagnosisButton(
      Boolean(diagnoses.length && diagnoses.some((diagnosis) => !diagnosis.disease && !diagnosis.diseaseStage)),
    );
    setSubtitle(getSubtitle());
  }, [diagnoses]);

  const handleAddDiagnosis = () => {
    dispatch(updatePatient({ diagnoses: [...diagnoses, { disease: null, diseaseStage: null }] }));
    setDiagnosisInputValues([...diagnosisInputValues, '']);
    setStageInputValues([...stageInputValues, '']);
  };

  const handleRemoveDiagnosis = (index: number) => {
    // remove diagnosis from the list of added diagnoses
    const updatedDiagnoses = [...diagnoses];
    updatedDiagnoses.splice(index, 1);
    dispatch(updatePatient({ diagnoses: updatedDiagnoses }));

    // remove disease dropdown values corresponding to the removed diagnosis
    const updatedDiagnosisInputValues = [...diagnosisInputValues];
    updatedDiagnosisInputValues.splice(index, 1);
    setDiagnosisInputValues(updatedDiagnosisInputValues);

    // remove diseaseStage dropdown values corresponding to the removed diagnosis
    const updatedStageInputValues = [...stageInputValues];
    updatedStageInputValues.splice(index, 1);
    setStageInputValues(updatedStageInputValues);
  };

  const renderDiagnosisDropdowns = () => {
    return diagnoses.map((_, index) => (
      <div key={index} className={classes.diagnosisContainer}>
        <SingleSelectTypeahead
          classes={selectClasses}
          placeholder="Enter..."
          label="Diagnosis"
          onChange={(selectedItem) => handleOnSelect(DiagnosisDropdown.Disease, selectedItem, index)}
          onInputValueChange={(value) => handleDiagnosisInputChange(value, index)}
          options={diagnosisOptionsFiltered}
          value={diagnoses[index].disease}
          inputValue={diagnosisInputValues[index]}
          escapeClippingArea={true}
          data-pendo-id="trial-search-diagnosis"
        />
        <SingleSelectTypeahead
          classes={selectClasses}
          placeholder="Enter..."
          label="Stage / Grade"
          onChange={(selectedItem) => handleOnSelect(DiagnosisDropdown.DiseaseStage, selectedItem, index)}
          onInputValueChange={(value) => handleStageInputChange(value, index)}
          options={stageOptionsFiltered}
          value={diagnoses[index].diseaseStage}
          inputValue={stageInputValues[index]}
          escapeClippingArea={true}
          data-pendo-id="trial-search-diagnosis-stage"
        />
        <Button
          className={classes.removeButton}
          buttonType="tertiary"
          small={true}
          onClick={() => handleRemoveDiagnosis(index)}
          ariaLabel="remove diagnosis">
          Remove
        </Button>
      </div>
    ));
  };

  if (!open) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.innerContainerTitle}>DIAGNOSIS</div>
        {renderDiagnosisDropdowns()}
        <Button
          buttonType="secondary"
          small={true}
          disabled={disableAddDiagnosisButton}
          onClick={handleAddDiagnosis}
          data-pendo-id={'trial-search-add-diagnosis'}
          ariaLabel={'add a diagnosis'}>
          Add diagnosis
        </Button>
      </div>
    </div>
  );
};
