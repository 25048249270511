import { createUseStyles } from 'react-jss';

export default createUseStyles({
  downloadMenu: {
    width: 'auto',
    marginLeft: 'auto',

    '& svg': {
      marginLeft: 10,
    },
  },
});
