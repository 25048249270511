import { greenPalette } from '@tempus/component-library/styles/colors';
import { createUseStyles } from 'react-jss';
import { grayPalette } from 'tcl-v3/colors';

export default createUseStyles({
  uploadButton: {
    display: 'flex',
    alignItems: 'center',

    '* + &': {
      marginTop: 40,
    },

    '& > svg': {
      marginRight: 5,
    },
  },
  activeDot: {
    fontSize: 24,
    color: greenPalette.greenLightest,
    '-webkit-text-stroke': `1px ${greenPalette.tempusGreen}`,

    '&.inactive': {
      color: grayPalette.gray10,
      '-webkit-text-stroke': `1px ${grayPalette.gray40}`,
    },
  },
});
