import { createUseStyles } from 'react-jss';
import { grayPalette } from 'tcl-v3/colors';

export default createUseStyles({
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px',
    borderBottom: `1px solid ${grayPalette.gray40}`,
  },
  ctgovContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    borderBottom: `1px solid ${grayPalette.gray40}`,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    gap: '5px',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(8px)',
    zIndex: 10,
    overflow: 'scroll',
  },
  ctgovText: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
});
