import { Button } from '@tcl-boron-prefabs/button';
import { Card } from '@tcl-boron-prefabs/card';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { ActivityType, TimePatientStatuses } from '@tempus/t-shared';
import {
  PATIENT_TRACKER_STATUS_CATEGORIES,
  PATIENT_TRACKER_STATUS_ROLLUP,
} from '@tempus/t-shared/src/constants/patient-tracker';
import cn from 'classnames';
import { get } from 'lodash';
import React, { ReactElement } from 'react';
import { ChevronDown, ChevronRight } from 'tcl-v3/icons';
import { useQueryParams, StringParam } from 'use-query-params';

import { PatientTrackingDetailsWithPatientMeta, TimePatient } from '~/store/patientTrackerV2/types';
import { formatDateForDisplay } from '~/utils/misc';

import NotesPreview from './NotesPreview';
import { PatientStatus } from './PatientStatus';
import UpcomingVisit from './UpcomingVisit';
import { useCardStyles, useStyles } from './styles';

export interface CardColumn<T> {
  header: string;
  accessor: keyof T;
  width?: number;
  customBody?: (row: T) => string | ReactElement;
}

interface PatientTrackerCardProps {
  title: string;
  status: TimePatientStatuses;
  timePatient: TimePatient;
  columns: CardColumn<PatientTrackingDetailsWithPatientMeta>[];
  displayedRowCount?: number;
  stateId: string;
  siteId: string;
  showSiteName: boolean;
}

export const PatientTrackerCard: React.FC<PatientTrackerCardProps> = ({
  title,
  status,
  columns,
  timePatient,
  displayedRowCount,
  showSiteName,
}) => {
  const {
    id: timePatientId,
    patientTrackingDetails,
    patient,
    activities,
    timePatientVisits,
    institution,
    responseRequired = false,
  } = timePatient;
  const latestNote = activities.filter((activity) => activity.type === ActivityType.Note).slice(-1)[0];
  const firstTrackingDetail = patientTrackingDetails[0] || {};

  const classes = useStyles();
  const [isCardOpen, setIsCardOpen] = React.useState(true);
  const [displayedRows, setDisplayedRows] = React.useState(patientTrackingDetails.slice(0, displayedRowCount || 1));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setQueryParams] = useQueryParams(
    {
      timePatientId: StringParam,
    },
    { includeAllParams: true },
  );
  const onUpdateHandler = () => {
    setQueryParams({ timePatientId });
  };
  const cardClasses = useCardStyles();
  const physician = patientTrackingDetails.find((ptd) => ptd.linkedTreatingMd)?.linkedTreatingMd || '';
  const siteName = institution.shortName || institution.name;

  return (
    <>
      <Card classes={cardClasses} className={cn(classes.card, typography.body)}>
        <>
          <div className={cn(classes.twoColumnContainer, classes.header)}>
            <div className={classes.patientNameContainer}>
              <span onClick={() => setIsCardOpen(!isCardOpen)}>
                <span className={classes.chevron}>{isCardOpen ? <ChevronDown /> : <ChevronRight />}</span>
                <span className={classes.title}>
                  <strong>{title}</strong>
                </span>
              </span>
              <PatientStatus status={status} />
            </div>
            <div className={classes.right}>
              <NotesPreview
                // TODO: make better types so we can distinguish the type of note and
                //    not have to use workarounds to the typing to get into the content field
                latestNoteActivity={get(latestNote, 'content.note', '')}
                latestNoteActivitySource={get(latestNote, 'content.source', '')}
                responseRequired={responseRequired}
              />
              {timePatientId && (
                <Button
                  className={classes.updateButton}
                  buttonType="secondary"
                  small
                  onClick={onUpdateHandler}
                  data-testid="update-button"
                  ariaLabel="Update">
                  Update
                </Button>
              )}
            </div>
          </div>
          <table className={classes.headerTable}>
            <tbody>
              <tr>
                <td className={classes.ptDetails}>
                  <div>{firstTrackingDetail?.patientCancerType || ''}</div>
                  <div>DOB {patient ? formatDateForDisplay(patient.dateOfBirth, true) : ''}</div>
                </td>
                <td className={classes.ptDetails}>
                  <div>{physician && `Dr. ${physician}`}</div>
                  {showSiteName && <div>{siteName}</div>}
                </td>
                <td className={classes.ptDetails}>
                  <UpcomingVisit
                    type={timePatientVisits[0]?.visitType || ''}
                    date={timePatientVisits[0]?.visitDate || ''}
                    doNotShowUpdateDue={
                      [
                        ...PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE],
                        ...PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED],
                      ].includes(firstTrackingDetail.status) || firstTrackingDetail.trial?.type === 'Observational'
                    }
                  />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          {isCardOpen && (
            <div className={classes.cardBody}>
              <table className={classes.table}>
                <thead>
                  <tr>
                    {columns.map((c) => (
                      <th key={c.header} className={classes.th} style={{ ...(c.width && { width: c.width }) }}>
                        {c.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {displayedRows.map((row, i) => (
                    <tr key={row.id || i}>
                      {columns.map((c) => (
                        <td key={c.header} className={classes.td}>
                          {c.customBody ? c.customBody(row) : row[c.accessor]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {patientTrackingDetails.length > displayedRows.length && (
                <div
                  className={cn(typography.link, classes.viewAll)}
                  onClick={() => setDisplayedRows(patientTrackingDetails)}>{`+ View ${patientTrackingDetails.length -
                  displayedRows.length} trial match`}</div>
              )}
            </div>
          )}
        </>
      </Card>
    </>
  );
};

export default PatientTrackerCard;
