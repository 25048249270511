import { Routes, getPatientTrackerSiteUrl, getSiteDocumentsSiteUrl } from '~/routes';

const getSubdirectory = (path): string => path.split('/')[1];

// Since external users cannot access /sites or /patient-tracker, redirect them to the site-specific page
export const getPathForImpersonation = (pathname: string, siteId: string): string => {
  switch (getSubdirectory(pathname)) {
    case getSubdirectory(Routes.patientTracker.path):
      return getPatientTrackerSiteUrl(siteId);
    case getSubdirectory(Routes.sitePatientTracker!.path):
      return getPatientTrackerSiteUrl(siteId);
    case getSubdirectory(Routes.siteDocuments.path):
      return getSiteDocumentsSiteUrl(siteId);
    default:
      return pathname;
  }
};
