import { DocumentPatchInput } from '@tempus/t-shared';
import { RightSideOverlay, storeActions } from '@tempus/t-shared/ui';
import { map } from 'lodash';
import pEachSeries from 'p-each-series';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trash } from 'tcl-v3/icons';
import { Loader, Modal, Overlay, Tab, TabGroup } from 'tcl-v3/prefabs';
import typography from 'tcl-v3/styles/typography.module.scss';

import useStyles from '~/components/overlayStyles';
import { RootState } from '~/store';
import api from '~/store/api';
import { creators as documentEditCreators } from '~/store/documentEdit';

import DocumentDetails from './DocumentDetails';
import ReviewDetails from './ReviewDetails';
import VersionDetails from './VersionDetails';

type OverlayProps = React.ComponentProps<typeof Overlay>;
type DetailsOverlayProps = Omit<OverlayProps, 'isOpen' | 'onRequestClose' | 'classes'>;

enum TabType {
  VERSION = 'version',
  DOCUMENT = 'document',
  REVIEW = 'review',
}

const CLOSE = 'close';

const DetailsOverlay: React.FC<DetailsOverlayProps> = (props) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const [persisting, setPersisting] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>(TabType.VERSION);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showWarningConfirmation, setShowWarningConfirmation] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [pendingAction, setPendingAction] = useState<string | null>(null);
  const {
    user: { canWriteDocuments, canViewInternalDocuments },
    documentEdit: { editingVersionId },
    document: { documents, classificationId, classification },
  } = useSelector((state: RootState) => state);

  const selectedDocumentDetails = useMemo(() => {
    if (!editingVersionId || !documents || !classification || !classificationId) {
      // Depending on order of execution of the actions in redux,
      //   there is a chance that `editingVersionId` is still set
      //   even though the document was removed. In this case, we
      //   return {} as well.
      return {};
    }

    const classificationDocuments = documents[classification][classificationId];

    if (!classificationDocuments) {
      return {};
    }

    const document = classificationDocuments.find((document) =>
      map(document.versions, 'id').includes(editingVersionId),
    );

    if (!document) {
      return {};
    }

    const version = document.versions.filter((version) => version.id === editingVersionId)[0];

    return { document, version };
  }, [editingVersionId, documents, classification, classificationId]);

  const isOpen = Boolean(editingVersionId);
  const classes = useStyles({ canWriteDocuments });

  const closeDeleteConfirmation = () => setShowDeleteConfirmation(false);
  const closeWarningConfirmation = () => setShowWarningConfirmation(false);

  const isReviewStatusSet = () => {
    const { version } = selectedDocumentDetails;
    return version && version.reviewStatus !== null;
  };

  const confirm = () => {
    if (pendingAction === CLOSE) {
      dispatch(documentEditCreators.setEditingVersionId(''));
    } else if (pendingAction) {
      // there has to be a pending action but TS requires this check
      setSelectedTab(pendingAction);
    }
    setPendingAction(null);
    setUnsavedChanges(false);
    closeWarningConfirmation();
  };

  const closeDetailsOverlay = () => {
    if (unsavedChanges) {
      setPendingAction(CLOSE);
      setShowWarningConfirmation(true);
    } else {
      dispatch(documentEditCreators.setEditingVersionId(''));
    }
  };

  const changeTab = (newTab: string) => {
    if (unsavedChanges) {
      setPendingAction(newTab);
      setShowWarningConfirmation(true);
    } else {
      setSelectedTab(newTab);
    }
  };

  useEffect(() => {
    closeDeleteConfirmation();
    setSelectedTab(TabType.VERSION);
  }, [editingVersionId]);

  const renderContentRequiringVersion = () => {
    const { document, version } = selectedDocumentDetails;

    if (!document || !version) {
      return null;
    }

    const persistChanges = async (updates: DocumentPatchInput) => {
      setPersisting(true);

      const result = await api.documents.patchVersion(version.id, updates);

      if (result) {
        dispatch(documentEditCreators.updateDocument(result));
      } else {
        dispatch(storeActions.notification.showErrorMessage('Error updating document.'));
      }

      setPersisting(false);

      return Boolean(result);
    };

    const deleteSingleVersion = async (versionId: string) => {
      const result = await api.documents.deleteVersion(versionId);

      if (result) {
        dispatch(documentEditCreators.deleteVersion(document.id, versionId));
      }

      return result;
    };

    const deleteDocument = async () => {
      const versionIds = map(document.versions, 'id');

      // Move the version being editted to last so the overlay
      //   will not be closed before the entire task is complete.
      versionIds.push(versionIds.splice(versionIds.indexOf(editingVersionId), 1)[0]);

      const results = await pEachSeries(versionIds, deleteSingleVersion);
      return results.every((r) => r);
    };

    const performDelete = async () => {
      setDeleting(true);

      const action = selectedTab === TabType.VERSION ? deleteSingleVersion(editingVersionId) : deleteDocument();
      const result = await action;

      if (result) {
        setShowDeleteConfirmation(false);
      } else {
        dispatch(storeActions.notification.showErrorMessage(`Failed to delete ${selectedTab}. Please try again.`));
      }

      setDeleting(false);
    };

    const renderDetails = () => {
      switch (selectedTab) {
        case TabType.VERSION:
          return (
            <VersionDetails
              document={document}
              version={version}
              persistChanges={persistChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          );
        case TabType.DOCUMENT:
          return (
            <DocumentDetails
              document={document}
              version={version}
              persistChanges={persistChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          );
        case TabType.REVIEW:
          return (
            <ReviewDetails
              document={document}
              version={version}
              persistChanges={persistChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          );
        default:
          return null;
      }
    };

    return (
      <React.Fragment>
        <div className={classes.tabContents}>{renderDetails()}</div>

        <Modal
          size="small"
          title="Are you sure?"
          onClickConfirm={performDelete}
          isOpen={showDeleteConfirmation}
          onClickCancel={closeDeleteConfirmation}
          onRequestClose={closeDeleteConfirmation}>
          {deleting ? (
            <Loader />
          ) : (
            <span className={typography.body}>The {selectedTab} will be deleted and this action cannot be undone.</span>
          )}
        </Modal>

        <Modal
          size="small"
          title="Are you sure?"
          onClickConfirm={confirm}
          isOpen={showWarningConfirmation}
          onClickCancel={closeWarningConfirmation}
          onRequestClose={closeWarningConfirmation}>
          You have unsaved changes that will be lost if you decide to continue. Click cancel and address highlighted
          entries to save.
        </Modal>
      </React.Fragment>
    );
  };

  const renderDeleteButton = () => {
    if (!canWriteDocuments) {
      return null;
    }

    if (selectedTab === TabType.REVIEW) {
      return null;
    }

    return (
      <div>
        {/* In a div so the button doesn't expand to the whole width of the overlay. */}

        <button
          onClick={() => setShowDeleteConfirmation(true)}
          data-pendo-id={`document-details-delete-${selectedTab}`}
          className={`${classes.deleteButton} ${typography.supportingBody} ${typography.gray}`}>
          <Trash /> Delete {selectedTab}
        </button>
      </div>
    );
  };

  return (
    <RightSideOverlay {...props} ariaHideApp={false} isOpen={isOpen} onRequestClose={closeDetailsOverlay}>
      <div className={`${typography.sectionHeader} ${classes.title}`}>
        Details
        {persisting && <Loader scale={0.5} />}
      </div>

      <TabGroup className={classes.tabs} value={selectedTab} onChange={changeTab}>
        <Tab className={classes.tab} value={TabType.VERSION} data-pendo-id="document-details-tab-version">
          Version
        </Tab>
        <Tab className={classes.tab} value={TabType.DOCUMENT} data-pendo-id="document-details-tab-document">
          Document
        </Tab>
        {canViewInternalDocuments && isReviewStatusSet() ? (
          <Tab className={classes.tab} value={TabType.REVIEW} data-pendo-id="document-details-tab-review">
            Review
          </Tab>
        ) : null}
      </TabGroup>

      {renderContentRequiringVersion()}
      {renderDeleteButton()}
    </RightSideOverlay>
  );
};

export default DetailsOverlay;
