import { MultiSelectTypeahead } from '@tcl-boron-prefabs/multi-select-typeahead';
import { Slider } from '@tcl-boron-prefabs/slider';
import { Tag } from '@tcl-boron-prefabs/tag';
import { Toggle } from '@tcl-boron-prefabs/toggle';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownOption } from 'tcl-v3/models';

import { TrialSearchInputFilterProps } from '~/components/TrialSearchInput/index';
import { RootState } from '~/store';
import Api from '~/store/api';
import { updatePatient } from '~/store/trialSearch/slice';
import { SelectOption } from '~/store/trialSearch/state';

import { BiomarkerSelectOption, formatBiomarkerOptions } from './biomarkers.utils';
import { createUseFilterStyles, useMultiSelectStyles } from './styles';

export const BIOMARKER = 'biomarker';

export const BiomarkerFilter: React.FC<TrialSearchInputFilterProps> = (props) => {
  const dispatch = useDispatch();
  const { open, setSubtitle } = props;
  const classes = createUseFilterStyles();
  const multiSelectclasses = useMultiSelectStyles();

  const selectedVariants = useSelector((state: RootState) => state.trialSearch.patientInput.variantOptions);
  const selectedTmb = useSelector((state: RootState) => state.trialSearch.patientInput.tmbResults);
  const initialOptions = useSelector(({ trialSearch: { dropdowns } }: RootState) => dropdowns[BIOMARKER] || []);

  const [variantsLoading, setVariantsLoading] = useState(false);
  const [variantOptions, setVariantOptions] = useState<SelectOption[]>([]);
  const [variantsInputValue, setVariantsInputValue] = useState('');

  const [isTmbCheckboxSelected, setIsTmbCheckboxSelected] = useState(selectedTmb !== null);
  const onTmbCheckboxChange = () => {
    if (isTmbCheckboxSelected && selectedTmb !== null) {
      // Clear out tmb state when unchecking tmb
      handleTmbSelect(null);
    }
    setIsTmbCheckboxSelected(!isTmbCheckboxSelected);
  };

  const getSubtitle = () => {
    const subtitleParts: string[] = [];
    if (selectedVariants.length) {
      subtitleParts.push(`(${selectedVariants.length}) selected`);
    }
    if (selectedTmb) {
      subtitleParts.push(`TMB ${selectedTmb}`);
    }
    return subtitleParts.length === 1 ? subtitleParts[0] : subtitleParts.join(', ');
  };

  const handleTmbSelect = (updatedTmb) => {
    dispatch(
      updatePatient({
        tmbResults: updatedTmb,
      }),
    );
  };

  const handleVariantsUpdate = (opts, areUpdatedFromDropdown = false) => {
    /* When a biomarker is (de)selected, TrialSearchBar is rerendered and the logic to set focus on the open 
     filters popover is run. This causes the biomarker dropdown to close as the focus is actively set to the 
     popover. To prevent this, we set this flag here whenever a biomarker is (de)selected. This flag is 
     checked before updating focus and focus is not updated if a biomarker selection has been made. This is 
     then reset once the focus update has been thwarted. The focus logic is in ../../TrialSearchBar/index.tsx. 
     We want this flag to be updated only when the biomarker update is from the dropdown, not when clicking on
     the 'x' button on a selected biomarker tag to remove it, in which case we do need to update the focus, 
     hence the optional parameter to this method. */
    window.areBiomarkersUpdatedForTrialFilters = areUpdatedFromDropdown;

    dispatch(
      updatePatient({
        variantOptions: (opts as BiomarkerSelectOption[]).sort((a, b) => a.label.localeCompare(b.label)),
      }),
    );
  };

  const handleVariantsInput = (e) => {
    setVariantsInputValue(e);
    setVariantsLoading(e ? true : false);
  };

  const debounceVariantsChange = async (e) => {
    const options = e ? await Api.dropdowns.getBiomarkers(e).then(formatBiomarkerOptions) : [];
    setVariantOptions(options);
    setVariantsLoading(false);
  };

  useEffect(() => {
    if (!selectedVariants.length && variantsInputValue.length) {
      setVariantsInputValue('');
    }
  }, [selectedVariants]);

  useEffect(() => {
    if (isTmbCheckboxSelected && selectedTmb === null) {
      setIsTmbCheckboxSelected(false);
    }
  }, [selectedTmb]);

  useEffect(() => {
    setSubtitle(getSubtitle());
  }, [selectedVariants, selectedTmb]);

  if (!open) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.innerContainerTitle}>Tumor Mutational Burden</div>
        <Toggle
          label="TMB filter"
          labelPlacement="left"
          onChange={onTmbCheckboxChange}
          isSelected={isTmbCheckboxSelected}
          className={classes.toggleLabel}
          data-pendo-id="trial-search-biomarkers-tumor-mutational-burden"
        />
        {isTmbCheckboxSelected && (
          <div className={classes.tmbSlider}>
            <Slider
              min={0}
              max={30}
              step={1}
              value={selectedTmb || 0}
              minValLabel={0}
              maxValLabel={'30+'}
              debounceTimeOverride={100}
              onDebounceChange={handleTmbSelect}
              tooltipFormatter={(value) => value.toString()}
            />
          </div>
        )}
      </div>
      <div className={classes.innerContainer}>
        <div className={classes.innerContainerTitle}>DNA Biomarkers</div>
        <div>
          <MultiSelectTypeahead
            classes={multiSelectclasses}
            placeholder="Search"
            label=""
            hideLabel={true}
            onChange={(opts: DropdownOption[]) => handleVariantsUpdate(opts, true)}
            options={variantOptions}
            defaultOptions={initialOptions}
            value={selectedVariants}
            isLoading={variantsLoading}
            inputValue={variantsInputValue}
            onInputValueChange={handleVariantsInput}
            onDebounceChange={debounceVariantsChange}
            escapeClippingArea={true}
            data-pendo-id="trial-search-biomarkers-variants"
          />
        </div>
        <div className={cn(typography.link)} onClick={() => handleVariantsUpdate([])}>
          Clear biomarkers
        </div>
        {selectedVariants.length > 0 && (
          <div>
            {selectedVariants.map((variant, i) => (
              <div key={`biomarker-tag-${i}`} className={classes.biomarkerTag}>
                <Tag
                  onDelete={() => {
                    handleVariantsUpdate(selectedVariants.filter((variantI) => variantI !== variant));
                  }}
                  text={variant.label}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
