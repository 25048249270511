import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export const useTabGroupStyles = createUseStyles({
  tabGroup: {
    '& $tab:first-child': {
      marginLeft: 0,
    },
  },
  tab: {
    padding: '4px 0 !important',
  },
  marginBelow: {
    marginBottom: 60,
  },
});

export const useClasses = createUseStyles({
  root: {
    borderBottom: `1px solid ${GrayscalePalette[300]}`,
    paddingBottom: '0px',
  },
});
