import { BrandPalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export const useLayoutStyles = createUseStyles({
  root: {
    boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0), 0 3px 3px 0 rgba(224, 224, 224)',
    zIndex: '4',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '30%',
  },
});

export default createUseStyles({
  comboBoxWrapper: {
    textTransform: 'inherit',
  },
  navy: {
    color: BrandPalette[700],
  },

  dropdownTab: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginLeft: 4,
    },
  },
});
