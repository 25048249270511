import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { Activity, MatchStatusUpdates } from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUSES } from '@tempus/t-shared/src/constants/patient-tracker';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { getTrialDisplay } from '~/utils/trial';

import useStyles from './ActivityStatus.styles';

export const MatchStatusUpdateActivity = ({
  activity,
  isInternalUser,
  stateId,
}: {
  activity: Activity;
  isInternalUser: boolean;
  stateId: string;
}) => {
  const classes = useStyles();

  const ptds = useSelector(
    ({ patientTrackerV2 }: RootState) => patientTrackerV2[stateId].patient.data?.patientTrackingDetails,
  );

  let shouldDisplay = true;
  if ('newStatus' in activity.content) {
    const isInternalReview = activity.content.newStatus === PATIENT_TRACKER_STATUSES.INTERNAL_REVIEW;
    shouldDisplay = !isInternalReview ? true : isInternalReview && isInternalUser;
  }

  return (
    <>
      {shouldDisplay && (
        <li key={activity.id} className={cn(typography.body, classes.li)}>
          <div className={classes.listItemContainer}>
            <div className={classes.updatesLeft}>
              <div>
                Patient Updated to <em>{(activity.content as MatchStatusUpdates).newStatus}</em> for{' '}
                <strong>{getTrialDisplay((activity.content as MatchStatusUpdates).trialId, ptds || [])}</strong>
              </div>
              {(activity.content as MatchStatusUpdates).newStatus ===
                PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE && (
                <>
                  <div>Reason: {(activity.content as MatchStatusUpdates).reasonNotAMatch}</div>
                  {(activity.content as MatchStatusUpdates).reasonNotAMatchDetails && (
                    <div>Additional details: {(activity.content as MatchStatusUpdates).reasonNotAMatchDetails}</div>
                  )}
                </>
              )}
            </div>
            <div>{activity.createdAt}</div>
          </div>
        </li>
      )}
    </>
  );
};
