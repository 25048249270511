import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  center: {
    position: 'relative',
  },
  noResultComponent: {
    marginTop: '100px',
  },
  tabGroup: {
    height: '4px',
    borderBottom: `1px solid ${GrayscalePalette[400]}`,
  },
});
