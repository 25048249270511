import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { DocumentConstants, DocumentVersionView, DocumentView } from '@tempus/t-shared';
import {
  DocumentListContext,
  DocumentListRenderer,
  createDocumentListContext,
  DocumentVersionReviewStatusDisplay,
} from '@tempus/t-shared/ui';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { grayPalette } from 'tcl-v3/colors';
import { AddSimple, Information, Shared } from 'tcl-v3/icons';

import { AddDocuments } from '~/components/AddDocuments';
import { RootState } from '~/store';
import { TRIALS_API_ROOT } from '~/store/api';
import client from '~/store/api/axios';
import { creators as documentEditCreators } from '~/store/documentEdit';
import { creators as trialCreators } from '~/store/trial/actions';

import { DocumentListPage } from '../DocumentListPage';
import DetailsOverlay from './DetailsOverlay';
import useStyles from './styles';

interface TherapiesDocumentListPageProps {
  header?: ReactNode;
  classificationId: string;
  classification: DocumentConstants.Classification;
  queryParams?: Record<string, unknown>;
}

const TherapiesDocumentListPage: React.FC<TherapiesDocumentListPageProps> = ({
  classification,
  classificationId,
  header,
  queryParams,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showFileUploader, setShowFileUploader] = useState(false);

  const trials = useSelector(({ trial }: RootState) => trial.allTrials);
  const userSites = useSelector(({ site }: RootState) => site.userSites);
  const canViewInternalDocuments = useSelector(
    ({ user: { canViewInternalDocuments } }: RootState) => canViewInternalDocuments,
  );
  const editingVersionId = useSelector(({ documentEdit }: RootState) => documentEdit.editingVersionId);
  const canWriteDocuments = useSelector(({ user: { canWriteDocuments } }: RootState) => canWriteDocuments);

  const effectiveSites = userSites || {};

  useEffect(() => {
    if (canWriteDocuments) {
      dispatch(trialCreators.getTrialOptions());
    }
  }, [canWriteDocuments]);

  useEffect(() => {
    dispatch(documentEditCreators.setEditingVersionId(''));
  }, [classification, classificationId]);

  const renderUploadButton = useCallback(() => {
    if (!canWriteDocuments) {
      return null;
    }

    return (
      <React.Fragment>
        <button
          onClick={() => setShowFileUploader(true)}
          data-pendo-id={`upload-${classification}-document`}
          className={`${typography.link} ${classes.uploadButton}`}>
          <AddSimple /> Upload
        </button>

        <AddDocuments
          showFileUploader={showFileUploader}
          hideFileUploader={() => setShowFileUploader(false)}
          providedId={classificationId}
          classification={classification}
        />
      </React.Fragment>
    );
  }, [canWriteDocuments, classification, classificationId, showFileUploader]);

  const renderVersionActions = useCallback(
    (version: DocumentVersionView) => {
      if (!canViewInternalDocuments) {
        return null;
      }

      const edit = () => dispatch(documentEditCreators.setEditingVersionId(version.id));

      return (
        <>
          <DocumentVersionReviewStatusDisplay status={version.reviewStatus} />
          <button onClick={edit} data-pendo-id="document-version-details">
            <Information color={grayPalette.gray60} />
          </button>
        </>
      );
    },
    [canViewInternalDocuments],
  );

  const getSiteDisplayName = useCallback(
    (id: string | null): string | undefined => {
      const site = effectiveSites[id || ''];

      if (!site) {
        return;
      }

      return site.shortName || site.name;
    },
    [effectiveSites],
  );

  const renderVersionDetailsLine = useCallback(
    (document: DocumentView, version: DocumentVersionView, latest: boolean) => (
      renderCommonDetails: DocumentListRenderer,
    ) => {
      const renderActiveDot = () => {
        if (!canViewInternalDocuments) {
          return null;
        }

        const classNames = [classes.activeDot];

        if (!version.active) {
          classNames.push('inactive');
        }

        return <span className={classNames.join(' ')}>&bull;</span>;
      };

      const renderExternalIcon = () => {
        if (!canViewInternalDocuments || version.internal) {
          return null;
        }

        return <Shared color={grayPalette.gray45} />;
      };

      const renderInstitutionOrTrial = () => {
        if (!canViewInternalDocuments || !latest) {
          return null;
        } else if (document.institutionId && document.classification === DocumentConstants.Classification.Trial) {
          const name = getSiteDisplayName(document.institutionId) || '[Unknown Site]';

          return <strong>{name}</strong>;
        } else if (document.trialId && document.classification === DocumentConstants.Classification.Site) {
          const {
            trial: { nctId, shortName },
          } = document;

          return <strong>{shortName || nctId}</strong>;
        }

        return null;
      };

      return (
        <>
          {renderActiveDot()}
          {renderCommonDetails()}
          {renderExternalIcon()}
          {renderInstitutionOrTrial()}
        </>
      );
    },
    [canViewInternalDocuments, getSiteDisplayName],
  );

  return (
    <DocumentListContext.Provider
      value={createDocumentListContext(
        {
          getSiteDisplayName,
          highlightedVersionId: editingVersionId,
          showUncategorizedAsOther: !canWriteDocuments,
          getTrialDisplayName: (id) => {
            const trial = trials.find((t) => t.id === id);

            if (!trial) {
              return;
            }

            return trial.shortName || trial.nctId;
          },
          renderers: {
            uploadButton: renderUploadButton,
            versionActions: renderVersionActions,
            versionDetailsLine: renderVersionDetailsLine,
          },
        },
        {
          trialsApiRoot: TRIALS_API_ROOT,
          axiosWithImpersonation: client,
        },
      )}>
      <DocumentListPage
        queryParams={queryParams}
        header={header}
        classification={classification}
        classificationId={classificationId}
        showReviewStatusFilter={canViewInternalDocuments}>
        <DetailsOverlay />
      </DocumentListPage>
    </DocumentListContext.Provider>
  );
};

export default TherapiesDocumentListPage;
