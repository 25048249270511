import { isEqual } from 'lodash';
import { Reducer } from 'redux';

import {
  DocumentEditActionTypes,
  DocumentEditState,
  SET_EDITING_VERSION_ID,
  GET_TAGS,
  GET_TAG,
  SET_TAGS,
  SET_FAILED_UPLOADS,
  DocumentFile,
  SET_DOCUMENT_UPLOADS,
  UPDATE_DOCUMENT_UPLOAD,
  DELETE_DOCUMENT_UPLOAD,
  UPDATE_DOCUMENT_EDIT_STATE,
} from './types';

const defaultState: DocumentEditState = {
  editingVersionId: '',
  tags: {},
  loadingTags: false,
  failedUploads: [],
  uploads: [],
};

const reducer: Reducer<DocumentEditState, DocumentEditActionTypes> = (state = defaultState, action) => {
  switch (action.type) {
    case SET_EDITING_VERSION_ID:
      return { ...state, editingVersionId: action.id };

    case GET_TAG:
    case GET_TAGS:
      return { ...state, loadingTags: true };

    case SET_TAGS: {
      const { tags, appendTags } = action;
      const newTags = appendTags ? { ...state.tags, ...tags } : tags;
      return { ...state, tags: newTags, loadingTags: false };
    }

    case SET_FAILED_UPLOADS:
      const { document } = action;
      let newUploads: DocumentFile[] = [];
      if (document) {
        const { failedUploads } = state;
        newUploads = [...failedUploads, document];
      }
      return {
        ...state,
        failedUploads: newUploads,
      };

    case SET_DOCUMENT_UPLOADS: {
      const { documents: uploads } = action;
      return { ...state, uploads };
    }

    case UPDATE_DOCUMENT_UPLOAD: {
      const { uploads } = state;
      const { documentId, document } = action;

      const updatedUploads = [...uploads];
      const index = updatedUploads.findIndex((file) => file.document.id === documentId);
      if (index !== -1) {
        const currentUpload = updatedUploads[index];

        document.changeCount += 1;

        if (!isEqual(currentUpload.document.tags, document.document.tags)) {
          document.tagChangeCount += 1;
        }

        updatedUploads[index] = document;
      }

      return { ...state, uploads: updatedUploads };
    }

    case DELETE_DOCUMENT_UPLOAD: {
      const { uploads } = state;
      const { documentId } = action;
      return { ...state, uploads: uploads.filter((u) => u.document.id !== documentId) };
    }

    case UPDATE_DOCUMENT_EDIT_STATE: {
      return { ...state, ...action.state };
    }

    default:
      return state;
  }
};

export default reducer;
