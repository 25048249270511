import { ActionPalette } from '@tcl-boron-colors/colors';
import { NOT_AVAILABLE_TIME_SITE_STATUSES, PROGRAM_TYPES_DEC } from '@tempus/t-shared';
import React from 'react';
import { useSelector } from 'react-redux';
import { CellProps } from 'react-table';

import { RootState } from '~/store';
import { Trial } from '~/store/trial/types';

const shortNameStyle: React.CSSProperties = {
  fontWeight: 'bold',
};

const lastUpdatedStyle: React.CSSProperties = {
  color: ActionPalette[500],
};

export const TrialCell = ({ row: { original } }: CellProps<Trial>): React.ReactNode => {
  const { updatedAt, title, shortName, cohorts, status } = original;
  const {
    user: { canImpersonate },
  } = useSelector((state: RootState) => state);
  const date = new Date(updatedAt);
  const isUnavailableTrial = NOT_AVAILABLE_TIME_SITE_STATUSES.includes(status) && !canImpersonate;

  const isStTrial = cohorts.every((c) => c.programType === PROGRAM_TYPES_DEC.ST);
  const showLastUpdated = !isUnavailableTrial && !isStTrial;

  return (
    <div>
      {shortName && <span style={shortNameStyle}>{shortName}</span>}
      <p>{title}</p>
      {showLastUpdated && <p style={lastUpdatedStyle}>Last updated: {date.toLocaleDateString()}</p>}
    </div>
  );
};
