import { Button } from '@tcl-boron-prefabs/button';
import { NotAvailable } from '@tempus/t-shared/ui';
import React from 'react';
import { useDispatch } from 'react-redux';

import { creators as siteCreators } from '~/store/site/actions';

import NoAccessSvg from './NoAccessSvg';
import { useStyles } from './styles';

interface InternalUsersImpersonateCtaProps {}

export const InternalUsersImpersonateCta: React.FC<InternalUsersImpersonateCtaProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.wrapper}>
      <NotAvailable
        title="Internal users cannot access this view"
        Image={NoAccessSvg}
        description="Use the impersonation feature or filter the patient tracker for this trial"
      />
      <Button
        buttonType="secondary"
        className={classes.impersonateButton}
        onClick={() => dispatch(siteCreators.setImpersonationModalOpen(true))}
        small
        ariaLabel="Impersonate">
        Impersonate
      </Button>
    </div>
  );
};

export default InternalUsersImpersonateCta;
