import { Cell } from 'react-table';

export { TrialCell } from './TrialCell';
import { Trial } from '~/store/trial/types';

export const getCellId = (cell: Cell<Trial>): string => {
  const { column } = cell;

  if (typeof column.Header === 'string') {
    return column.Header;
  }

  return column.id || 'unknown';
};
