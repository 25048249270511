import { DocumentVersionView, DocumentView } from '@tempus/t-shared';

export interface DocumentEditState {
  failedUploads: DocumentFile[];
  uploads: DocumentFile[];

  // When set, the edit overlay is shown for the
  //   version.
  editingVersionId: string;

  // A lookup of available tags and their
  //   values. When the lookup value is false,
  //   the values for that tag have not been
  //   retrieved yet.
  tags: Record<string, string[] | false>;
  loadingTags: boolean;
}

export const GET_TAG = 'GET_TAG';
export const GET_TAGS = 'GET_TAGS';
export const SET_TAGS = 'SET_TAGS';
export const DELETE_VERSION = 'DELETE_VERSION';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const SET_EDITING_VERSION_ID = 'SET_EDITING_VERSION_ID';
export const SET_FAILED_UPLOADS = 'SET_FAILED_UPLOADS';
export const SET_DOCUMENT_UPLOADS = 'SET_DOCUMENT_UPLOADS';
export const UPDATE_DOCUMENT_UPLOAD = 'UPDATE_DOCUMENT_UPLOAD';
export const DELETE_DOCUMENT_UPLOAD = 'DELETE_DOCUMENT_UPLOAD';
export const UPDATE_DOCUMENT_EDIT_STATE = 'UPDATE_DOCUMENT_EDIT_STATE';

export interface DocumentFile {
  version: DocumentFileVersion;
  document: DocumentWithoutExtras;
  file: File;
  linkedDocument: DocumentView | null;
  tagChangeCount: number;
  changeCount: number;
}

export type DocumentFileVersion = Omit<DocumentVersionView, 'name' | 'createdAt' | 'updatedAt'> & {
  documentId: string;
};
export type DocumentWithoutExtras = Omit<DocumentView, 'trial'>;

interface SetEditingVersionIdAction {
  type: typeof SET_EDITING_VERSION_ID;
  id: string;
}
export interface UpdateDocumentAction {
  type: typeof UPDATE_DOCUMENT;
  document: DocumentView;
}
interface SetFailedUploads {
  type: typeof SET_FAILED_UPLOADS;
  document: DocumentFile | null;
}
export interface GetTagAction {
  type: typeof GET_TAG;
  key: string;
}
export interface GetTagsAction {
  type: typeof GET_TAGS;
}
interface SetTagsAction {
  type: typeof SET_TAGS;
  tags: DocumentEditState['tags'];
  appendTags?: boolean;
}
export interface DeleteVersionAction {
  type: typeof DELETE_VERSION;
  documentId: string;
  versionId: string;
}
interface SetDocumentUploads {
  type: typeof SET_DOCUMENT_UPLOADS;
  documents: DocumentFile[];
}
interface UpdateDocumentUpload {
  type: typeof UPDATE_DOCUMENT_UPLOAD;
  documentId: string;
  document: DocumentFile;
}
interface DeleteDocumentUpload {
  type: typeof DELETE_DOCUMENT_UPLOAD;
  documentId: string;
}
interface UpdateDocumentEditState {
  type: typeof UPDATE_DOCUMENT_EDIT_STATE;
  state: Partial<DocumentEditState>;
}

export type DocumentEditActionTypes =
  | SetEditingVersionIdAction
  | UpdateDocumentAction
  | GetTagAction
  | GetTagsAction
  | SetTagsAction
  | SetFailedUploads
  | DeleteVersionAction
  | SetDocumentUploads
  | UpdateDocumentUpload
  | DeleteDocumentUpload
  | UpdateDocumentEditState;
