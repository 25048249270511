import { Card } from '@tcl-boron-prefabs/card';
import moment from 'moment';
import React from 'react';
import { Information } from 'tcl-v3/icons';

import { VisitType } from '~/store/patientTrackerV2/constants';
import { isDatePast } from '~/store/patientTrackerV2/helpers';

import useStyles from './VisitDatePastBanner.styles';

interface VisitDatePastBannerProps {
  nextVisit?: { visitDate: string; visitType: VisitType };
}

export const VisitDatePastBanner: React.FC<VisitDatePastBannerProps> = ({ nextVisit }) => {
  const classes = useStyles();

  if (!nextVisit || !nextVisit.visitDate || !isDatePast(nextVisit.visitDate)) {
    return null;
  }

  const visitTypeText =
    Object.values(VisitType).includes(nextVisit.visitType) && nextVisit.visitType !== VisitType.OTHER
      ? nextVisit.visitType
      : 'Visit';

  const noOfDaysPast = moment().diff(moment(nextVisit.visitDate), 'days');

  return (
    <Card className={classes.scanReminderCard}>
      <div className={classes.cardContent}>
        <div className={classes.icon}>
          <Information />
        </div>
        <div>
          This patient had a{' '}
          <strong>
            {visitTypeText} {noOfDaysPast} day{noOfDaysPast > 1 ? 's' : ''} ago.
          </strong>{' '}
          Please review patient status and matches.
        </div>
      </div>
    </Card>
  );
};

export default VisitDatePastBanner;
