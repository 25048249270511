import { AppWrapper, AuthorizationWrapperProps } from '@tempus/t-shared/ui';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import '@tcl-boron-fonts/ibm-plex-sans/dist/ibm-plex-sans.css';

import App from './components/App';
import { config } from './config';
import store from './store';

const oktaOptions: AuthorizationWrapperProps['oktaOptions'] = {
  oktaClientID: config.okta.oktaClientId,
  loginServiceURI: config.okta.loginServiceUri,
  authenticationServiceURI: config.okta.oktaIssuerUrl,
};

render(
  <AppWrapper store={store} oktaOptions={oktaOptions} pendoAccountId="therapies">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AppWrapper>,
  document.getElementById('root'),
);
