import {
  GET_ALL_SITES,
  GET_ALL_SITES_FAILED,
  SET_ALL_SITES,
  Institution,
  SiteActionTypes,
  GET_USER_SITES,
  SET_USER_SITES,
  GET_USER_SITES_FAILED,
  SET_IMPERSONATION_MODAL_OPEN,
} from './types';

export const creators = {
  getAllSites(): SiteActionTypes {
    return { type: GET_ALL_SITES };
  },
  getAllSitesFailed(error: string): SiteActionTypes {
    return { type: GET_ALL_SITES_FAILED, error };
  },
  setAllSites(timeSites: Array<Institution>): SiteActionTypes {
    return { type: SET_ALL_SITES, timeSites };
  },
  getUserSites(): SiteActionTypes {
    return { type: GET_USER_SITES };
  },
  getUserSitesFailed(error: Error): SiteActionTypes {
    return { type: GET_USER_SITES_FAILED, error };
  },
  setUserSites(userSites, selectedUserSite): SiteActionTypes {
    return { type: SET_USER_SITES, userSites, selectedUserSite };
  },
  setImpersonationModalOpen(isImpersonationModalOpen: boolean): SiteActionTypes {
    return { type: SET_IMPERSONATION_MODAL_OPEN, isImpersonationModalOpen };
  },
};
