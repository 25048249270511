import { ValuesetCodes } from '@tempus/t-shared';
import { MaxPageWidth, NotAvailable, NoResultImageBoron } from '@tempus/t-shared/ui';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import TrialSearchBar from '~/components/TrialSearchBar';
import TrialSearchResultCard from '~/components/TrialSearchResultCard';
import UnavailableTrialsButton from '~/components/TrialSearchUnavailableTrialsButton';
import { RootState } from '~/store';
import { SearchPrompt } from '~/store/trialSearch/state';
import { loadDropdowns } from '~/store/trialSearch/thunks';

import useStyles, { searchPageStyles } from './styles';

export const TRIAL_SEARCH_VALUESETS = [
  ValuesetCodes.PATIENT_GENDER,
  ValuesetCodes.STAGE,
  ValuesetCodes.CANCER_COHORT,
  ValuesetCodes.COHORT_CANCER_SOLID_TUMORS,
];

interface TrialSearchProps extends RouteComponentProps, ConnectedProps<typeof connector> {}
const TrialSearch: React.FC<TrialSearchProps> = ({
  loadDropdowns,
  trials,
  searchPrompt,
  selectedUserSite,
  showUnavailableTrialsButton,
}) => {
  const classes = useStyles();

  useEffect(() => {
    loadDropdowns();
  }, []);

  const generateMessage = () => {
    switch (searchPrompt) {
      case SearchPrompt.NO_RESULTS:
        return (
          <NotAvailable
            title={'No trials found'}
            Image={NoResultImageBoron}
            description={'Review or expand your search criteria to find trials for this patient.'}
          />
        );
      case SearchPrompt.NO_INPUT:
        return (
          <NotAvailable
            title={'No patient information entered'}
            Image={NoResultImageBoron}
            description={'Enter patient information into the search bar to find trial matches.'}
          />
        );
    }
  };

  return (
    <>
      <MaxPageWidth classes={searchPageStyles()}>
        <TrialSearchBar />
        {trials.length === 0 && generateMessage()}
        <div className={classes.trialSearchResults}>
          {trials.map((trial) => (
            <TrialSearchResultCard key={`${trial.id}_${trial.cohorts[0].programType}`} {...trial} />
          ))}
        </div>
        <div className={classes.unavailableTrialsButton}>
          {showUnavailableTrialsButton && selectedUserSite?.id ? <UnavailableTrialsButton /> : null}
        </div>
      </MaxPageWidth>
    </>
  );
};

const connector = connect(
  (state: RootState) => {
    return {
      trials: state.trialSearch.trials,
      searchPrompt: state.trialSearch.searchPrompt,
      showUnavailableTrialsButton: state.trialSearch.showUnavailableTrialsButton,
      selectedUserSite: state.site.selectedUserSite,
    };
  },
  {
    loadDropdowns: loadDropdowns.action,
  },
);

export default withRouter(connector(TrialSearch));
