import { kebabCase } from 'lodash';
import React from 'react';
import { DropdownOption } from 'tcl-v3/models';

import { NoLabelMultiCombobox, NoLabelInput } from '~/components/StyledInputs';
import { Trial } from '~/store/trial/types';

import { useFilterHeaderStyles } from './styles';

export function DefaultColumnFilter(props) {
  const {
    tableName,
    column: { filterValue, setFilter, id, Header },
  } = props;
  const header = typeof Header === 'object' ? id : Header;

  return (
    <>
      <NoLabelInput
        type="search"
        label=""
        value={filterValue || ''}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        data-pendo-id={`${tableName}-filter-${kebabCase(header)}`}
      />
    </>
  );
}

export const SelectColumnFilter = (presetOptions: { label: string; value: string }[] | undefined = undefined) => (
  props,
) => {
  const {
    tableName,
    column: { filterValue, setFilter, preFilteredRows, id, Header },
  } = props;
  const header = typeof Header === 'object' ? id : Header;

  const options: string[] = React.useMemo<string[]>(() => {
    const options = new Set<string>();
    (preFilteredRows || []).forEach((row) => {
      row.values[id] && options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const dropdownOptions = presetOptions
    ? [...new Set(presetOptions)]
    : options.map((opt) => ({ label: opt, value: opt }));

  const value = filterValue ? filterValue.map((fv) => dropdownOptions.find(({ value }) => value === fv)) : null;

  const formatFilterValue = (options: DropdownOption[]) => options.map((opt) => opt.value);

  const classes = useFilterHeaderStyles();

  return (
    <div className={classes.root}>
      <NoLabelMultiCombobox
        label=""
        onClick={(e) => e.stopPropagation()}
        clearable={true}
        value={value}
        options={dropdownOptions}
        onChange={(options) => setFilter(options.length ? formatFilterValue(options) : null)}
        classes={classes}
        data-pendo-id={`${tableName}-filter-${kebabCase(header)}`}
      />
    </div>
  );
};

export function trialColumnFilter(rows, columnIds, filterValue) {
  return rows.filter(({ original }) => {
    const { nctId, title, shortName } = original as Trial;

    const trialData = [shortName, nctId, title].join();
    return trialData.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
  });
}

export function multiSelectFilter(rows, columnIds, filterValue) {
  if (!Array.isArray(filterValue) || filterValue.length === 0) {
    // Base case if no filter values are selected
    return rows;
  }
  const columnId = columnIds[0];
  const filterValueSet = new Set<string>(filterValue);

  return rows.filter((row) => {
    const rowValues = (row.original[columnId] || '').split(', ');
    return rowValues.some((v) => filterValueSet.has(v));
  });
}
