import { createUseStyles } from 'react-jss';

export default createUseStyles({
  searchBar: {
    flexBasis: '100%',
  },
  trialSearchResults: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '40px',
    rowGap: '40px',
    flexWrap: 'wrap',
  },
  unavailableTrialsButton: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const searchPageStyles = createUseStyles({
  centered: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: '50px',
  },
});
