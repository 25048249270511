import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { Activity, NewTrialMatch } from '@tempus/t-shared';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { getTrialDisplay } from '~/utils/trial';

import useStyles from './ActivityStatus.styles';

export const NewTrialMatchActivity = ({ activity, stateId }: { activity: Activity; stateId: string }) => {
  const classes = useStyles();

  const ptds = useSelector(
    ({ patientTrackerV2 }: RootState) => patientTrackerV2[stateId].patient.data?.patientTrackingDetails,
  );

  return (
    <>
      <li key={activity.id} className={cn(typography.body, classes.li)}>
        <div className={classes.listItemContainer}>
          <div className={classes.updatesLeft}>
            Patient has a new match to{' '}
            <strong>{getTrialDisplay((activity.content as NewTrialMatch).trialId, ptds || [])}</strong>
          </div>
          <div>{activity.createdAt}</div>
        </div>
      </li>
    </>
  );
};
