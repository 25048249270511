import withStyles from '@tempus/component-library/utils/withStyles';
import { T_TRIALS_EMAIL } from '@tempus/t-shared';
import React from 'react';

import { ClassProps } from '~/components/types';

interface HelpDeskProps {
  classes: ClassProps;
}

const HelpDesk: React.FC<HelpDeskProps> = ({ classes }) => (
  <div className={classes.container}>
    <h1 className={classes.headline}>Whoops Seems You Do Not Have Access To This Page!</h1>
    <h4>Contact {T_TRIALS_EMAIL} to request access or additional support.</h4>
  </div>
);

export default withStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    paddingTop: '100px',
  },
  headline: {
    lineHeight: '48px',
    fontWeight: 500,
  },
})(HelpDesk);
