import { ROLES } from '@tempus/t-shared';

import PatientTrackerSitesV2 from '~/routes/patientTrackerV2';
import SitePatientTrackerV2 from '~/routes/patientTrackerV2/siteId';
import SiteDocuments from '~/routes/siteId/documents';
import TrialDocuments from '~/routes/trialId/documents';
import TrialsPage from '~/routes/trialsPage';
import TrialMaterials from '~/routes/trialsPage/trialMaterials';
import TrialSearch from '~/routes/trialsPage/trialSearch';

import paths from './paths';

export const Routes = {
  trials: {
    title: 'Trials',
    path: paths.TRIALS,
    component: TrialsPage,
    showSiteSelection: true,
    authorizedRoles: [ROLES.T_TRIALS_READ],
  },
  trialSearch: {
    title: 'Find Trials',
    path: paths.TRIAL_SEARCH,
    component: TrialSearch,
    authorizedRoles: [ROLES.T_TRIALS_READ],
  },
  trialMaterials: {
    title: `Materials`,
    path: paths.TRIAL_MATERIALS,
    component: TrialMaterials,
    authorizedRoles: [ROLES.T_TRIALS_READ],
  },
  trialDocuments: {
    title: 'Trial Documents',
    path: paths.TRIAL_DOCUMENTS,
    component: TrialDocuments,
    showSiteSelection: true,
    authorizedRoles: [ROLES.T_DOCUMENTS_READ],
  },
  siteDocuments: {
    title: 'Site Documents',
    path: paths.SITE_DOCUMENTS,
    component: SiteDocuments,
    showSiteSelection: true,
    authorizedRoles: [ROLES.T_DOCUMENTS_READ],
  },
  patientTracker: {
    title: 'Patient Tracker',
    path: paths.PATIENT_TRACKER,
    component: PatientTrackerSitesV2,
    showSiteSelection: true,
    authorizedRoles: [ROLES.T_PATIENT_TRACKER_READ_INTERNAL],
  },
  sitePatientTracker: {
    title: 'Site Patient Tracker',
    path: paths.SITE_PATIENT_TRACKER,
    component: SitePatientTrackerV2,
    showSiteSelection: true,
    authorizedRoles: [ROLES.T_PATIENT_TRACKER_READ, ROLES.T_PATIENT_TRACKER_READ_INTERNAL],
  },
};

export const getSiteDocumentsSiteUrl = (id: string | null) => {
  if (id) {
    return `/sites/${id}/documents`;
  }
  return '/sites/documents';
};
export const getPatientTrackerSiteUrl = (id: string) => `/patient-tracker/sites/${id}`;
