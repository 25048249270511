import { ActionPalette, BrandPalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  header: {
    flexDirection: 'row',
    marginBottom: '50px',
  },
  title: {
    textTransform: 'none',
    color: BrandPalette[700],
  },
  actionButtons: {
    display: 'inline-block',
    float: 'right',

    '& > button': {
      marginRight: '20px',
      '&:focus': {
        border: `2px solid ${ActionPalette[600]}`,
      },
    },

    '& > div': {
      display: 'inline-block',
    },
  },
});
