import { SagaIterator } from '@redux-saga/core';
import { ALL_SITES, AVAILABLE_TIME_SITE_STATUSES } from '@tempus/t-shared';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';

import { RootState } from '~/store';
import api from '~/store/api';

import { GetTrialsBySiteRequest } from '../api/types';
import { creators } from './actions';
import { GET_TRIALS, SET_TRIALS, GET_TRIAL_OPTIONS, GET_ALL_TRIALS } from './types';
import { formatTrials, hasNonStatusFilters } from './utils';

function* fetchTrials(data?: { params?: GetTrialsBySiteRequest }): SagaIterator {
  const selectedUserSite = yield select(({ site }: RootState) => site.selectedUserSite);

  try {
    const { trials, count } = yield call(api.trialSitesSearch.getTrialsBySite, {
      filters: { statuses: AVAILABLE_TIME_SITE_STATUSES },
      pageSize: 0,
      ...data?.params,
      ...((selectedUserSite?.id || selectedUserSite?.name === ALL_SITES) && {
        siteId: selectedUserSite?.id || 'ALL_SITES',
      }),
    });

    const formattedData = formatTrials(trials);

    if (data?.params?.pageSize) {
      const unfilteredCount = hasNonStatusFilters(data?.params?.filters)
        ? yield select(({ trial }: RootState) => trial.unfilteredCount)
        : count;

      yield put(creators.setTrials(formattedData, unfilteredCount, count));
    } else {
      yield put(creators.setAllTrials(formattedData));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(creators.getTrialsFailed(error.message));
    }
  }
}

function* fetchTrialsMeta(): SagaIterator {
  try {
    const latestApplicableAtLookup = yield call(api.documents.latestApplicableAtByTrial);
    yield put(creators.setTrialsMeta(latestApplicableAtLookup));
  } catch (error) {
    // Do nothing. Not having the meta information doesn't hurt anything.
  }
}

function* fetchTrialOptions(): SagaIterator {
  const { trialOptions } = (yield select(({ trial }: RootState) => trial)) as RootState['trial'];

  if (trialOptions.length) {
    return;
  }

  try {
    const { data } = yield call(api.trials.getAll);
    yield put(creators.setTrialOptions(data.data));
  } catch (error) {
    if (error instanceof Error) {
      yield put(creators.getTrialOptionsFailed(error.message));
    }
  }
}

export default [
  takeLeading(GET_ALL_TRIALS, fetchTrials),
  takeLeading(GET_TRIALS, fetchTrials),
  takeLatest(GET_TRIAL_OPTIONS, fetchTrialOptions),
  takeLeading(SET_TRIALS, fetchTrialsMeta),
];
