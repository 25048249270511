import { Note } from '@tcl-boron-icons/icons';
import { Tooltip } from '@tcl-boron-prefabs/tooltip';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import cn from 'classnames';
import { startCase } from 'lodash';
import React, { useState, useRef } from 'react';

import { tooltipComponentClasses, useStyles } from './styles';

interface NotesPreviewProps {
  latestNoteActivity: string;
  latestNoteActivitySource: string;
  responseRequired: boolean;
}

const IconWithBadge = ({ icon }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconWrapper}>
      {icon}
      <div className={classes.badge}></div>
    </div>
  );
};

const NoteSection: React.FC<{ title: string; content: string | undefined }> = ({ title, content }) => {
  const classes = useStyles();
  return (
    <div className={cn(classes.noteSection)}>
      <h4 className={classes.noteFromTitle}>{title}</h4>
      <p className={classes.noteContent}>{content}</p>
    </div>
  );
};

export const NotesPreview: React.FC<NotesPreviewProps> = ({
  latestNoteActivity,
  latestNoteActivitySource,
  responseRequired,
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = window.setTimeout(() => {
      setIsOpen(true);
    }, 250);
  };

  const handleMouseLeave = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = window.setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  if (!latestNoteActivity) {
    return null;
  }
  return (
    <>
      <div className={classes.tooltipContainer}>
        <Tooltip
          isOpen={isOpen}
          position="side-left"
          data-testid="notes-preview-tooltip"
          classes={tooltipComponentClasses()}>
          {{
            tooltip: (
              <div className={classes.tooltip} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <span className={cn(typography.subSectionHeader)} data-testid="notes-preview-text">
                  Notes Preview
                </span>
                <NoteSection
                  title={`Note from ${startCase(latestNoteActivitySource.toLowerCase())}`}
                  content={latestNoteActivity}
                  data-testid="notes-preview-note-from-tempus"
                />
              </div>
            ),
            base: (
              <div className={classes.noteIcon} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {responseRequired ? (
                  <IconWithBadge icon={<Note className={classes.noteIcon} />} />
                ) : (
                  <Note className={classes.noteIcon} />
                )}
              </div>
            ),
          }}
        </Tooltip>
      </div>
    </>
  );
};

export default NotesPreview;
