import React from 'react';
import { Warning } from 'tcl-v3/icons';
import typography from 'tcl-v3/styles/typography.module.scss';

interface IssueTextProps {
  classes: Record<string, string>;
  colorClass: string;
  text: string;
}

const IssueText: React.FC<IssueTextProps> = ({ classes, colorClass, text }) => {
  return (
    <div className={`${classes.issue} ${colorClass} ${typography.small}`}>
      <Warning />
      {text}
    </div>
  );
};

export default IssueText;
