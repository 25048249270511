// Copied from https://go.opstempus.com/NB6jbG.

import React from 'react';
import { components } from 'react-select';
import { grayPalette, primaryPalette } from 'tcl-v3/colors';
import { CaretDown, Checkmark, Close } from 'tcl-v3/icons';
import { Loader } from 'tcl-v3/prefabs';

// Can be updated as required to override other supported components (https://react-select.com/components#replaceable-components)
export interface CreatableSingleSelectComponentOverrides {
  ClearIndicator?: (props?) => JSX.Element;
  DropdownIndicator?: (props) => JSX.Element;
  Input?: (props?) => JSX.Element;
  LoadingIndicator?: (props?) => JSX.Element;
  LoadingMessage?: (props?) => JSX.Element;
  NoOptionsMessage?: (props?) => JSX.Element;
  Option?: (props?) => JSX.Element;
}

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <Close height="10px" width="10px" color={grayPalette.gray100} />
  </components.ClearIndicator>
);

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <CaretDown height="5px" width="10px" color={grayPalette.gray100} />
  </components.DropdownIndicator>
);

const SingleSelectOption = (props) => {
  const {
    isSelected,
    selectProps: { useBoronStyles },
  } = props;

  return (
    <components.Option {...props}>
      {/* The style allows us to have indented values for the category options. */}
      <span style={{ whiteSpace: 'pre-wrap' }}>{props.children}</span>
      {isSelected && !useBoronStyles && <Checkmark height="13px" width="13px" color={primaryPalette.tempusPrimary} />}
    </components.Option>
  );
};

const LoadingIndicator = () => {
  return <Loader scale={0.5} />;
};

const SimpleInput = (props) => {
  const {
    type,
    value,
    onBlur,
    onFocus,
    onChange,
    tabIndex,
    innerRef: ref,
    style: outerStyle,
    isDisabled: disabled,
  } = props;
  const style = { border: 'none', width: '100%', background: 'transparent', ...outerStyle };
  const selectedProps = { disabled, onBlur, onChange, onFocus, tabIndex, type, value, style, ref };

  return <input {...selectedProps} />;
};

export const getComponentOverrides = (
  overrides: CreatableSingleSelectComponentOverrides,
): CreatableSingleSelectComponentOverrides => {
  return {
    ClearIndicator: overrides.ClearIndicator || ClearIndicator,
    DropdownIndicator: overrides.DropdownIndicator || DropdownIndicator,
    Input: overrides.Input || SimpleInput,
    LoadingIndicator: overrides.LoadingIndicator || LoadingIndicator,
    Option: overrides.Option || SingleSelectOption,
    ...(overrides.LoadingMessage && { LoadingMessage: overrides.LoadingMessage }),
    ...(overrides.NoOptionsMessage && { NoOptionsMessage: overrides.NoOptionsMessage }),
  };
};
