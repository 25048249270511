import { DocumentConstants, DocumentTagView, DocumentView, InternalProgramType } from '@tempus/t-shared';

import { getEffectiveProgramTypes } from '~/utils/program-type';

export const extensionFromFile = (file: File): string => {
  return file.name.split('.').pop() || '';
};

export const versionNumberIsValid = (versionNumber: string): boolean => {
  return Boolean(versionNumber.match('^[0-9]+([.][0-9]+)?$'));
};

export const versionNumberIsUnique = (
  versionNumber: string,
  versionId: string,
  document?: DocumentView | null,
): boolean => {
  return !document || !document.versions.find(({ version, id }) => version === versionNumber && id !== versionId);
};

export const versionIsValid = (versionNumber: string, versionId: string, document?: DocumentView | null): boolean => {
  return versionNumberIsValid(versionNumber) && versionNumberIsUnique(versionNumber, versionId, document);
};

export const displayName = (
  fileType: string,
  fileName: string,
  requiredTags: DocumentTagView[] | null | undefined,
  classification: DocumentConstants.Classification,
  category: string,
  programType: DocumentConstants.NullableProgramType,
): string => {
  if (!fileType) {
    return '';
  }

  if (fileType === DocumentConstants.DocumentRollupConstants.OTHER) {
    return fileName;
  }

  const nameParts = [fileType];
  const { requiredKeys, programTypes } = DocumentConstants.getDocumentRollup(classification, fileType, category);

  if (requiredKeys) {
    const requiredTagValues: string[] = [];
    requiredKeys.forEach((key) => {
      const tagValue = (requiredTags?.find((tag) => tag.key === key) || {}).value;
      if (tagValue) {
        requiredTagValues.push(tagValue);
      }
    });

    if (requiredTagValues.length) {
      nameParts.push(...requiredTagValues);
    } else {
      nameParts.push('');
    }
  }

  const effectiveProgramTypes = getEffectiveProgramTypes(programTypes);

  // If there is only one program type allowed, then it
  //   is not added to the name.
  const addProgramTypeToName = effectiveProgramTypes.length > 1;

  if (addProgramTypeToName) {
    if (programType) {
      // If the program type is set, then add it to the name.
      nameParts.push(InternalProgramType[programType]);
    } else if (!effectiveProgramTypes.includes(null)) {
      // If it is not set, and it must be, add an empty value
      //   to indicate something is missing.
      nameParts.push('');
    }
  }

  return nameParts.join(' - ');
};

export const classificationField = (classification: DocumentConstants.Classification): string => {
  return classification === DocumentConstants.Classification.Trial ? 'trialId' : 'institutionId';
};
