import { BiomarkerFilter } from '@tempus/t-shared';
import { omitBy, isNull } from 'lodash';

import { SelectOption } from '~/store/trialSearch/state';

interface Mutation {
  gene: string;
  reported: boolean;
  pVarSingleChar?: string;
  cVar?: string;
  fusedGene?: string;
}
export interface BiomarkerSelectOption extends SelectOption {
  mutation: Mutation;
}

export const formatBiomarkerOptions = (input: BiomarkerFilter[]): BiomarkerSelectOption[] =>
  input.map(({ display, gene, pVarSingleChar, cVar, fusedGene }) => ({
    label: display,
    value: display,
    mutation: (omitBy(
      {
        gene,
        reported: true,
        pVarSingleChar,
        cVar,
        fusedGene,
      },
      isNull,
    ) as unknown) as Mutation, // TypeScript unable to infer that gene & reported are not null
  }));
