import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  twoColumnContainer: {
    display: 'flex',
    height: '100%',
  },
  sidebar: {
    minWidth: '250px',
    maxWidth: '250px',
    padding: '0 40px 0 0',
  },
  mainSection: {
    flexGrow: 1,
  },
  trialName: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  notesPreview: {
    marginTop: '2px',
    position: 'absolute',
    transition: 'all 500ms ease-out',
  },
  pagination: {
    backgroundColor: 'transparent',
  },
});

export const useClasses = createUseStyles({
  root: {
    borderBottom: `1px solid ${GrayscalePalette[300]}`,
    paddingBottom: '0px',
  },
});
