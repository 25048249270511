import { Table, TableProps } from '@tempus/t-shared/ui';
import React, { useMemo } from 'react';
import { mergeClasses } from 'tcl-v3/utils';

import { TableRowObject } from '~/custom-typings/react-table';

import { DefaultColumnFilter } from './filters';
import { useCustomTableStyles } from './styles';

const TherapiesTable = <T extends TableRowObject>(props: TableProps<T>) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const classes = mergeClasses(useCustomTableStyles());
  const combinedProps = { defaultColumn, ...props, classes };
  if (combinedProps.defaultShowFilters === undefined) {
    // show up the filter row by default if not set
    combinedProps.defaultShowFilters = true;
  }
  return <Table {...combinedProps} />;
};

export default TherapiesTable;
