import { BrandPalette } from '@tcl-boron-colors/colors';
import styled from 'styled-components';

export default styled.div`
  width: 100%;
  color: ${BrandPalette[700]};
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
`;
