import { BaseUserState } from '@tempus/t-shared/ui';
import notificationAction from '@tempus/t-shared/ui/store/notification/actions';
import userAction from '@tempus/t-shared/ui/store/user/actions';
import { call, put, takeEvery, select } from 'redux-saga/effects';

import api from '~/store/api';

import { GET_CURRENT_USER } from './types';
import { EnhancedUserState } from '.';

function* getCurrentUser() {
  try {
    const currentUser = yield select((state) => state.user);

    const baseUser: BaseUserState & EnhancedUserState = yield call(api.users.getCurrentUser);

    yield put(userAction.setUser({ ...currentUser.user, ...baseUser }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(notificationAction.showErrorMessage(error.message));
    }
  }
}

export default [takeEvery(GET_CURRENT_USER, getCurrentUser)];
