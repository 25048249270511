import { MaxPageWidth, NoResultImageBoron, NotAvailable } from '@tempus/t-shared/ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import paths, { checkPath } from '~/routes/paths';
import Styles from '~/routes/sites/styles';
import { RootState } from '~/store';
import { creators as siteCreators } from '~/store/site';

import SiteSelectionHeader from '../PageHeaders/SiteSelectionHeader';
import { allSitesOption } from '../constants';

const SiteSelection = (props) => {
  const dispatch = useDispatch();

  const selectedUserSite = useSelector(({ site }: RootState) => site.selectedUserSite);
  const {
    user: { canImpersonate },
  } = useSelector((state: RootState) => state);
  const userSites = useSelector(({ site }: RootState) => site.userSites);
  const userSiteCount = Object.keys(userSites || {}).length;
  const location = useLocation();
  const path = location.pathname;
  const onTrialDocuments = checkPath(path, paths.TRIAL_DOCUMENTS);

  // only external grandparent users see the SiteSelectionHeader on the Trial Documents page
  const showSiteSelectionHeader = onTrialDocuments ? !canImpersonate && userSiteCount > 1 : userSiteCount > 1;

  if (userSiteCount === 0) {
    return <div></div>;
  } else if (selectedUserSite) {
    return (
      <div>
        {showSiteSelectionHeader && <SiteSelectionHeader />}

        {props.children}
      </div>
    );
  } else if (showSiteSelectionHeader) {
    return (
      <div>
        {showSiteSelectionHeader && <SiteSelectionHeader />}
        <MaxPageWidth>
          <Styles>
            <NotAvailable
              title={'No site selected'}
              Image={NoResultImageBoron}
              description={
                'Select a site from the top left dropdown to see all of its available trials and relevant patients.'
              }
            />
          </Styles>
        </MaxPageWidth>
      </div>
    );
  } else if (canImpersonate) {
    // on pages with no SiteSelectionHeader, internal users get set to "All Sites" if they have no selected site in their local storage
    {
      dispatch(siteCreators.setUserSites(userSites, { id: allSitesOption.value, name: allSitesOption.label }));
    }
    return <div></div>;
  } else {
    return <div></div>;
  }
};

export default SiteSelection;
