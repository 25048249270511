import { useQuery } from '@tanstack/react-query';
import { Button } from '@tcl-boron-prefabs/button';
import React from 'react';

import api from '~/store/api';
import { ctgovUrl } from '~/utils/trial';

import useStyles from './styles';

interface CtgovLinkProps {
  trialId: string;
}

const CtgovLink = ({ trialId }: CtgovLinkProps) => {
  const { isLoading, error, data: trial } = useQuery({
    queryKey: ['trial', trialId],
    queryFn: () => api.trials.getTrial(trialId),
  });
  const classes = useStyles();
  if (error || !trial) {
    return null;
  }
  return (
    <div className={classes.ctgovContainer}>
      <div className={classes.ctgovText}>Clinicaltrials.gov</div>
      <Button
        buttonType="secondary"
        onClick={() => {
          window.open(ctgovUrl(trial.nctId), '_blank', 'noreferrer noopener');
        }}
        ariaLabel={'View CTGOV'}
        small={true}
        loading={isLoading}>
        View
      </Button>
    </div>
  );
};
export default CtgovLink;
