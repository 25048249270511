import { Close } from '@tcl-boron-icons/icons';
import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import cn from 'classnames';
import React, { ComponentType, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { clearPatient } from '~/store/trialSearch/slice';
import { PatientInput } from '~/store/trialSearch/state';

import useStyles from '../TrialSearchBar/styles';
import { TrialSearchInputTitles } from '../TrialSearchBar/types';

export interface TrialSearchInputFilterProps {
  open: boolean;
  setSubtitle: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface TrialSearchInputProps {
  title: TrialSearchInputTitles;
  placeholderSubtitle: string;
  Component: ComponentType<TrialSearchInputFilterProps>;
  stateKeys: Array<keyof PatientInput>;
  setElementFocusRef: (element: HTMLDivElement | null) => void;
}

export const TrialSearchInput: React.FC<TrialSearchInputProps> = (props) => {
  const { title, placeholderSubtitle, Component, stateKeys, setElementFocusRef } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [subtitle, setSubtitle] = useState<string | undefined>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleOnInputClick = () => {
    setElementFocusRef(containerRef.current);
    setOpen(!open);
  };

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false);
    }
  };

  const handleClear = (e) => {
    e.stopPropagation();
    dispatch(clearPatient(stateKeys));
    setSubtitle(undefined);
  };

  return (
    <div id={title} onBlur={handleBlur} ref={containerRef} tabIndex={0}>
      <Button
        className={open ? classes.isOpen : undefined}
        classes={classes}
        buttonType="tertiary"
        onClick={handleOnInputClick}
        ariaLabel={title}>
        <div className={classes.textSearchInput}>
          <div className={typography.button}>{title}</div>
          <div
            className={cn(classes.textSearchInputSubtitle, typography.body, {
              [typography.gray]: !subtitle,
            })}>
            {subtitle || placeholderSubtitle}
          </div>
        </div>

        {subtitle && open && <Close onClick={handleClear} />}
      </Button>
      <div className={classes.textSearchInputFilterBox} tabIndex={-1}>
        {<Component open={open} setSubtitle={setSubtitle} {...props} />}
      </div>
    </div>
  );
};
