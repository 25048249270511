import { Button } from '@tcl-boron-prefabs/button';
import { Modal } from '@tcl-boron-prefabs/modal';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { PatientGeneticData, PatientNameSearch } from '@tempus/t-shared';
import { storeActions } from '@tempus/t-shared/ui';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import { Calendar, UserSolid } from 'tcl-v3/icons';
import { Loader } from 'tcl-v3/prefabs';

import PatientNameSelect from '~/components/PatientNameSelect';
import { NameCategory, PatientDropdownOption } from '~/components/PatientNameSelect/types';
import Api from '~/store/api';
import { updatePatient } from '~/store/trialSearch/slice';
import { DiagnosisInput } from '~/store/trialSearch/state';

import { PatientInputFieldsEnum } from '../AddPatientV2/types';
import { CreatableSingleSelectComboboxProps } from '../CreatableSingleSelectCombobox';
import { CreatableSingleSelectComponentOverrides } from '../CreatableSingleSelectCombobox/overrides';
import { BiomarkerSelectOption } from '../TrialSearchInput/biomarkers.utils';
import useStyles from './styles';

const TrialSearchByPatient = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstNameInputValue, setFirstNameInputValue] = useState('');
  const [lastNameInputValue, setLastNameInputValue] = useState('');
  const [selectedPatient, setSelectedPatient] = useState<PatientNameSearch | null>(null);
  const [selectedFirstNameOption, setSelectedFirstNameOption] = useState<PatientDropdownOption | null>(null);
  const [selectedLastNameOption, setSelectedLastNameOption] = useState<PatientDropdownOption | null>(null);
  const [updatingTrialFilters, setUpdatingTrialFilters] = useState(false);

  const institutionId = useSelector(
    (state: { site: { selectedUserSite: { id: string } } }) => state.site.selectedUserSite.id,
  );

  const getFormattedDate = (date: string) => moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');

  const handleNameChange = (option: PatientDropdownOption | null) => {
    if (!option || !option.patientSearchResult) {
      setFirstNameInputValue('');
      setLastNameInputValue('');
      setSelectedFirstNameOption(null);
      setSelectedLastNameOption(null);
      setSelectedPatient(null);
      return;
    }

    setSelectedPatient(option.patientSearchResult);
    setFirstNameInputValue(option.patientSearchResult.firstName);
    setLastNameInputValue(option.patientSearchResult.lastName);

    setSelectedFirstNameOption({
      patientSearchResult: option?.patientSearchResult,
      label: option.patientSearchResult.firstName,
      value: option.patientSearchResult.firstName,
    });

    setSelectedLastNameOption({
      patientSearchResult: option.patientSearchResult,
      label: option.patientSearchResult.lastName,
      value: option.patientSearchResult.lastName,
    });
  };

  const clearPatientAndGetNewSearchInput = (input: string, nameCategory: NameCategory): [string, string] => {
    setSelectedPatient(null);
    setSelectedFirstNameOption(null);
    setSelectedLastNameOption(null);
    const firstName = nameCategory === PatientInputFieldsEnum.FIRST_NAME ? input : '';
    const lastName = nameCategory === PatientInputFieldsEnum.LAST_NAME ? input : '';
    setFirstNameInputValue(firstName);
    setLastNameInputValue(lastName);
    return [firstName, lastName];
  };

  const loadNameOptions = (nameCategory: NameCategory) => {
    const debouncedSearch = useCallback(
      _.debounce(async (first: string, last: string, callback: (options: PatientDropdownOption[]) => void) => {
        try {
          const patientSearchResults = await Api.patients.searchPatient({
            first,
            last,
            institutionId,
          });

          callback(
            patientSearchResults.length
              ? patientSearchResults.map((patientSearchResult) => {
                  const labelValue =
                    nameCategory === PatientInputFieldsEnum.FIRST_NAME
                      ? patientSearchResult.firstName
                      : patientSearchResult.lastName;
                  return { patientSearchResult, label: labelValue, value: labelValue };
                })
              : [],
          );
        } catch {
          dispatch(
            storeActions.notification.showErrorMessage(
              'Error searching for patient. Please try again or contact your Tempus representative.',
            ),
          );
          callback([]);
        }
      }, 300),
      [nameCategory, institutionId],
    );

    return (input: string, callback: (options: PatientDropdownOption[]) => void) => {
      if (!selectedPatient) {
        debouncedSearch(firstNameInputValue, lastNameInputValue, callback);
      } else {
        // Clear selected patient and start new patient search
        const [firstName, lastName] = clearPatientAndGetNewSearchInput(input, nameCategory);
        debouncedSearch(firstName, lastName, callback);
      }
    };
  };

  const componentOverrides: CreatableSingleSelectComponentOverrides = {
    Option: (props) => {
      const { firstName, lastName, dateOfBirth, providerName } = props.data.patientSearchResult;

      return (
        <components.Option {...props}>
          <div className={classes.option}>
            <div className={classes.optionName}>
              {firstName} {lastName}
            </div>
            <div>DOB {getFormattedDate(dateOfBirth)}</div>
            <div>{providerName}</div>
          </div>
        </components.Option>
      );
    },
    LoadingIndicator: () => <></>,
    LoadingMessage: (props) => {
      return (
        <components.LoadingMessage {...props}>
          <div className={classes.loadingMessage}>
            <Loader scale={0.5} />
            <div>Searching patient names. This may take a moment.</div>
          </div>
        </components.LoadingMessage>
      );
    },
    NoOptionsMessage: (props) => {
      return (
        <components.NoOptionsMessage {...props}>
          <div className={classes.loadingMessage}>No matching patient name found.</div>
        </components.NoOptionsMessage>
      );
    },
  };

  const updateTrialFilters = async () => {
    setUpdatingTrialFilters(true);

    try {
      const patientGeneticData: PatientGeneticData = await Api.patients.fetchPatientGeneticData(
        selectedPatient?.patientId as string,
      );

      const age = moment().diff(selectedPatient?.dateOfBirth, 'years');

      const diagnoses: DiagnosisInput[] = patientGeneticData.diseases.length
        ? patientGeneticData.diseases.map((disease) => ({
            disease: { label: disease.cohortCancerTypeName, value: disease.cohortCancerType },
            diseaseStage: { label: disease.stageGradeName || '', value: disease.stageGrade || '' },
          }))
        : [];

      const biomarkers: BiomarkerSelectOption[] = patientGeneticData.biomarkers.length
        ? patientGeneticData.biomarkers
            .map((biomarker) => ({
              label: biomarker.display,
              value: biomarker.display,
              mutation: {
                gene: biomarker.gene,
                reported: true,
                ...(biomarker.pVarSingleChar && { pVarSingleChar: biomarker.pVarSingleChar }),
                ...(biomarker.cVar && { cVar: biomarker.cVar }),
                ...(biomarker.fusedGene && { fusedGene: biomarker.fusedGene }),
              },
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        : [];

      dispatch(
        updatePatient({
          age,
          dateOfBirth: getFormattedDate(selectedPatient?.dateOfBirth || ''),
          diagnoses,
          variantOptions: biomarkers,
        }),
      );

      closeModal();
    } catch {
      dispatch(
        storeActions.notification.showErrorMessage(
          'Error fetching patient genetic data. Please try again or contact your Tempus representative.',
        ),
      );
    } finally {
      setUpdatingTrialFilters(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPatient(null);
    setSelectedFirstNameOption(null);
    setSelectedLastNameOption(null);
    setFirstNameInputValue('');
    setLastNameInputValue('');
  };

  const firstNameSelectProps: CreatableSingleSelectComboboxProps['reactSelectProps'] = {
    inputValue: firstNameInputValue,
    onInputChange: (value: string) => setFirstNameInputValue(value),
    onMenuClose: () => {
      setFirstNameInputValue(firstNameInputValue);
    },
  };

  const lastNameSelectProps: CreatableSingleSelectComboboxProps['reactSelectProps'] = {
    inputValue: lastNameInputValue,
    onInputChange: (value: string) => setLastNameInputValue(value),
    onMenuClose: () => {
      setLastNameInputValue(lastNameInputValue);
    },
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        buttonType="secondary"
        data-pendo-id="search-by-patient-button"
        ariaLabel="Search for trials by patient">
        By Patient
      </Button>
      <Modal
        classes={useStyles()}
        className={classes.modal}
        overlayClassName={classes.modalOverlay}
        title=""
        size="small"
        isOpen={isModalOpen}
        onRequestClose={closeModal}>
        <div className={`${typography.sectionHeader} ${classes.modalHeader}`}>
          <strong>Populate search fields with patient information</strong>
        </div>
        <div className={`${classes.contentContainer} ${classes.searchContainer}`}>
          <div className={classes.disclaimer}>
            At this time, only information of patients sequenced at Tempus can be retrieved.
          </div>
          <div className={`${classes.searchContent}`}>
            <PatientNameSelect
              label="First name"
              reactSelectProps={firstNameSelectProps}
              loadOptions={loadNameOptions(PatientInputFieldsEnum.FIRST_NAME)}
              selectedValue={selectedFirstNameOption}
              onChange={handleNameChange}
              async={true}
              createDisabled={true}
              componentOverrides={componentOverrides}
              isDisabled={updatingTrialFilters}
            />
            <PatientNameSelect
              label="Last name"
              reactSelectProps={lastNameSelectProps}
              loadOptions={loadNameOptions(PatientInputFieldsEnum.LAST_NAME)}
              selectedValue={selectedLastNameOption}
              onChange={handleNameChange}
              async={true}
              createDisabled={true}
              componentOverrides={componentOverrides}
              isDisabled={updatingTrialFilters}
            />
            {selectedPatient?.dateOfBirth && (
              <div className={`${classes.selectedDetails} ${classes.dob}`}>
                <Calendar width={14} height={16} />
                <span className={classes.selectedDetailsText}>DOB {getFormattedDate(selectedPatient.dateOfBirth)}</span>
              </div>
            )}
            {selectedPatient?.providerName && (
              <div className={classes.selectedDetails}>
                <UserSolid width={14} height={16} />
                <span className={classes.selectedDetailsText}>{selectedPatient.providerName}</span>
              </div>
            )}
          </div>
          <div className={classes.modalFooter}>
            <div className={classes.modalActions}>
              <Button
                small
                id="identification-cancel-button"
                className="secondary-button"
                buttonType="secondary"
                ariaLabel="Cancel"
                onClick={closeModal}
                data-pendo-id="patient-name-search-modal-cancel-button">
                Cancel
              </Button>
              <Button
                small
                ariaLabel="Confirm"
                onClick={updateTrialFilters}
                disabled={!selectedPatient}
                data-pendo-id="patient-name-search-modal-confirm-button"
                loading={updatingTrialFilters}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TrialSearchByPatient;
