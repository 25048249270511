import { Button } from '@tcl-boron-prefabs/button';
import { Checkbox } from '@tcl-boron-prefabs/checkbox';
import { AVAILABLE_TIME_SITE_STATUSES, NOT_AVAILABLE_TIME_SITE_STATUSES } from '@tempus/t-shared';
import { MaxPageWidth, NotAvailable, ContentEmptyImage } from '@tempus/t-shared/ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TrialsTable from '~/components/TrialsTable';
import { RootState } from '~/store/';
import { creators as trialCreators } from '~/store/trial/actions';

import Styles from './styles';

const TrialMaterials: React.FC = () => {
  const { error } = useSelector(({ trial }: RootState) => trial);
  const selectedUserSite = useSelector(({ site }: RootState) => site.selectedUserSite);
  const trialCount = useSelector(({ trial }: RootState) => trial.unfilteredCount);
  const [showUnavailableTrials, setShowUnavailableTrials] = useState(false);
  const [showUnavailableTrialsButton, setShowUnavailableTrialsButton] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trialCreators.getTrials({ filters: {}, page: 0, pageSize: 15 }));
    setShowUnavailableTrials(false);
    setShowUnavailableTrialsButton(true);
  }, [selectedUserSite]);

  const onShowUnvailableTrialsButtonClick = () => {
    setShowUnavailableTrials(true);
    setShowUnavailableTrialsButton(false);
    dispatch(
      trialCreators.getTrials({
        filters: { statuses: [...AVAILABLE_TIME_SITE_STATUSES, ...NOT_AVAILABLE_TIME_SITE_STATUSES] },
        page: 0,
        pageSize: 15,
      }),
    );
  };

  const showUnavailableTrialsChanged = () => {
    if (showUnavailableTrials) {
      setShowUnavailableTrialsButton(true);
    }

    setShowUnavailableTrials(!showUnavailableTrials);
  };

  const renderContent = () => {
    if (!trialCount) {
      return (
        <div className="no-trials-div">
          <NotAvailable
            title="No Trials Yet"
            Image={ContentEmptyImage}
            description="Trials that are available for your site to activate will display here."
          />
          {selectedUserSite?.id && showUnavailableTrialsButton && (
            <Button
              buttonType="secondary"
              onClick={onShowUnvailableTrialsButtonClick}
              className="show-unavailable-trials-button"
              data-pendo-id="show-not-available-trials-materials"
              ariaLabel={'Show unavailable trials'}>
              Show trials not available to my site
            </Button>
          )}
        </div>
      );
    }

    return (
      <div className="container">
        {selectedUserSite?.id && (
          <Checkbox
            label="Show trials not available to my site"
            onChange={showUnavailableTrialsChanged}
            isSelected={showUnavailableTrials}
            data-pendo-id="show-not-available-trials-materials"
          />
        )}
        <TrialsTable showUnavailableTrials={showUnavailableTrials} />
      </div>
    );
  };

  return (
    <MaxPageWidth>
      <Styles>
        {renderContent()}
        {error}
      </Styles>
    </MaxPageWidth>
  );
};

export default withRouter(TrialMaterials);
