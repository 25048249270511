import { Overlay } from '@tcl-argon-prefabs/overlay';
import { Alert } from '@tcl-boron-prefabs/alert';
import Loader from '@tcl-boron-prefabs/loader';
import React, { useEffect } from 'react';
import { Styles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, StringParam } from 'use-query-params';

import { RootState } from '~/store';
import { creators as patientTrackerCreators } from '~/store/patientTrackerV2';

import useStyles, { overlayOverrideClasses, overlayStyles } from './UpdateOverlay.styles';
import { UpdateOverlayContent } from './UpdateOverlayContent';

type SyncUpdateOverlayProps = {
  stateId: string;
  siteId: string;
};

export const UpdateOverlay = ({ stateId, siteId }: SyncUpdateOverlayProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { data: timePatient, loading, error } = useSelector(
    ({ patientTrackerV2 }: RootState) => patientTrackerV2[stateId].patient,
  );

  const [queryParams, setQueryParams] = useQueryParams(
    {
      timePatientId: StringParam,
    },
    { includeAllParams: true },
  );

  const timePatientId = queryParams.timePatientId;

  const refreshData = () => {
    if (timePatientId) {
      dispatch(patientTrackerCreators.getPatientTrackerRecord(stateId, siteId, timePatientId));
    }
  };

  const onCloseOverlayHandler = () => {
    setQueryParams({ timePatientId: undefined });
  };

  useEffect(() => {
    if (timePatientId) {
      refreshData();
    } else {
      dispatch(patientTrackerCreators.clearPatientTrackerRecord(stateId));
    }
  }, [timePatientId]);

  return (
    <Overlay
      style={overlayStyles as Styles}
      openDirection="right"
      isOpen={!!timePatientId}
      className={classes.overlay}
      classes={overlayOverrideClasses()}>
      {!timePatient && loading && (
        <div className={classes.overlayLoader}>
          <Loader size="large" />
          <div>Loading patient</div>
        </div>
      )}
      {error && (
        <Alert status="error" className={classes.overlayError}>
          {{
            title: 'Error',
            content: error,
          }}
        </Alert>
      )}
      {timePatient && (
        <UpdateOverlayContent
          timePatient={timePatient}
          stateId={stateId}
          siteId={siteId}
          isOpen={!!timePatientId}
          onClose={onCloseOverlayHandler}
          onDataRefresh={() => refreshData()}
        />
      )}
    </Overlay>
  );
};

export default UpdateOverlay;
