import { Warning } from '@tempus/component-library/icons/statuses';
import colors from '@tempus/component-library/styles/colors';
import { namePartsToName } from '@tempus/t-shared';
import { ContactRenderer, DocumentVersionReviewStatusDisplay } from '@tempus/t-shared/ui';
import React, { useState, useEffect } from 'react';
import { InputTheme } from 'tcl-v3/models';
import { Alert, Loader } from 'tcl-v3/prefabs';
import typography from 'tcl-v3/styles/typography.module.scss';

import api from '~/store/api';
import { DocumentVersionReviewData } from '~/store/api/types';
import { formatDateForDisplay } from '~/utils/misc';

import { DetailSectionProps } from '../types';
import { useReviewDetailsStyles } from './styles';

const errorMessage = 'An error occured while retriving details.';

const ReviewDetails: React.FC<DetailSectionProps> = ({ version }) => {
  const { id } = version;

  const [loading, setLoading] = useState<boolean>(false);
  const [reviewData, setReviewData] = useState<DocumentVersionReviewData | null>(null);
  const [error, setError] = useState('');

  const classes = useReviewDetailsStyles();

  if (!id) {
    return null;
  }

  const getDocumentVersionReviewData = async (documentVersionId: string) => {
    setLoading(true);
    api.documents
      .getVersionReview(documentVersionId)
      .then((response) => {
        if (response) {
          setReviewData(response);
        } else {
          setError(errorMessage);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const restoreComponentState = () => {
    setLoading(false);
    setError('');
    setReviewData(null);
  };

  useEffect(() => {
    restoreComponentState();
    if (id) {
      getDocumentVersionReviewData(id);
    }
  }, [id]);

  const renderError = () => {
    return (
      <Alert classes={{ root: classes.alert }}>
        {{ icon: <Warning color={colors.red} />, content: error, theme: InputTheme.Error }}
      </Alert>
    );
  };

  const renderReviewUser = (userId: string | null, date: string | null) => {
    if (!userId || !date) {
      return null;
    }

    return (
      <div className={`${typography.supportingBody} ${typography.gray} ${classes.reviewUser}`}>
        {formatDateForDisplay(date)}{' '}
        {
          <ContactRenderer
            contactId={userId}
            renderer={(contact) => {
              if (typeof contact === 'string') {
                return <>{contact}</>;
              } else {
                return <>{namePartsToName(contact.name)}</>;
              }
            }}
          />
        }
      </div>
    );
  };

  const renderReviewReason = (reason: string | null) => {
    if (!reason) {
      return null;
    }

    return (
      <div className={classes.reviewReason}>
        <h3 className={typography.label}>Reason</h3>
        <p className={`${typography.body}`}>{reason}</p>
      </div>
    );
  };

  const renderReview = () => {
    if (!reviewData) {
      return null;
    }

    return (
      <>
        <DocumentVersionReviewStatusDisplay status={reviewData.reviewStatus} matchTextColor />
        {renderReviewUser(reviewData.reviewUser, reviewData.reviewDate)}
        {renderReviewReason(reviewData.reviewReason)}
      </>
    );
  };

  return <>{loading ? <Loader /> : error.length ? renderError() : renderReview()}</>;
};

export default ReviewDetails;
