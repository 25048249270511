import { SingleDatePicker } from '@tcl-argon-prefabs/single-date-picker';
import debounce from 'debounce-promise';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TrialSearchInputFilterProps } from '~/components/TrialSearchInput/index';
import { RootState } from '~/store';
import { updatePatient } from '~/store/trialSearch/slice';

import { createUseDemographicStyles, useDatePickerStyles } from './styles';

export const DemographicsFilter: React.FC<TrialSearchInputFilterProps> = (props) => {
  const dispatch = useDispatch();
  const classes = createUseDemographicStyles();
  const customCalendarPickerStyles = useDatePickerStyles();
  const { open, setSubtitle } = props;

  const age = useSelector((state: RootState) => state.trialSearch.patientInput.age);
  const dateOfBirth = useSelector((state: RootState) => state.trialSearch.patientInput.dateOfBirth);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);

  const getSubtitle = () => {
    const subtitleParts: string[] = [];

    if (age === null) {
      return;
    }

    if (age > 0) {
      subtitleParts.push(`${age} years old`);
    } else {
      // Check if date is not in the future (temporary till we upgrade SingleDatePicker to Boron and use maxDate)
      if (moment().diff(dateOfBirth, 'days') >= 0) {
        const ageInMonths = moment().diff(dateOfBirth, 'months');
        subtitleParts.push(`${ageInMonths} months old`);
      } else {
        // If date is in the future (temporary till we upgrade SingleDatePicker to Boron and use maxDate)
        subtitleParts.push('0 years old');
      }
    }

    return subtitleParts.length === 1 ? subtitleParts[0] : subtitleParts.join(', ');
  };

  const updateDobAndAge = ({ dateString, dateObject }) => {
    if (moment(dateString, 'MM/DD/YYYY', true).isValid()) {
      const ageInYears = moment().diff(dateString, 'years');
      dispatch(updatePatient({ age: ageInYears, dateOfBirth: dateString }));
    }
    // when user hits 'Clear' in SingleDatePicker
    else if (dateObject === undefined) {
      dispatch(updatePatient({ age: null, dateOfBirth: '' }));
    }
  };
  const onDateChange = debounce(updateDobAndAge, 100);

  useEffect(() => {
    setSubtitle(getSubtitle());
  }, [age]);

  if (!open) {
    return null;
  }

  return (
    <div className={classes.container}>
      <SingleDatePicker
        isOpen={isCalendarOpen}
        label="Date of birth"
        onChange={onDateChange}
        onToggleIsOpen={toggleCalendar}
        value={{ dateString: dateOfBirth }}
        classes={customCalendarPickerStyles}
        maxYear={new Date().getFullYear()}
        data-pendo-id="trial-search-demographic-date-of-birth"
      />
    </div>
  );
};
