import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initialize as intializeGA, pageview } from '@tempus/t-shared/ui/utils/gaHelpers';
import React, { useEffect, ReactNode, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, useLocation, useHistory, Route, Redirect } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import CheckRole from '~/auth/CheckRole';
import { config } from '~/config';
import { Routes } from '~/routes/';
import { RootState } from '~/store';
import { creators as userAction } from '~/store/enhanceUser';

import ErrorBoundary from './Errors/ErrorBoundary';
import TherapiesNavigation from './TherapiesNavigation';
import '../assets/styles/main.css';

const generateRoute = (route) => {
  let exact = false;
  if (route.path.startsWith('/patient-tracker')) {
    exact = true;
  }
  return (
    <Route
      path={route.path}
      exact={exact}
      render={(): ReactNode => (
        <CheckRole
          Component={route.component}
          authorizedRoles={route.authorizedRoles}
          redirect
          showSiteSelection={route.showSiteSelection}
        />
      )}
    />
  );
};

const usePageViews = () => {
  const location = useLocation();
  useEffect(() => pageview(location.pathname), [location]);
};

const queryClient = new QueryClient();

const App: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [initialUrl, setInitialUrl] = useState<{
    pathname: string;
    search: string;
  } | null>(null);
  const dispatch = useDispatch();
  const { canViewNewDocuments, canViewSiteDocuments, canViewPatientTracking, features } = useSelector(
    (state: RootState) => state.user,
  );
  useEffect(() => {
    (async () => await intializeGA(config.gaTrackingId))();
    dispatch(userAction.getCurrentUser());
  }, []);
  usePageViews();

  useEffect(() => {
    if (!initialUrl) {
      // set the initial url with query
      setInitialUrl({
        pathname: location.pathname,
        search: location.search,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    // if the user has features flags, redirect to the initial url
    if (features.length && initialUrl && initialUrl.pathname !== location.pathname) {
      history.replace(initialUrl.pathname + initialUrl.search);
    }
  }, [features]);

  return (
    <QueryClientProvider client={queryClient}>
      <TherapiesNavigation />
      <ErrorBoundary>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <Switch>
            {canViewNewDocuments && generateRoute(Routes.trialDocuments)}
            {generateRoute(Routes.trials)}
            {canViewSiteDocuments && generateRoute(Routes.siteDocuments)}
            {canViewPatientTracking && generateRoute(Routes.sitePatientTracker)}
            {canViewPatientTracking && generateRoute(Routes.patientTracker)}
            <Redirect to={Routes.trials.path} />
          </Switch>
        </QueryParamProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
