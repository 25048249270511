import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export const useTabGroupStyles = createUseStyles({
  tabGroupWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomBorder: {
    height: '4px',
    borderBottom: `1px solid ${GrayscalePalette[400]}`,
  },
});
