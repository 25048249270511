import { createUseStyles } from 'react-jss';

export const useLayoutStyles = createUseStyles({
  root: {
    minHeight: 100,
    boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0), 0 3px 3px 0 rgba(224, 224, 224)',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export default createUseStyles({
  details: {
    marginTop: 8,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '& > div': {
      width: '30%',
      marginTop: 24,
    },
  },
  fullTrialTitle: {
    width: '100% !important',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
});
