import { createUseStyles } from 'react-jss';

export const useDetailStyles = createUseStyles({
  issue: {
    display: 'flex',
    margin: '10px 0',
    alignItems: 'flex-start',

    '& > svg': {
      marginRight: 10,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',

    '& > div, & > label': {
      flex: 1,
    },

    '& .spacer': {
      width: 10,
    },

    '& + &': {
      marginTop: 30,
    },

    '& + &.less-margin': {
      marginTop: 20,
    },

    '&.note': {
      marginTop: 10,
    },
  },
});

export const useCheckboxWrapperStyles = createUseStyles({
  root: {
    fontSize: 14,
  },
});

export const useReverseToggleStyles = createUseStyles({
  root: {
    margin: '0 !important',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
});

export const useDatePickerStyles = createUseStyles({
  calendarContainer: {
    right: 0,
    left: 'unset !important',
  },
  calendarButtonContainer: {
    display: 'none !important',
  },
});
