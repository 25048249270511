import { createUseStyles } from 'react-jss';

export const useReviewDetailsStyles = createUseStyles({
  reviewUser: {
    marginTop: '9px',
  },
  reviewReason: {
    marginTop: '27px',
    '& > *': {
      marginTop: '0px',
    },
  },
  alert: {
    boxShadow: 'none !important',
  },
});
